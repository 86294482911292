const riot = require('riot');

riot.tag2('popup-emoji-picker', '<div class="bg-white rounded-8 box-shadow-primary" ref="popup" onmouseleave="{close}"> <div data-is="module-emoji-picker" options="{options}"></div> </div>', '', '', function(opts) {
    this.select = (emoji) => {
      this.trigger('selected', app.emoji.getSelectData(emoji));
    };

    this.options = {
      onEmojiSelect: this.select,
      locale: "ja",
      autoFocus: true,
      skinTonePosition: 'none'
    };
});