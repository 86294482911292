const riot = require('riot');

riot.tag2('popup-suggestion-users', '<div class="w342" ref="popup"> <div class="f fm fbw py4 px12"> <div class="bold">{opts.label}</div> <div class="p4 cursor-pointer mrn4" onclick="{close}"><i class="icon-close fs24 text-label_dark_medium"></i></div> </div> <div class="block mb8" onkeydown="{selectionList.onKeyDown}"> <div class="relative input light_secondary f fm mb8 mx12"><i class="icon-search text-label_dark_disabled fs16 mr8"></i> <input class="w-full" ref="search" placeholder="ユーザーを検索する" oninput="{onInput}"><i class="icon-close absolute t0 b0 r0 f fh h-full p6 fs16 cursor-pointer" onclick="{onDeleteValue}" if="{refs.search.value}"></i> </div> <div class="overflow-scroll scrollbar-none transition-128" riot-style="max-height: {displayUsers ? 178 : 0}px"> <div class="w-full" each="{displayUser, index in getFilteredDisplayUsers()}" data-is="item-selection-list" item="{displayUser.user}" onclick="{onSelectUser}" selected-class="bg-pale"> <div class="f fm fbw font-lato py4 px12"> <label class="f fm cursor-pointer"><img class="flex-fixed s24 circle object-fit-cover mr8" riot-src="{app.utils.getImageUrl(displayUser.user.data.icon_image)}"> <div class="flex-fixed max-width-50per lh13 bold line-clamp-1 word-break-all mr4">@{displayUser.user.data.screen_name}</div> <div class="fs10 lh13 fw4 text-label_dark_medium fs12 line-clamp-1 word-break-all">{displayUser.user.data.display_name}</div> </label><i class="flex-fixed s16 fs16 {&quot;icon-check text-primary&quot;: displayUser.checked}"></i> </div> </div> </div> </div> </div>', 'popup-suggestion-users input:focus,[data-is="popup-suggestion-users"] input:focus{outline:none !important}', 'class="bg-white box-shadow-primary rounded-8"', function(opts) {
    this.mixin('selectionList');
    this.on('mount', async () => {
      var users = app.utils.displayUsersSort(this.opts.users);
      this.displayUsers = users.map(user => {
        var checked = this.opts.selectedUsersIds.some(uid => uid === user.id);

        return {
          user: user,
          checked: checked,
        };
      });

      setTimeout(() => {
        this.refs.search.focus();
      });
      this.update();
    });

    this.getFilteredDisplayUsers = () => {
      var value = this.refs.search.value;

      let display_users = this.displayUsers.slice().sort((a, b) => {
        if (a.checked === b.checked) return 0;
        if (a.checked) return -1;
        if (b.checked) return 1;
      });

      if (!value) {
        return display_users;
      }
      else {

        var hit_users = app.utils.filterUsers(this.opts.users, value);

        display_users = display_users.filter(du => hit_users.find(u => u.id === du.user.id)).map(user => {

          var name = user.user.data.screen_name + user.user.data.display_name;
          const index = name.toLowerCase().indexOf(value);
          return { index, user };
        }).sort((a, b) => {
          if (a.index === b.index) return 0;
          if (a.index === -1) return 1;
          if (b.index === -1) return -1;
          return a.index - b.index;
        }).map(item => item.user);

        return display_users;
      }
    };

    this.onInput = (e) => {
      e.preventUpdate = true;
      this.debounceSearch();
    };

    this.debounceSearch = _.debounce(() => {
      this.update();
    }, 128);

    this.toggleChecked = (displayUser) => {
      displayUser.checked = !displayUser.checked;
      this.onDeleteValue();
    };

    this.getSelectedUsers = () => {
      return this.displayUsers.filter(item => item.checked).map(user => user.user);
    };

    this.onDeleteValue = () => {
      this.refs.search.value = '';
      this.refs.search.focus();
      this.debounceSearch();
    };

    this.selectUser = (displayUser) => {
      this.toggleChecked(displayUser);

      this.trigger('userselect', {
        user: displayUser.user,
        selectedUsers: this.getSelectedUsers(),
        checked: displayUser.checked,
        self: this,
      });
    };

    this.onSelectUser = (e) => {
      this.refs.search.focus();
      this.selectUser(e.item.displayUser);
    };

    this.on('selectionList.keydown.enter', (e) => {
      e.preventDefault();

      var display_users = this.getFilteredDisplayUsers();
      var displayUser = display_users[this.selectionList.selectedIndex];
      this.selectUser(displayUser);
    });

    this.on('selectionList.keydown.other', (e) => {

      if (document.activeElement !== this.refs.search) {

        this.refs.search.focus();
      }
    });
});