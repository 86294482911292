const riot = require('riot');

riot.tag2('modal-emoji-picker', '<div class="f fb s-full" onclick="{startClosing}"> <div class="w-full rounded-top-6 box-shadow-primary bg-white" ref="modal" onclick="event.stopPropagation()"> <div class="f fc overflow-y-scroll transition-128 gpu-enable" ref="body" riot-style="max-height: {isOpen ? refs[&quot;body&quot;].scrollHeight : 0}px" ontransitionend="{onCloseTransition}"> <div class="w-full" data-is="module-emoji-picker" options="{options}"></div> </div> </div> </div>', '', 'spat-animation=""', function(opts) {
    this.isOpen = false;

    this.select = (emoji) => {
      this.trigger('selected', app.emoji.getSelectData(emoji));
      this.startClosing();
    };

    this.options = {
      onEmojiSelect: this.select,
      dynamicWidth: true,
      locale: "ja",
      skinTonePosition: 'none',
      previewPosition: 'none',
      emojiSize: 32,
      emojiButtonSize: 48,
    };

    this.on('mount', () => {

      setTimeout(() => {
        this.isOpen = true;
        this.update();
      });
    });

    this.startClosing = () => {
      this.isOpen = false;
      this.update();
    };

    this.onCloseTransition = () => {
      if (!this.isOpen) {
        this.close();
      }
    };
});