const riot = require('riot');

riot.tag2('modal-link', '<div class="max-width-472 w-full bg-white rounded-8" ref="modal"> <form class="p32" onsubmit="{submit}"> <label class="f fm mb16"> <div class="bold fs12 mr8">Link: </div> <input class="input light large w-full" ref="link" placeholder="https://~" oninput="{oninput}"> </label> <div class="fs16 lh15 bold word-break-all mb16" show="{ogp.title}">{ogp.title}</div> <div class="mb16" show="{ogp.image_url}"><img class="border" riot-src="{ogp.image_url}"></div> <div class="fs12 lh13 word-break-all mb16" show="{ogp.description}">{ogp.description}</div> <div class="py10" if="{isLoading}" data-is="atom-loading"></div> <div class="mb16" show="{ogp}"> <label class="mr8" show="{ogp.title}"> <input class="mr4" type="checkbox" ref="is_title">Title </label> <label class="mr8" show="{ogp.image_url}"> <input class="mr4" type="checkbox" ref="is_image">Image </label> <label class="mr8" show="{ogp.description}"> <input class="mr4" type="checkbox" ref="is_description">Description </label> </div> <div class="text-center"> <button class="button primary {\'disabled\' : isLoading || !ogp}">Submit</button> </div> </form> </div>', 'modal-link,[data-is="modal-link"]{background-color:rgba(0,0,0,0.5)}', 'class="f fh" spat-animation="push"', function(opts) {
    this.on('mount', () => {
      setTimeout(() => {
        this.refs.link.focus();
      });
    });

    this.fetchOGP = async (e) => {
      this.isLoading = true;
      this.update();
      var url = this.refs.link.value;

      if (!/^http(s)?:\/\/.+/.test(url)) {
        this.isLoading = false;
        this.update();
        return ;
      }

      var {ogp} = await app.api.child('utils/ogp').get({ url });
      this.update({ogp});
      this.isLoading = false;
      this.update();
    };
    this.oninput = _.debounce(this.fetchOGP, 512);

    this.submit = (e) => {
      e.preventDefault();
      if (!this.ogp) return;
      var ogp = this.ogp;
      var texts = [];

      if (this.refs.is_title.checked) {
        texts.push(`[${ogp.title}](${ogp.url})`);
      }
      else {
        texts.push(this.refs.link.value);
      }

      if (this.refs.is_image.checked) {
        texts.push(`[![${ogp.title}](${ogp.image_url})](${ogp.url})`)
      }

      if (this.refs.is_description.checked) {
        texts.push(`${ogp.description}`);
      }

      this.value = {
        text: texts.join('\n\n'),
        ogp: this.ogp,
      };
      this.trigger('submit', {
        text: texts.join('\n\n'),
        ogp: this.ogp,
      });
    };
});