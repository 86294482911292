const riot = require('riot');

riot.tag2('page-error', '<div class="s-full f fh"> <div class="text-center"> <h2 class="fs128 mb16">{statusCode}</h2> <p class="fs24 mb32">{description}</p><a class="text-link rounded-full" href="/">to TOP</a> </div> </div>', 'page-error,[data-is="page-error"]{display:block}', '', function(opts) {
    this.preload = ({req, res}) => {
      let statusCode = res.statusCode;

      if (res.error.status) {
        statusCode = res.error.status;
      }
      let errorMessage = {
        403: {
          title: "Auth Error",
          description: "このページへのアクセス権限がありません",
        },

        404: {
          title: "Page Not Found",
          description: "ページが見つかりません",
        },
      }[statusCode];

      if (!errorMessage) {
        errorMessage = {
          title: "Error",
          description: "",
        };
      }
      return {
        statusCode,
        title: errorMessage.title,
        description: errorMessage.description,
      };
    };

    this.head = () => {
      return {
        title: 'Hello, spat!',
      };
    };
});