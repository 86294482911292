const riot = require('riot');

riot.tag2('atom-input-image', '<div class="f fh s-full cursor-pointer relative" onclick="{onSelectImage}"> <div class="s-full"><img class="s-full object-fit-cover" if="{url || opts.riotSrc}" riot-src="{url || opts.riotSrc}"></div> <div class="absolute trbl0"> <yield></yield> </div> <div class="absolute trbl0 opacity-0 hover-trigger hover-opacity-100 transition {isDragover ? \'opacity-100\' : \'opacity-0\'}" ondragenter="{onDragenter}" ondragleave="{onDragleave}"> <div class="s-full bg-overlay_secondary f fh pointer-none"><i class="icon-common text-white fs{opts.iconSize}"></i></div> </div> </div>', '', '', function(opts) {
    this.on('mount', () => {
      this.input = document.createElement('input');
      this.input.type = 'file';
      this.input.accept = 'image/*';

      this.input.onchange = async (e) => {
        var file = e.currentTarget.files[0];
        if (!file) return ;
        this._setFile(file);
        this.update();
      };

      this.root.ondragover = (e) => {
        return false;
      };
      this.root.ondrop = (e) => {
        var file = e.dataTransfer.files[0];
        if (!file) return ;

        if (!this.validateFileImageOnly(file.type)) {
          e.preventDefault();
          spat.modal.alert('その形式のファイルは投稿できません。');
          return;
        }

        this._setFile(file);

        this.isDragover = false;
        this.update();

        return false;
      };
    });

    this.onDragenter = () => {
      this.isDragover = true;
      this.update();
    };

    this.onDragleave = () => {
      this.isDragover = false;
      this.update();
    };

    this.onSelectImage = () => {
      this.input.click();
    };

    this.reset = () => {
      this.input.value = '';
      this.file = null;
      this.url = null;
    };

    this._setFile = async (file) => {
      this.file = file;

      this.url = URL.createObjectURL(file);
      this.trigger('imageset');
      this.trigger('fileset');
      this.update();
    };

    this.getImageURL = () => {
      return this.url || this.opts.riotSrc;
    };

    this.getFile = () => {
      return this.file;
    };

    this.getIsUpdated = () => {

      if (!this.getFile() && !this.getImageURL()) return true;

      else if (this.getFile()) return true;

      else return false;
    };

    this.uploadAndGetImageURL = async () => {

      if (!this.getFile() && !this.getImageURL()) {
        return null;
      }

      else if (!this.getFile() && this.getImageURL()) {
        return this.getImageURL();
      }

      else {
        var url = await app.utils.uploadFile(this.file);
        return url;
      }
    };

    this.validateFileImageOnly = (type) => {
      var re = /(^image\/\w*)/;
      return re.test(type);
    };
});