const riot = require('riot');

riot.tag2('item-user-icon', '<img class="s24 object-fit-cover circle tooltip cursor-pointer" riot-src="{app.utils.getImageUrl(opts.user.data.icon_image)}" aria-label="プロフィールを表示" data-tooltip-position="top" data-ignore-onload="true" onclick="{onOpenPopupUser}">', '', '', function(opts) {
    this.onOpenPopupUser = (e) => {
      e.preventDefault();
      e.stopPropagation();
      spat.popup.open('popup-user', {
        element: e.currentTarget,
        closeTarget: e.currentTarget,
        position: 'bottom',
        offsetLeft: 32,
        offsetTop: -4,
        opts: {
          user: this.opts.user,
        },
      });
    };
});