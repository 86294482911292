const riot = require('riot');

riot.tag2('popup-user', '<div class="font-lato w240 bg-white rounded-12 box-shadow-primary" ref="popup"> <div class="f fm fclm w-full py24 px16"><img class="block object-fit-cover flex-fixed s100 circle mb11" riot-src="{app.utils.getImageUrl(opts.user.data.icon_image)}"> <div class="bold lh13 line-clamp-1 mb8">{opts.user.data.screen_name} </div> <div class="text-label_dark_medium bold fs12 lh13 mb8">{opts.user.data.display_name}</div> <div class="text-label_dark_medium fs12 lh13 bold mb16">email： {opts.user.data.email}</div> <div class="row fh mb16" if="{affiliationGroups.length}"> <div class="f fh fs10 rounded-4 bg-separator_opacity text-label_dark_medium word-break-keep lh15 h18 px4 mr4 mb4" each="{group in affiliationGroups}"><span>@{group.data.screen_name}</span></div> </div> <time class="text-label_dark_medium fs12 lh13 bold mb24">{dayjs(opts.user.data.created_at).format(\'YYYY/MM/DD\')}から利用</time><a class="button primary w113 h40" if="{opts.user.isMine()}" href="/settings" onclick="{close}">編集</a> <button class="button primary w113 h40" if="{!opts.user.isMine()}" onclick="{createDM}">DMを送信</button> </div> </div>', 'popup-user,[data-is="popup-user"]{display:block}', '', function(opts) {
    this.on('mount', async () => {

      this.affiliationGroups = await this.fetchAffiliationGroups();
      this.update();
    });

    this.createDM = async () => {

      var users = [app.store.currentUser.data, this.opts.user.data];

      var data = {
        workspace_id: app.store.workspace_id,
        name: users.map(user => user.screen_name).sort().join(','),
        description: '',
        kind: 'direct',
        user_ids: [this.opts.user.id],
      };

      var project = await this.findProjects(data);

      if (!project) {
        var {project} = await app.store.workspace.createProject(data);
        project = app.store.doc(`${app.store.workspace.path}/projects/${project.id}`);

        const note = await this.opts.project.createNote({title:"general", content:"# general\n\n"});
      }

      var project_user = this.getProjectUser(project);

      spat.router.push(project_user.getProjectURL());
      this.close();
    };

    this.findProjects = async ({name, kind}) => {

      var projects_users = app.store.projectsUsersStore.items.filter(item => item.data.project_kind === kind);

      var projects = projects_users.map(pu => app.store.doc(pu.data.project_ref.path));

      var promises = projects.map(async (project) => {
        let project_users = await project.fetchProjectUsers();
        let project_name = project_users.map(user => user.data.screen_name).sort().join(',');
        if (name === project_name) {
          return project;
        }
        else {
          throw '';
        }
      });

      try {

        return await Promise.any(promises);
      }
      catch(e) {

        return ;
      }
    };

    this.getProjectUser = (project) => {
      var projects_users = app.store.projectsUsersStore.items;
      return projects_users.find(pu => project.id === pu.data.project_id);
    };

    this.fetchAffiliationGroups = async () => {
      var groups = await app.store.workspace.fetchGroups();
      return groups.filter(group => {
        return group.data.user_ids.some(id => id === this.opts.user.id);
      });
    };
});