const riot = require('riot');

riot.tag2('page-invites', '', '', '', function(opts) {
    this.on('show', async ({req, res}) => {
      var invite_id = req.query.invite;
      var {invite} = await app.api.child('invites').child(invite_id).get();

      if (app.auth.isSignIn()) {

        if (invite.email === app.auth.currentUser.email) {
          await app.api.child('me/sign_in').post({invite_id});
          spat.router.push(`/workspaces/${invite.workspace.id}/inboxes`);
          await spat.modal.alert(`おめでとうございます！\n新しいワークスペース『${invite.workspace.name}』に参加しました。`);
        }
        else {

          var result = await spat.modal.confirm('現在ログインしているユーザーと違う\nメールアドレス宛の招待です。\n別のアカウントでログインし直しますか？');

          if (result) {

            spat.router.push('/signin' + location.search);
          }
          else {
            spat.router.push('/');
          }
        }
      }
      else {

        spat.modal.alert(`ワークスペース『${invite.workspace.name}』に\n招待されました。\nアカウントを作成、またはログインして alog を始めましょう！`)

        spat.router.push('/signup' + location.search);
      }
    });
});