const riot = require('riot');

riot.tag2('module-tooltip', '', 'module-tooltip,[data-is="module-tooltip"]{transform:translate3d(0, 0, 0);visibility:hidden;position:fixed;top:0;right:0;bottom:0;left:0;display:block;z-index:9999}@keyframes tooltip-fade-in{ 0%{opacity:0} 100%{opacity:1}}@keyframes tooltip-fade-out{ 0%{opacity:1} 100%{opacity:0}} module-tooltip .tooltip-content,[data-is="module-tooltip"] .tooltip-content{position:fixed;visibility:visible;pointer-events:none;--tooltip-x:0;--tooltip-y:0;transform:translate3d(var(--tooltip-x), var(--tooltip-y), 0)}', '', function(opts) {
    this.MIN_EDGE_MARGIN = 0;
    this._hoverElementCache = new Set();

    this.on('mount', () => {
      if (spat.isBrowser) {

        const hoverEventListener = (e) => {
          const element = e.target.closest('.tooltip');
          if (!element) return ;
          const text = element.ariaLabel;
          if (!text) return ;
          if (this._hoverElementCache.has(element)) return ;
          this._hoverElementCache.add(element);
          let offsetTop = element.dataset.tooltipOffsetTop;
          if (offsetTop !== undefined) offsetTop = +offsetTop;
          let offsetLeft = element.dataset.tooltipOffsetLeft;
          if (offsetLeft !== undefined) offsetLeft = +offsetLeft;
          this.showText({
            text,
            element,
            options: {
              position: element.dataset.tooltipPosition,
              align: {
                x: element.dataset.tooltipAlignX,
                y: element.dataset.tooltipAlignY,
              },
              offsetTop,
              offsetLeft,
            },
          });
        };
        document.addEventListener('mouseover', hoverEventListener);

        document.addEventListener('touchstart', hoverEventListener);

        this.resizeObserver = new ResizeObserver(this.tooltipResized);
      }
    });

    this.tooltipResized = (entries, observer) => {
      for (let entry of entries) {
        this.updatePosition(entry.target);
      }
    };

    this.updatePosition = ($tooltip) => {
      if (!$tooltip._tag) return ;
      const tooltipTag = $tooltip._tag;
      const options = tooltipTag.__openOptions;
      const align = Object.assign({}, options.align);

      let left = 0;
      let top = 0;

      const tooltipOrigin = {x: '0', y: '0'};

      if (options.element) {
        const rect = options.element.getBoundingClientRect();
        options.position = options.position || 'bottom';

        if (options.position === 'top') {
          top = rect.top;
          tooltipOrigin.y = '-100%';
          if (!align.x) align.x = 'center';
        }

        else if (options.position === 'bottom') {
          top = rect.bottom;
          if (!align.x) align.x = 'center';
        }

        else if (options.position === 'left') {
          left = rect.left;
          tooltipOrigin.x = '-100%';
          if (!align.y) align.y = 'middle';
        }

        else if (options.position === 'right') {
          left = rect.right;
          if (!align.y) align.y = 'middle';
        }

        if (align.x === 'center') {
          left = rect.left + rect.width / 2;
          tooltipOrigin.x = '-50%';
        }
        else if (align.x === 'left') {
          left = rect.left;
        }
        else if (align.x === 'right') {
          left = rect.right;
          tooltipOrigin.x = '-100%';
        }

        if (align.y === 'middle') {
          top = rect.top + rect.height / 2;
          tooltipOrigin.y = '-50%';
        }
        else if (align.y === 'top') {
          top = rect.top;
        }
        else if (align.y === 'bottom') {
          top = rect.bottom;
          tooltipOrigin.y = '-100%';
        }
      }

      top += options.offsetTop || 0;
      left += options.offsetLeft || 0;
      $tooltip.style.setProperty('--tooltip-x', tooltipOrigin.x);
      $tooltip.style.setProperty('--tooltip-y', tooltipOrigin.y);
      Object.assign($tooltip.style, {
        left: `${left}px`,
        top: `${top}px`,
      });

      const tooltipRect = $tooltip.getBoundingClientRect();
      let positionChanged = false;

      if (tooltipRect.top < this.MIN_EDGE_MARGIN) {
        top -= tooltipRect.top - this.MIN_EDGE_MARGIN;
        positionChanged = true;
      }
      else if (tooltipRect.bottom > innerHeight - this.MIN_EDGE_MARGIN) {
        top -= tooltipRect.bottom - innerHeight + this.MIN_EDGE_MARGIN;
        positionChanged = true;
      }

      if (tooltipRect.left < this.MIN_EDGE_MARGIN) {
        left -= tooltipRect.left - this.MIN_EDGE_MARGIN;
        positionChanged = true;
      }
      else if (tooltipRect.right > innerWidth - this.MIN_EDGE_MARGIN) {
        left -= tooltipRect.right - innerWidth + this.MIN_EDGE_MARGIN;
        positionChanged = true;
      }

      if (positionChanged) {
        Object.assign($tooltip.style, {
          left: `${left}px`,
          top: `${top}px`,
        });
      }
    };

    this.open = (tagName, options = {}) => {
      var _options = Object.assign({
        autoClose: true,
      }, options);

      var element = document.createElement('div');
      this.root.appendChild(element);

      var tooltipTag = riot.mount(element, tagName, _options.opts)[0];
      element.classList.add('tooltip-content');

      tooltipTag.refs.tooltip.style.animation = `tooltip-fade-in 100ms`;

      tooltipTag.__openOptions = _options;
      tooltipTag.promise = new Promise((resolve) => {
        tooltipTag.close = async () => {

          if (tooltipTag.isClose) return ;

          tooltipTag.isClose = true;

          this.resizeObserver.unobserve(tooltipTag.root);
          var value = tooltipTag.value;
          await this.close(tooltipTag);
          resolve(value);

          return value;
        };
        tooltipTag.waitClose = () => {
          return tooltipTag.promise;
        };
        tooltipTag.update();
      });

      this.updatePosition(element);

      this.resizeObserver.observe(element);
      this.update();

      if (_options.element && _options.autoClose) {
        const close = () => {
          tooltipTag.close();
        };
        const observer = new IntersectionObserver((entries => {
          const { rootBounds } = entries[0];

          if (!rootBounds) {
            close();
            return ;
          }
          const { width, height } = rootBounds;

          if (width === 0 && height === 0) {
            close();
          }
        }));

        observer.observe(_options.element);
        _options.element.addEventListener('mouseleave', close);
        document.addEventListener('touchend', close);
        document.addEventListener('touchcancel', close);
        tooltipTag.on('unmount', () => {
          _options.element.removeEventListener('mouseleave', close);
          document.removeEventListener('touchend', close);
          document.removeEventListener('touchcancel', close);
          observer.disconnect();
        });
      }
      return tooltipTag;
    };

    this.close = (tooltipTag) => {
      tooltipTag.trigger('close');
      return new Promise(resolve => {

        tooltipTag.refs.tooltip.style.animation = 'tooltip-fade-out 80ms forwards';

        tooltipTag.refs.tooltip.addEventListener('animationend', (e) => {
          this._unmount(tooltipTag);
          resolve();
        });
      });
    };

    this._unmount = (tooltipTag) => {
      tooltipTag.unmount();
      this._hoverElementCache.delete(tooltipTag.__openOptions.element);
    };

    this.showText = ({text, element, options = {}}) => {
      const _options = Object.assign({
        opts: { text },
        element,
      }, options);
      const DEFAULT_MARGIN = 8;
      const { position } = _options;
      if (_options.offsetTop === undefined) {
        if (!position || position === 'bottom') {
          _options.offsetTop = DEFAULT_MARGIN;
        }
        else if (position === 'top') {
          _options.offsetTop = -DEFAULT_MARGIN;
        }
      }
      if (options.offsetLeft === undefined) {
        if (position === 'right') {
          _options.offsetLeft = DEFAULT_MARGIN;
        }
        else if (position === 'left') {
          _options.offsetLeft = -DEFAULT_MARGIN;
        }
      }
      return this.open('tooltip-text', _options);
    };
});