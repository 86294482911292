const riot = require('riot');

riot.tag2('page-index', '<div class="f fclm s-full overflow-hidden"> <div class="f s-full overflow-hidden"> <div class="flex-fixed" ref="workspaces" data-is="module-workspaces"></div> <div class="flex-fixed bg-background_dark_primary text-white overflow-y-scroll scrollbar-none s-hide" ref="sidebar"> <div ref="projects" data-is="module-projects" onworkspaceclick="{toggleWorkspace}" is_visible="{refs.workspaces.getVisible()}"></div> </div> <div class="f flex-column s-full overflow-hidden" ref="main"> <div data-is="module-header" project="{app.store.project}" view="{view}"></div> <div class="s-full overflow-hidden"> <div class="s-full" ref="view"> <div class="s-full f fh text-center lh15 bold" if="{isEmptyView}">参加しているワークスペースがありません。<br>ワークスペースに参加するには、招待を受け取る必要があります。</div> </div> </div> </div> </div> <div class="flex-fixed w-full s-show" show="{!app.utils.hasInputFocus()}" ref="tabbar" data-is="module-tabbar" onclickcurrenttab="{onClickCurrentTab}"></div> </div>', 'page-index,[data-is="page-index"]{display:block}', '', function(opts) {
    this.isEmptyView = false;

    this.preload = async ({req, res}) => {
      if (!spat.isBrowser) return ;

      if (!app.auth.isSignIn()) {
        res.redirect(301, '/signin');
        return ;
      }

      if (!req.params.workspace_id && app.store.workspacesUsersObserver.items.length) {

        var last_accessed_workspace_user = app.store.workspacesUsersObserver.items.reduce((a, b) => {
          return a.data.last_looked_at > b.data.last_looked_at ? a : b;
        });

        if (app.useragent.isMobile) {
          res.redirect(301, `/workspaces/${last_accessed_workspace_user.data.workspace_id}`);
        }
        else {
          res.redirect(301, `/workspaces/${last_accessed_workspace_user.data.workspace_id}/inboxes`);
        }

        return ;
      }

      else if (!req.params.workspace_id && !app.store.workspacesUsersObserver.items.length) {
        return ;
      }

      var view = req.view || req.query.view || 'list';

      return {
        workspace_id: req.params.workspace_id,
        project_id: req.params.project_id,
        note_id: req.params.note_id,
        view: view,
      };
    };

    this.head = () => {
      return {
        title: 'alog',
      };
    };

    this.on('mount', () => {
      if (spat.isBrowser && !app.useragent.isMobile) {
        this.setupSplit();
      }
    });

    this.onClickCurrentTab = () => {

      this.viewTag.trigger('refresh');
    };

    this.setupSplit = () => {

      app.utils.split([
        this.refs.sidebar,
        this.refs.main,
      ], {
        storageKey: 'split_sidebar',
        defaultSizes: [10, 90],
        minSizes: [70, 300],
        onDrag: this.onDrag,
      });
    };

    this.on('show', async ({req, res, cached}) => {
      if (!req.params.workspace_id) {
        this.isEmptyView = true;
        this.update();
        return ;
      }

      await app.store.setup(req.params);

      if (app.store.projectUser?.id) {
        app.utils.history.addProject(app.store.projectUser.id);
      }
      this.update();

      if (this.view !== "inbox" && this.view !== "notifications") {
        this.refs.projects.scrollToActive();
      }

      if (!this.viewTag || this.viewTag.root.dataset.view !== this.view) {

        if (this.viewTag) {
          this.viewTag.unmount();
          delete this.viewTag;
        }

        var element = document.createElement('div');
        element.setAttribute('data-view', this.view);
        element.setAttribute('class', 's-full');
        this.viewTag = riot.mount(element, `module-view-${this.view}`)[0];
        this.refs.view.appendChild(element);
      }

      this.viewTag.setup(req.params, {req, res, cached});

      if (app.store.project) {
        var project = app.store.project;

        app.api.child('me').child(project.path).child('look').put({
          view_name: this.view,
        });
      }
    });

    this.on('hide', () => {

      app.shortcut.setDefaultView();
    });

    this.toggleWorkspace = () => {
      this.refs.workspaces.toggle();
    };

    this.onDrag = (sizes) => {

      let sidebar_width = (this.refs.main.offsetWidth / 100) * sizes[0];
      this.refs.projects.updateMinSize(sidebar_width);
    };
});