const riot = require('riot');

riot.tag2('module-view-notifications', '<div class="f fclm s-full"> <div class="s-full px16 pb16 s-px8 overflow-hidden"> <div class="s-full bg-background_light_secondary box-shadow-primary rounded-12 overflow-scroll" ref="body"> <div class="f fclm container-768"> <div class="sticky t0 z1 f bg-background_light_secondary pt8 mb32 s-mb16"><a class="col4" each="{tab in tabs}" href="/workspaces/{app.store.workspace.id}/notifications{tab.id === &quot;notifications&quot; ? &quot;&quot; : &quot;/&quot; + tab.id}" onclick="{onChangeTab}"> <div class="f fh py16 s-py8 text-{tab.id === current ? &quot;background_dark_primary&quot; : &quot;label_dark_medium&quot;}"><i class="fs16 mr2 s-fs13 icon-{tab.icon}"></i> <div class="lh15 fs13 bold white-space-nowrap s-fs12">{tab.label}</div> </div> <div class="w-full h4 bg-background_dark_primary rounded-1" if="{tab.id === current}"></div></a></div> <div class="relative z0 s-full" if="{current}"> <div class="s-full px8" data-is="module-{current}" if="{app.store.workspace_user}"></div> </div> </div> </div> </div> </div>', 'module-view-notifications,[data-is="module-view-notifications"]{display:block}', '', function(opts) {
    this.tabs = [
      {id: 'notifications', label: '通知', icon: 'notification'},
      {id: 'reminds', label: 'リマインド', icon: 'remind'},
      {id: 'activities', label: 'アクティビティ', icon:'activity'},
      {id: 'favorite_messages', label: 'お気に入り', icon:'star_empty'},
    ];

    this.setup = ({tab}, {req, res, cached}) => {
      tab = tab || 'notifications';

      if (tab === this.current && req.isBack && cached) return ;
      this.update({
        current: null,
      });

      this.current = tab;
      this.update();

      var tag = this.tags['module-' + this.current];
      tag.reload();
    };

    this.onChangeTab = (e) => {
      e.preventDefault();
      spat.router.replace(e.currentTarget.href);
    };

    this.on('refresh', (e) => {
      app.utils.scrollToTop(this.refs.body);
    });
});