const riot = require('riot');

riot.tag2('item-selection-list', '<div class="cursor-pointer selection-list-item {getClass()}" tabindex="-1" onmousemove="{parent.selectionList.onItemSelect}" onmouseenter="{parent.selectionList.onItemSelect}"> <yield></yield> </div>', 'item-selection-list,[data-is="item-selection-list"]{display:block} item-selection-list .selection-list-item,[data-is="item-selection-list"] .selection-list-item{outline:none}', '', function(opts) {
    this.on('mount', () => {
      this.update();
    });

    this.on('update', () => {
      this.item = opts.item;
    });

    this.isSelected = () => {
      return this.parent.selectionList.isSelected(this.root);
    };

    this.getClass = () => {
      const selectedClass = this.opts.selectedClass || "bg-primary_middle_50";
      return this.isSelected() ? selectedClass : '';
    };
});