const riot = require('riot');

riot.tag2('module-print-pdf', '<div class="absolute t0 l0 z99999 min-height-100vh w-full p20 bg-white"> <div class="f fr no-print"> <div> <button class="button w200 p10 primary_fill rounded-8 border border-label_dark_disabled text-white fs18 mr20 mb10" onclick="{printPdf}">PDFを保存</button> <p>※画像が読み込まれてから押してください</p> <button class="absolute t0 r0 cursor-pointer p12" onclick="{() => trigger(⁗close⁗)}"><i class="icon-close fs24 text-label_dark_medium"></i></button> </div> </div> <div class="p24" data-is="atom-markdown" content="{opts.note.data.content}"></div> </div>', 'module-print-pdf,[data-is="module-print-pdf"]{background-color:rgba(28,34,54,0.9)}', '', function(opts) {

    this.printPdf = () => {
      spat.appTag.root.classList.add("no-print");
      try {
        document.execCommand('print', false, null);
      }
      catch(e) {
        window.print();
      }
      spat.appTag.root.classList.remove("no-print");
    };
});