const riot = require('riot');

riot.tag2('modal-workspace-delete', '<div class="relative w-full pt18 px32 pb24 max-width-347 bg-white rounded-12" ref="modal"> <div class="absolute t6 r0 cursor-pointer p12" onclick="{close}"><i class="icon-close fs24 text-label_dark_medium"></i></div> <div class="f fc fs16 lh15 bold mb18">ワークスペースを削除</div> <div class="text-center fs13 lh15 mb12"><span class="bold">{opts.workspace.data.name}</span>を消去しますか？<br>この操作を行うと元に戻せません。</div> <div class="fs10 lh15 bold mb6">ワークスペース名を入力して確定</div> <input class="input light_secondary w-full mb24" ref="input" type="text" oninput="{_.noop}" placeholder="ワークスペース名を入力"> <div class="f fh"> <button class="w113 h40 rounded-8 bold text-background_dark_primary border border-label_dark_medium fs13 mr17" onclick="{close}">{opts.negative || \'キャンセル\'}</button> <button class="w113 h40 rounded-8 bold border fs13 {canDelete() ? &quot;text-alert_primary border-alert_primary&quot; : &quot;text-label_dark_disabled border-label_dark_disabled&quot;}" onclick="{onDelete}" disabled="{!canDelete()}">{opts.positive || \'削除\'}</button> </div> </div>', 'modal-workspace-delete,[data-is="modal-workspace-delete"]{background-color:rgba(28,34,54,0.9)} modal-workspace-delete input::placeholder,[data-is="modal-workspace-delete"] input::placeholder{font-size:13px} modal-workspace-delete input:focus,[data-is="modal-workspace-delete"] input:focus{border:1px solid #1d284c}', 'class="f fh" spat-animation="push"', function(opts) {
    this.on('mount', () => {
    });
    this.canDelete = () => {
      var name = this.refs.input.value;
      return name === this.opts.workspace.data.name;
    };

    this.onDelete = async (e) => {
      e.preventDefault();
      var indicator = spat.modal.indicator();
      try {
        await this.opts.workspace.delete();
        app.utils.openToast('deleteWorkspace');

        setTimeout(() => {
          spat.router.push('/');
        }, 100);

        this.close();
      }
      finally {
        indicator.close();
      }
    };
});