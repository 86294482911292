const riot = require('riot');

riot.tag2('popup-calendar', '<div class="w280 bg-white border px16 pt16 pb8 rounded-8 box-shadow-primary" ref="popup"> <div class="f fm fbw mb5"><i class="icon-arrow-left fs16 cursor-pointer circle hover-trigger hover-bg-dark p3" onclick="{onPrev}"></i> <div class="fs11 lh15 bold" if="{base}">{base.format(\'YYYY年 MM月\')}</div><i class="icon-arrow-right fs16 cursor-pointer circle hover-trigger hover-bg-dark p3 mrn3" onclick="{onNext}"></i> </div> <div class="row"> <div class="px8 pt8 pb4 text-center border-bottom border-separator_opacity mb8" each="{week, index in weeks}" riot-style="width: {100/7}%"> <div class="f fh text-center fs12 text-{getTextColor(index)}">{week}</div> </div> <div class="f fh cursor-pointer my4 hover-trigger" riot-style="width: {100/7}%" each="{date in dates}" onclick="{onSelectedDate}"> <button class="f fh s26 circle flex-fixed {getDateClass(date)}" data-date="{date.format(&quot;YYYY-MM-DD&quot;)}" tabindex="-1" onkeydown="{moveKey}"> <div class="font-lato lh15 fs12 text-center">{date.date()}</div> </button> </div> </div> </div>', '', '', function(opts) {

    this.weeks = ["月", "火", "水", "木", "金", "土", "日"];

    this.on('mount', () => {
      this.setup();
    });

    this.setup = (base) => {
      this.base = base || dayjs();

      const start = this.base.startOf('month');

      const end = this.base.endOf('month');

      this.dates = Array(end.date()).fill(0).map((v, i) => {

        return start.add(i, 'day').endOf('date');
      });

      var prev_month_date_num = ((start.day() + this.weeks.length)-1) % this.weeks.length;
      Array(prev_month_date_num).fill(0).forEach((v, i) => {
        this.dates.unshift(start.add(-(i+1), 'day').endOf('date'));
      });

      var next_month_date_num = this.weeks.length * 6 - this.dates.length;
      Array(next_month_date_num).fill(0).forEach((v, i) => {
        this.dates.push(end.add(i+1, 'day').endOf('date'));
      });
      this.update();
    };

    this.isToday = (date) => {
      return this.today.format('YYYY-MM-DD') === date.format('YYYY-MM-DD');
    };

    this.onPrev = () => {
      this.setup(this.base.add(-1, 'month'));
    };

    this.onNext = () => {
      this.setup(this.base.add(1, 'month'));
    };

    this.getTextColor = (i) => {
      const SATURDAY = 5;
      const SUNDAY = 6;
      if (i === SUNDAY) {
        return 'alert_primary';
      }
      else if (i === SATURDAY) {
        return 'deep';
      }
      else {
        return 'label_dark_medium';
      }
    };

    this.getDateClass = (date) => {
      var class_name = "";

      let is_today = date.isSame(Date.now(), "date");

      let is_this_month = date.isSame(this.base, "month");

      let is_saturday = date.day() === 6;

      let is_sunday = date.day() === 0;

      if (is_today) {
        class_name += "bg-primary text-white bold";
      }

      else {

        if (is_this_month && !is_today) {

          if (is_saturday) {
            class_name += 'text-deep';
          }

          else if (is_sunday) {
            class_name += 'text-alert_primary';
          }
        }

        else {

          if (is_saturday) {
            class_name += 'text-deep_50';
          }

          else if (is_sunday) {
            class_name += 'text-alert_primary_50';
          }

          else {
            class_name += "text-label_dark_disabled";
          }
        }

        class_name += ' hover-bg-dark'
      }

      return class_name;
    };

    this.onSelectedDate = (e) => {
      var date = e.item.date.format("YYYY/M/D(ddd)");
      this.value = date;
      this.close();
    };

    this.on('mount', () => {
      requestAnimationFrame(() => {
        this.getSelectDateElement().focus();
      });
    });

    this.choiceDay = dayjs();
    this.getSelectDateElement = () => {
      return this.root.querySelector(`[data-date="${this.choiceDay.format('YYYY-MM-DD')}"]`);
    };

    this.moveKey = (e) => {
      let base_month = this.choiceDay.month();

      switch (e.key) {
        case 'ArrowRight':

          this.choiceDay = this.choiceDay.add(1, 'day');

          if (base_month !== this.choiceDay.month()) {
            this.onNext();
          }
          break;
        case 'ArrowLeft':

          this.choiceDay = this.choiceDay.subtract(1, 'day');

          if (base_month !== this.choiceDay.month()) {
            this.onPrev();
          }
          break;
        case 'ArrowDown':

          this.choiceDay = this.choiceDay.add(this.weeks.length, 'day');

          if (base_month !== this.choiceDay.month()) {
            this.onNext();
          }
          break;
        case 'ArrowUp':

          this.choiceDay = this.choiceDay.subtract(this.weeks.length, 'day');

          if (base_month !== this.choiceDay.month()) {
            this.onPrev();
          }
          break;
        default:
            return;
      };

      this.getSelectDateElement().focus();
    };
});