const riot = require('riot');

riot.tag2('module-tabbar', '<div class="pb-homebar w-full bg-background_dark_deep text-label_white_medium px12"> <div class="f fm w-full h50"><a class="s-full py4" each="{item in tabItems}" href="{item.link}" onclick="{onClickTab}" ontouchend="{() =&gt; capacitor.hapticsImpactLight()}"> <div class="relative f fh fclm s-full rounded-12 {\'bg-background_dark_primary text-white\' : item.id === getLastPathname()}"><i class="fs20 mb4 icon-{item.icon}" if="{!item.image}"></i><img class="fs20 mb4 s22 rounded-8" if="{item.image}" riot-src="{item.image}"> <div class="fs10">{item.name}</div> <div class="absolute tn2 r18 f fh s16 circle bg-background_dark_deep" if="{item.getUnreadCount()}"><i class="flex-fixed fh f s14 circle bg-alert_primary fs9 text-white font-lato bold icon-mention" if="{item.hasMention()}"></i> <div class="flex-fixed fh f s14 circle bg-alert_primary fs9 text-white font-lato bold" if="{item.id !== \'inboxes\' || !item.hasMention()}">{item.getUnreadCount()}</div> </div> </div></a></div> </div>', 'module-tabbar,[data-is="module-tabbar"]{display:block}', '', function(opts) {
    this.tabItems = [

      {
        get id() { return `${app.store.workspace.id}`; },
        get name() { return app.store.workspace.data.name; },
        get image() { return app.utils.getImageUrl(app.store.workspace.data.icon_image); },
        get link() { return `/${app.store.workspace.path}`; },
      },

      {
        name: '検索',
        icon: "search",
        onclick: () => { app.shortcut.run('openSwitcher'); },
      },

      {
        id: 'inboxes',
        name: 'inbox',
        icon: "inbox",
        get link() { return `/${app.store.workspace.path}/inboxes`; },

        getUnreadCount: () => { return app.store.inboxesStore.items.length; },

        hasMention: () => { return this.hasMention(); }
      },

      {
        id: 'notifications',
        name: '通知',
        icon: "notification",
        get link() { return `/${app.store.workspace.path}/notifications`; },
      },

      {
        id: 'reminds',
        name:'リマインド',
        icon:"remind",
        get link() { return `/${app.store.workspace.path}/notifications/reminds`; },

        getUnreadCount: () => { return app.store.getWorkspaceUser().data.remind_count; },
      },
    ];

    this.hasMention = () => {
      return app.store.inboxesStore.items.some(inbox => inbox.isMention());
    };

    this.getLastPathname = () => {
      var pathname_array = location.pathname.split('/');
      var [last_path] = pathname_array.slice(-1);
      return last_path;
    };

    this.mixin('enableOptsEventHandler');

    this.onClickTab = (e) => {

      let tab = e.item.item;

      tab.onclick?.();

      if (tab.id === this.getLastPathname()) {

        this.trigger('clickcurrenttab', {
          id: tab.id
        });
      }
    };
});