const riot = require('riot');

riot.tag2('popup-suggestion-notes', '<div class="w342 px8" ref="popup"> <div class="f fm flex-between mb4"> <div class="f fm bold fs13"> <button class="f fm bg-background_light_primary hover-trigger hover-bg-pale hover-text-label_dark_primary rounded-4 p4 mr4" onclick="{onClickProject}"> <div class="line-clamp-1 word-break-all word-keep-all lh15 mr4">{project ? project.data.name : ⁗全てのプロジェクト⁗}</div><i class="icon-arrow-down text-background_dark_primary"></i> </button><span class="flex-fixed">のノートを検索</span> </div> <div class="p4 cursor-pointer" onclick="{close}"><i class="icon-close fs24 text-label_dark_medium"></i></div> </div> <form class="block mb8" onsubmit="{submit}" onkeydown="{selectionList.onKeyDown}"> <div class="relative input light_secondary f fm mb8"><i class="icon-search text-label_dark_disabled fs16 mr8"></i> <input class="w-full" ref="search" placeholder="プロジェクトのノートを検索する" oninput="{debounceSearch}"><i class="icon-close absolute t0 b0 r0 f fh h-full p6 fs16 cursor-pointer" onclick="{onDeleteValue}" if="{refs.search.value}"></i> </div> <div class="overflow-y-scroll scrollbar-none transition-128" riot-style="max-height: {notes ? 168 : 0}px"> <div each="{note, index in notes}" data-is="item-selection-list" item="{note}" onclick="{setValue}" selected-class="bg-pale"> <div class="p4 cursor-pointer"> <div class="inline-block mb2" if="{!parent.project}" data-is="atom-project" item="{note.getProject()}"></div> <div class="f fm fbw"> <div class="lh15 fs11 line-clamp-1 word-break-all">{note.data.title || ⁗Untitled⁗}</div> <div class="pointer-none pl4" if="{note.data.assigned_user_refs.length}" data-is="atom-assign" note="{note}"></div> </div> </div> </div> </div> </form> </div>', 'popup-suggestion-notes input:focus,[data-is="popup-suggestion-notes"] input:focus{outline:none !important}', 'class="bg-white box-shadow-primary rounded-8"', function(opts) {

    this.mixin('selectionList');
    this.on('mount', async () => {
      this.project = app.store.project;
      setTimeout(() => {
        this.refs.search.focus();
      });
      this.notes = await this.fetchSearchNotes();
      this.update();
    });

    this.fetchSearchNotes = async () => {
      const show_project = !this.project;
      var {note_refs} = await app.api.child(app.store.workspace.ref.path).child('projects/search_note').get({
        keyword: this.refs.search.value,
        limit: 64,
        project_id: show_project ? undefined : this.project.id,
      });
      const promises = note_refs.map(async note_path => {
        const note = await app.store.doc(note_path).fetch();
        if (show_project) await note.getProject().fetch();
        return note;
      });
      return await Promise.all(promises);
    };

    this.debounceSearch = _.debounce( async () => {
      this.notes = await this.fetchSearchNotes();
      this.update();
    }, 64);

    this.submit = (e) => {
      e.preventDefault();
      this.value = this.getSelectNote(this.selectionList.selectedIndex);
      this.close();
    };

    this.setValue = () => {
      this.value = this.getSelectNote(this.selectionList.selectedIndex);
      this.close();
    };

    this.getSelectNote = (index) => {
      return this.notes[index];
    };

    this.onDeleteValue = () => {
      this.refs.search.value = '';
      this.refs.search.focus();
      this.debounceSearch();
    };

    this.on('selectionList.keydown.enter', () => {
      this.setValue();
    });

    this.onClickProject = async (e) => {
      const popup = spat.popup.open('popup-suggestion-projects', {
        element: e.currentTarget,
        closeTarget: e.currentTarget,
        opener: this.root,
        position: 'bottom',
      });
      const value = await popup.waitClose();

      requestAnimationFrame(() => {
        this.refs.search.focus();
      });

      if (value) {

        if (value.type === 'all') {
          this.project = undefined;
        }
        else {

          this.project = value;
          await value.fetch();
        }

        this.notes = [];
        this.update();

        this.notes = await this.fetchSearchNotes();
        this.update();
      }
    };
});