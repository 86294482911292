const LINE_HEAD_SYMBOLS = [
  '- [x]',
  '- [ ]',
  '*',
  '+',
  '-',
];

/**
 * 行頭に記号が付くスタイルを形成。すでに記号がついている場合は置き換える
 * @param {string} str 
 * @param {string} symbol 
 */
const replace_line_head = (str, symbol) => {
  const WHITE_SPACE = ' ';
  // 前方の空白とそれ以降を取り出す
  const [, spaces = '', trimmed = ''] = str.match(/^(\s*)(.*)$/) || [];
  // 行頭に記号がついているかチェック
  let found_symbol = LINE_HEAD_SYMBOLS.find(sym => trimmed.indexOf(sym) === 0);
  // 更に # と 1. のパターンを調べる
  if (!found_symbol) {
    const patterns = [
      /^(#+)\s/,
      /^(\d+\.)\s/,
    ];
    patterns.forEach(pattern => {
      const matched = trimmed.match(pattern);
      if (matched) {
        found_symbol = matched[1];
      }
    });
  }
  if (found_symbol) {
    // 記号が見つかった場合は置き換えて返す
    return `${spaces}${symbol}${WHITE_SPACE}${trimmed.substring(found_symbol.length).trim()}`;
  }
  else {
    return `${spaces}${symbol}${WHITE_SPACE}${trimmed}`;
  }
};

export default class EditorUtil {
  /**
   * 
   * @param {CodeMirror.Editor} editor
   */
  constructor(editor) {
    this.editor = editor;
  }

  // カーソル位置を取得
  getCursor(start = 'from') {
    return this.editor.getCursor(start);
  }

  // 選択範囲の最後のカーソル位置を取得
  getCursorEnd() {
    return this.getCursor('to');
  }

  getSelection() {
    return this.editor.getSelection();
  }

  // カーソル位置を設定
  setCursor(pos) {
    this.editor.setCursor(pos);
  }

  // カーソル位置を行頭に移動
  setCursorToLineStart() {
    this.setCursor({ ch: 0, line: this.getCursor().line });
  }
      
  // カーソル位置を行末に移動
  setCursorToLineEnd() {
    const line = this.getCursor().line;
    this.setCursor({ ch: this.editor.getLine(line).length, line });
  }

  // 指定の文字数分、行数分カーソルを移動
  moveCursor(ch = 0, line = 0) {
    const cursor = this.getCursor();
    this.setCursor({ ch: cursor.ch + ch, line: cursor.line + line });
  }

  // 現在のカーソルの位置に文字を挿入
  insertTextAtCursor(text) {
    this.editor.replaceRange(text, this.getCursor());
  }
  
  // カーソルを末尾にあてる
  setCursorToLast() {
    this.setCursor({ ch: Infinity, line: Infinity });
  }

  // 現在の cursor から指定文字を基準に範囲選択
  setSelectionByString(str = '', direction = 'left') {
    const splitted = str.split('\n');
    const cursor = this.getCursor();
    const move_rate = direction === 'left' ? -1 : 1;
    // 一行のとき
    if (splitted.length === 1) {
      this.editor.setSelection({ line: cursor.line, ch: cursor.ch + str.length * move_rate }, cursor);
    }
    else {
      // 複数行のとき
      const last_line = direction === 'left' ? splitted[splitted.length - 1] : splitted[0];
      const last_line_length = last_line.length;
      this.editor.setSelection({ line: cursor.line + (splitted.length - 1) * move_rate, ch: last_line_length * move_rate }, cursor);
    }
  }

  // 記号が文字を囲うスタイルを形成
  replaceEnclosing(symbol) {
    // 選択中の文字を取得
    const selection = this.getSelection();
    // 形成
    const formatted_text = symbol + selection + symbol;
    // テキストを置き換える
    this.editor.replaceSelection(formatted_text);
    this.editor.focus();

    requestAnimationFrame(() => {
      // 改行している場合はカーソルを末尾にあてる
      if (/\r?\n/.test(formatted_text)) {
        this.setCursorToLast();
      }
      else {
        // cursor を左に symbol の文字数分移動
        this.moveCursor(-symbol.length);
        // 記号を取り除いたテキストを選択状態にする
        this.setSelectionByString(selection);
      }
    });
  }

  // コードブロックを形成
  replaceCodeBlock() {
    //- 選択中の文字を取得
    const selection = this.getSelection();
    //- 改行している場合
    if (/\r?\n/.test(selection)) {
      //- コードブロック下の行の数
      const UNDER_SYMBOL_LINE_NUMBER = 1;
      const CODE_BLOCK_SYMBOL = '```';
      const cursor = this.getCursor();
      const from = { ch: 0, line: cursor.line };
      const to = this.getCursorEnd();
      to.ch = this.editor.getLine(to.line).length;
      // 中途半端な位置を選択していてもちゃんと最初の行の行頭から最後の行の行末の範囲から取得する
      const range_string = this.editor.getRange(from, to);
      //- テキストの上下を記号で囲むように形成
      const formatted_text = `${CODE_BLOCK_SYMBOL}\n${range_string}\n${CODE_BLOCK_SYMBOL}`;
      //- テキストを置き換える
      this.editor.replaceRange(formatted_text, from, to);
      this.editor.focus();

      requestAnimationFrame(() => {
        // 前の行末をへカーソルを移動
        this.moveCursor(Infinity, -UNDER_SYMBOL_LINE_NUMBER);
        // 記号を取り除いたテキストを選択状態にする
        this.setSelectionByString(selection);
      });
    }
    //- 改行していないもしくは、文字を選択していない場合
    else {
      this.replaceEnclosing('`');
    }
  }

  // 文字の頭に記号が付くスタイルを形成
  replaceLineHead(symbol) {
    //- 選択中の文字を取得
    const selection = this.getSelection();
    //- 選択文字がない場合
    if (!selection) {
      const cursor = this.getCursor();
      const line_string = this.editor.getLine(cursor.line);
      const from = { ch: 0, line: cursor.line };
      const to = { ch: line_string.length, line: cursor.line };
      // すでに記号がついている場合を考慮して置換する
      const formatted_text = replace_line_head(this.editor.getRange(from, to), symbol);
      this.editor.replaceRange(formatted_text, from, to);
      this.editor.focus();
    }
    //- 文字選択している場合
    else {
      const cursor = this.getCursor();
      const from = { ch: 0, line: cursor.line };
      const to = this.getCursorEnd();
      // 中途半端な位置を選択していてもちゃんと行頭の範囲から取得する
      const range_string = this.editor.getRange(from, to);

      //- 形成(空白の行や空文字の行は記号を付けない)
      const formatted_text = range_string.split('\n').map(s => {
        if (s.trim()) return replace_line_head(s, symbol);
        return '';
      }).join('\n');

      //- テキストを置き換える
      this.editor.replaceRange(formatted_text, from, to);
      //- アイコン押したときにblurするので再度フォーカスする
      this.editor.focus();
      requestAnimationFrame(() => {
        //- 範囲の最後にカーソルを移動
        this.moveCursor(Infinity);
      });
    }
  }

  // リンク挿入のスタイルを形成
  replaceMarkdownLink(symbol) {
    //- 選択中の文字を取得
    const selection = this.getSelection();
    const INSERT_POSITION = 1;
    //- 選択文字がない場合
    if (!selection) {
      //- カーソルの位置に記号を挿入する
      this.insertTextAtCursor(symbol);
      this.editor.focus();
      // 各カッコの前にカーソルを移動
      this.moveCursor(-symbol.length + INSERT_POSITION);
    }
    //- 文字選択している場合
    else {
      const DEFAULT_URL = 'https://~';
      //- 閉じ括弧の数
      const CLOSING_PARENTHESIS_NUMBER = 1;
      //- urlの文字を選択状態にする
      let formatted_text = `[${selection}](${DEFAULT_URL})`;
      //- テキストを置き換える
      this.editor.replaceSelection(formatted_text);
      //- アイコン押したときにblurするので再度フォーカスする
      this.editor.focus();
      requestAnimationFrame(() => {
        // カーソルをカッコの前に移動
        this.moveCursor(-CLOSING_PARENTHESIS_NUMBER);
        //- DEFAULT_URL の箇所を選択状態にする
        this.setSelectionByString(DEFAULT_URL);
      });
    }
  }

  // テーブルをMarkdown形式で挿入
  insertMarkdownTable(rows, cols, headerString = 'Header') {
    // 初期化
    let table_string = '\n|';

    // ヘッダーの生成
    for (let i = 0; i < cols; i++) {
      // headerString + ヘッダー番号（1からスタート）を追加
      table_string += ` ${headerString}${i + 1} |`;
    }
    // ヘッダー行の終了と次の行への移行
    table_string += '\n|';

    // ヘッダーと内容を区切る線を生成
    for (let i = 0; i < cols; i++) {
      table_string += '--------|';
    }

    // 行の生成
    for (let i = 0; i < rows; i++) {
      // 新しい行の開始
      table_string += '\n|';

      // 列の生成
      for (let j = 0; j < cols; j++) {
        // 列の追加
        table_string += '-|';
      }
    }

    // テーブルをカーソル位置に挿入
    this.insertTextAtCursor(table_string);
  }
}