const riot = require('riot');

riot.tag2('modal-confirm', '<div class="relative w-full max-width-347 bg-white rounded-12" ref="modal"> <div class="absolute t0 r0 cursor-pointer p12" onclick="{close}"><i class="icon-close fs24 text-label_dark_medium"></i></div> <div class="pt44 pb24"> <div class="text-center px24 text-label_dark_primary mb20"> <div class="lh15 word-break-all white-space-pre-wrap" data-is="atom-raw" content="{opts.message}"></div> </div> <div class="f fc px52"> <button class="button dark_light w-full py9 mr17" onclick="{onCancel}">{opts.negative || \'キャンセル\'}</button> <button class="button alert_primary w-full py9" onclick="{onAccept}">{opts.positive || \'OK\'}</button> </div> </div> </div>', 'modal-confirm,[data-is="modal-confirm"]{background-color:rgba(28,34,54,0.9)}', 'class="f fh" spat-animation="push"', function(opts) {

    this.on('mount',() => {
      this.value = false;
    });

    this.onCancel = () => {
      this.value = false;
      this.close();
    };

    this.onAccept = () => {
      this.value = true;
      this.close();
    };
});