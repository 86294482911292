const riot = require('riot');

riot.tag2('modal-assign-user', '<div class="f fclm w472 h462 bg-white border border-pale rounded-8 px16 pt19 pb24 s-s-full" ref="modal"> <div class="flex-fixed"> <div class="f fm fbw mb18"> <div class="bold fs16 lh15">アサインユーザーを編集</div><i class="icon-close fs24 text-label_dark_medium cursor-pointer" onclick="{close}"></i> </div> <div class="f fm fbw mb6"> <div class="fs13 lh15 bold">ユーザー一覧</div> <div class="lh15 text-label_dark_medium"><span class="fs13 bold">{getAssignedUser().length}</span><span class="fs11">/{projectUsers.length} user</span></div> </div> </div> <div class="relative w-full mb8"> <input class="input focus-pale w-full pr24" ref="input" type="text" placeholder="ユーザーを追加" oninput="{onDebounceSearch}" onkeydown="{onKeyDown}" onblur="{onInputBlur}" onfocus="{onInputFocus}"><i class="icon-close absolute t0 b0 f fh r8 fs16 cursor-pointer" onclick="{onDeleteQuery}" if="{refs.input.value}"></i> </div> <div class="s-full f fclm overflow-hidden mb24"> <div class="s-full border border-separator_opacity rounded-8 overflow-scroll mb16"> <div class="f fm cursor-pointer p8 {&quot;bg-pale&quot; : i === selectedIndex}" each="{user, i in displayUsers}" ref="user" onmouseenter="{onHoverSelect}" onclick="{onCheckedUser}"><i class="flex-fixed s16 fs16 mr8 {&quot;icon-check text-primary&quot;: user.checked}"></i> <div class="w-full" data-is="item-user" item="{user}"></div> </div> </div> <div class="flex-fixed" if="{getAssignedUser().length}"><img class="s24 circle object-fit-cover {&quot;mln10&quot; : i !== 0}" each="{user, i in getAssignedUser()}" riot-src="{app.utils.getImageUrl(user.data.icon_image)}"></div> </div> <div class="f fc"> <button class="button w220 primary" onclick="{onUpdate}" disabled="{lock}">更新</button> </div> </div>', '', 'class="f fh bg-overlay_secondary" spat-animation="push"', function(opts) {

    this.selectedIndex = 0;

    this.projectUsers = [];

    this.assignUsers = [];

    this.on('mount', async () => {
      await this.fetchProjectUsers();
    });

    this.fetchProjectUsers = async () => {
      var project = this.opts.note.parent.parent;
      var [project_users, assigned_users] = await Promise.all([
        project.fetchProjectUsers(),
        this.opts.note.fetchAssignedUsers(),
      ]);

      app.utils.displayUsersSort(project_users);

      this.projectUsers = project_users.map(user => {
        user.checked = assigned_users.some(u => u.id === user.id);
        return user;
      });

      this.displayUsers = this.projectUsers;
      this.update();
    };

    this.onDebounceSearch = _.debounce((e) => {
      this.searchKeyword(e);
      this.update();
    }, 128);

    this.searchKeyword = (e) => {

      var value = e.target.value.trim();

      this.selectedIndex = 0;

      if(!value) {

        this.displayUsers = this.projectUsers;
      }

      else {
        this.displayUsers = [];

        this.displayUsers = app.utils.filterUsers(this.projectUsers, value);
      }
    };

    this.onKeyDown = (e) => {

      if (!this.displayUsers.length) return;
      var code = e.keyCode;
      var isArrowUp = code === 38;
      var isArrowDown = code === 40;
      var isEnter = code === 13;

      var selectUp = () => {
        e.preventDefault();
        this.selectedIndex = Math.max(0, this.selectedIndex - 1);

        var $suggestion = this.refs.user[this.selectedIndex];

        $suggestion.scrollIntoView({inline: "nearest", block: "nearest"});
      };

      var selectDown = () => {
        e.preventDefault();
        this.selectedIndex = Math.min(this.displayUsers.length - 1, this.selectedIndex + 1);

        var $suggestion = this.refs.user[this.selectedIndex];

        $suggestion.scrollIntoView({inline: "nearest", block: "nearest"});
      };

      if (isEnter) {
        e.preventDefault();
        var selected_user = this.displayUsers[this.selectedIndex];

        selected_user.checked = !selected_user.checked;
      }

      if (this.displayUsers.length === 1) return;
      if (isArrowUp) {
        selectUp();
      }
      else if (isArrowDown) {
        selectDown();
      }
    };

    this.onHoverSelect = (e) => {
      this.selectedIndex = e.item.i;

      this.update();
    };

    this.onCheckedUser = (e) => {
      e.preventDefault();
      e.item.user.checked = !e.item.user.checked;
    };

    this.focusInput = () => {
      this.refs.input.focus();
    };

    this.onUpdate = async () => {
      if (this.lock) return ;
      this.update({lock: true});
      try {
        var users = this.projectUsers.filter(item => item.checked);
        await this.opts.note.assign(users.map(user => user.id));

        this.value = users;
        this.close();
        app.utils.openToast('update');
      }
      catch(e) {
        spat.modal.alert('更新に失敗しました');
      }
      finally {
        this.update({lock: false});
      }
    };

    this.getAssignedUser = () => {
      return this.projectUsers.filter(u => u.checked);
    };

    this.onDeleteQuery = () => {
      this.refs.input.value = '';
      this.refs.input.focus();
      this.displayUsers = this.projectUsers;
      this.update();
    };
});