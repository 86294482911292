const riot = require('riot');

riot.tag2('item-activity', '<div class="f fm bg-white rounded-8 py16 transition box-shadow-primary-0_15"> <div class="w-full px16 word-break-all overflow-hidden"> <div class="f fm fbw w-full mb8"> <div if="{getProject()}" data-is="atom-project" item="{getProject()}"></div> <div class="f fm"><span class="font-lato fs10 text-label_dark_medium mr16" data-is="atom-time" date="{this.opts.item.data.created_at}"></span><i class="icon-fullscreen text-label_dark_medium fs16 cursor-pointer tooltip" onclick="{onOpenNote}" aria-label="開く" data-tooltip-position="top" data-tooltip-offset-top="4"></i></div> </div> <p if="{this.opts.item.data.kind === &quot;create_note&quot;}"><a class="text-primary" href="{getNoteLink()}"><b>{getNoteTitle()} </b></a>を <b>作成</b>しました</p> <div if="{this.opts.item.data.kind === &quot;post_message&quot;}"> <p class="mb8"><a class="text-primary" href="{getNoteLink()}"><b>{getNoteTitle()} </b></a>に <a class="text-primary" href="{getMessageLink()}"><b>コメント </b></a>しました。</p> <div class="max-height-120 overflow-hidden text-label_dark_primary mb8" data-is="atom-markdown" content="{getMessage()}"></div> <div ref="reactions" data-is="module-message-reactions" item="{opts.item.relation.message}"></div> </div> </div> </div>', 'item-activity,[data-is="item-activity"]{display:block} item-activity .box-shadow-primary-0_15:hover,[data-is="item-activity"] .box-shadow-primary-0_15:hover{box-shadow:0 6px 14px rgba(29,40,76,0.15)}', '', function(opts) {
    this.on('mount', () => {
      if (this.opts.item.data.kind === "post_message") {
        this.refs.reactions.on('submit', () => {
          this.refreshReaction();
        });
      }
    });

    this.getNote = () => {
      return this.opts.item.relation.note;
    };

    this.getNoteTitle = () => {
      var note = this.getNote();
      return note.getTitle();
    };

    this.getNoteLink = () => {
      var path = this.getNote().ref.path;
      return '/' + path;
    };

    this.getMessage = () => {
      return this.opts.item.relation.message.data.content;
    };

    this.getMessageLink = () => {
      return '/' + this.opts.item.data.message_ref.path;
    };

    this.getProject = () => {
      return this.opts.item.relation.project;
    };

    this.onOpenNote = async () => {
      var note = this.getNote();
      if (app.useragent.isMobile) {
        spat.router.push(`/${note.path}`);
      }
      else {
        var modal = spat.modal.open('modal-note', {
          note,
        });

        await modal.waitClose();

        this.update();
      }
    };

    this.getProjectLink = () => {
      var path = this.getProject().path;
      return '/' + path;
    };

    this.refreshReaction = async () => {
      await this.opts.item.relation.message.refresh();
      this.update();
    };
});