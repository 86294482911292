const riot = require('riot');

riot.tag2('popup-tool-list', '<div class="f fm pl12 bg-white rounded-8 box-shadow-primary" ref="popup"> <div class="f fm border-right-not-last cursor-pointer my12 mr12 mr0-last" each="{items in getSectionStyleItems()}"> <div class="hover-trigger mr12 tooltip" each="{item in items}" onclick="{select}" aria-label="{item.tooltip}" data-tooltip-position="top"><i class="fs16 text-label_dark_medium hover-text-label_dark_primary icon-{item.icon}"></i></div> </div> </div>', '', '', function(opts) {
    this.select = (e) => {
      let item = e.item.item;
      this.trigger('selected', item);
    };

    this.getSectionStyleItems = () => {
      let sections = _.groupBy(this.opts.items, 'type');
      return sections;
    };
});