const riot = require('riot');

riot.tag2('popup-emoji-mini', '<div class="cursor-pointer" ref="popup" onmouseleave="{close}"> <div class="bg-white rounded-6 box-shadow-primary px2"> <div class="f fm" data-is="module-emoji-mini" ref="emoji"></div> </div> <div class="f fc w-full h4" onclick="{onclick}"> <div class="h30 w40 mrn16"></div> </div> </div>', '', '', function(opts) {
    this.on('mount', () => {

      this.refs.emoji.on('selected', (emoji) => {
        this.trigger('selected', emoji);
      });
    });

    this.onclick = () => {
      this.trigger('hideSpaceClick');
    };
});