const riot = require('riot');

riot.tag2('modal-profile', '<div class="modal bg-white w472 s-w-full rounded-8 m8" ref="modal"> <form class="p32" onsubmit="{submit}"> <div class="f fh"> <div class="s80 circle overflow-hidden mb21" ref="icon" data-is="atom-input-image" riot-src="{app.store.currentUser.data.icon_image.url}" icon-size="48"></div> </div> <div class="mb24"> <label> <div class="mb12"> <div class="fs10 lh13 mb6">表示名</div> <input class="input large light_secondary w-full" ref="name" placeholder="{app.data.input.placeholder.display_name}" required> </div> </label> <label> <div class="lh15 mb12"> <div class="f fm lh13 fs10 mb6">ユーザー名</div> <label class="focus-border input large light_secondary f fm"> <div>@</div> <input class="w-full" ref="id" placeholder="{app.data.input.placeholder.id}" pattern="{app.data.input.validation.screen_name}" required> </label> <div class="fs10 text-label_dark_medium">半角英数3文字以上。記号は - と _ のみ使用可能。</div> </div> </label> </div> <div class="f fh"> <button class="button w220 primary transition">更新 </button> </div> </form> </div>', 'modal-profile,[data-is="modal-profile"]{background-color:rgba(0,0,0,0.5)} modal-profile input::placeholder,[data-is="modal-profile"] input::placeholder{color:rgba(29,40,76,0.2);font-size:13px} modal-profile input:focus,[data-is="modal-profile"] input:focus{outline:none !important}', 'class="f fh s-px8" spat-animation="push"', function(opts) {
    this.on('mount', () => {
      if (spat.isNode) return;

      var user = app.store.currentUser;

      this.refs.name.value = user.data.display_name;
      this.refs.id.value = user.data.screen_name;

      this.update();
    });

    this.submit = async (e) => {
      e.preventDefault();

      var i = spat.modal.indicator();

      var data = {
        display_name: this.refs.name.value.trim(),
        screen_name: this.refs.id.value.trim(),
      };

      try {
        var url = await this.refs.icon.uploadAndGetImageURL();
        if (url) {
          data.icon_image = {
            url: url,
          };
        }
        await app.api.child('me').put(data);
        spat.modal.alert('更新しました。');
        this.close();
      }
      catch (e) {
        console.error(e);
      }
      finally {
        i.close();
      }
    };
});