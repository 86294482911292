const riot = require('riot');

riot.tag2('module-view-toc', '<div class="f fclm s-full"> <div class="px16 mb10" data-is="module-note-filter" ref="filter" users="{projectUsers}"></div> <div class="s-full px16 pb16 overflow-hidden"> <div class="bg-white s-full box-shadow-primary rounded-12 overflow-scroll"> <div class="px32 py16"> <p class="fs13 lh15 word-break-word mb4" data-is="atom-markdown" content="{markdownContent}"></p> </div> </div> </div> </div>', 'module-view-toc,[data-is="module-view-toc"]{display:block}', '', function(opts) {
    this.markdownContent = 'loading...';

    this.on('mount', () => {
      if (spat.isNode) return ;

      this.refs.filter.on('filterchange', () => {
        this.updateMarkdown();
      });
    });

    this.on('unmount', () => {

      if (this.columnsStore) {
        this.columnsStore.unwatch();
        this.columnsStore.removeAllListeners();
        delete this.columnsStore;
      }
    });

    this.setup = async ({note_id}, {req}) => {

      this.project = app.store.project;
      this.update();

      this.columnsStore = this.project.collection('columns');
      this.columnsStore.on('snapshot', async () => {
        await this.updateColumns();
        this.updateMarkdown();
      });
      await this.columnsStore.watch();

      this.projectUsers = await this.project.fetchProjectUsers();
      this.update();
    };

    this.updateColumns = async () => {
      let promises = this.columnsStore.items.filter((column) => {
        return column.data.rule === 'noop';
      }).sort((a, b) => {
        return a.data.sort_num - b.data.sort_num;
      }).map(async (column) => {
        let promises = column.data.note_refs.map((note_ref) => {
          return app.store.doc(note_ref.path).fetch({relation:false});
        });

        let notes = await Promise.all(promises);

        return {
          column: column,
          notes: notes,
        };
      });

      this.columns = await Promise.all(promises);
    };

    this.updateMarkdown = async () => {
      if (!this.columns) return ;

      let query = this.refs.filter.getQuery();

      this.markdownContent = this.columns.map(column => {
        let lines = [];

        lines.push(`## ${column.column.data.name}(${column.notes.length})`);
        lines.push('\n');

        let notes = app.utils.filterNotes(column.notes, query);

        notes.forEach(note => {
          let title = note.data.title || '(新しいノート)';
          lines.push(`- [${title}](/${note.path})`);
        });

        lines.push('\n');

        return lines.join('\n');
      }).join('\n');

      this.update();
    };
});