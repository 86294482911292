const riot = require('riot');

riot.tag2('module-action-menus', '<div class="f fm" if="{!opts.isEdit &amp;&amp; opts.isMine}"> <div class="cursor-pointer hover-trigger p4 circle hover-trigger hover-bg-pale transition-128 tooltip s-mr8" onclick="{onEdit}" role="button" aria-label="編集" data-tooltip-position="top"><i class="icon-edit fs16 text-label_dark_medium hover-text-background_dark_primary s-fs20"></i></div> <div class="cursor-pointer hover-trigger p4 circle hover-trigger hover-bg-pale transition-128 tooltip" onclick="{onMenuClick}" role="button" aria-label="メニュー" data-tooltip-position="top"><i class="icon-more_vertical fs16 text-label_dark_medium hover-text-background_dark_primary s-fs24"></i></div> </div> <div class="f fm" if="{!opts.isMine}"> <div class="cursor-pointer hover-trigger p4 circle hover-trigger hover-bg-pale transition-128 tooltip s-mr8" onclick="{onReply}" role="button" aria-label="返信" data-tooltip-position="top"><i class="icon-reply fs16 text-label_dark_medium hover-text-background_dark_primary s-fs20"></i></div> <div class="cursor-pointer hover-trigger p4 circle hover-trigger hover-bg-pale transition-128 tooltip" onclick="{onMenuClick}" role="button" aria-label="メニュー" data-tooltip-position="top"><i class="icon-more_vertical fs16 text-label_dark_medium hover-text-background_dark_primary s-fs24"></i></div> </div>', '', '', function(opts) {
    this.onReply = () => {
      this.trigger('replyClick');
    };

    this.onEdit = () => {
      this.trigger('editClick');
    };

    this.onMenuClick = (e) => {
      this.trigger('menuClick', e);
    };
});