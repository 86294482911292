const riot = require('riot');

riot.tag2('module-view-board', '<div class="f fclm s-full"> <div class="px16 mb10" data-is="module-note-filter" ref="filter" users="{projectUsers}"></div> <div class="s-full f overflow-x-scroll px16 pb16" ref="columns_wrapper"> <div class="flex-fixed w270 h-full bg-white rounded-8 mr16" each="{column in getColumns()}" data-column-id="{column.id}"> <div class="f flex-column s-full" data-is="item-column" item="{column}" columns="{getColumns()}" note-query="{parent.refs.filter.getQuery()}" current-note="{parent.currentNote}"></div> </div> <div class="h-fit filtered flex-fixed w270 overflow-hidden mr16"> <div class="bg-white cursor-pointer rounded-8" onclick="{onStartEdit}"> <div class="f fbw p16 hover-trigger hover-text-primary" show="{!isEditingNewColumn}"><span class="font-lato fs13 bold mr2">カラムを追加する</span><i class="icon-add fs15"></i></div> <form class="p8" onsubmit="{onAddColumn}" show="{isEditingNewColumn}" onfocusout="{onCancel}"> <input class="input w-full bg-pale mb8" ref="project_name" oninput="{_.noop}" placeholder="カラム名を入力"> <div class="f fm fr"> <button class="button dark_light h32 f fh mr8" type="button" onclick="{onCancel}">キャンセル</button> <button class="button h32 f fh {hasValue() ? &quot;primary_fill&quot; : &quot;disabled_fill&quot;}">決定</button> </div> </form> </div> </div> </div> </div>', 'module-view-board .h-fit,[data-is="module-view-board"] .h-fit{height:fit-content}', '', function(opts) {
    this.on('mount', () => {
      if (spat.isNode) return ;

      if (!app.useragent.isMobile) {

        Sortable.create(this.refs.columns_wrapper, {
          animation: 150,
          filter: '.filtered',
          onEnd: async (e) => {

            var target = this.getColumns().find(item => item.id === e.item.dataset.columnId);
            await target.update({sort_num: Number(e.newIndex)})
            this.update();
          }
        });
      }

      this.refs.filter.on('filterchange', () => {

        this.update();
      });

      this.isEditingNewColumn = false;
    });

    this.on('unmount', () => {

      if (this.columnsStore) {
        this.columnsStore.unwatch();
        this.columnsStore.removeAllListeners();
        delete this.columnsStore;
      }
    });

    this.saveCurrentURL = () => {
      this.currentURL = location.href;
    };

    this.setup = async ({ note_id }, { req }) => {

      app.shortcut.setView('board', { viewTag: this });
      var project = app.store.project;

      if (note_id) {

        if (req.isBack) {
          spat.router.replaceState(`/${project.path}?view=board`);
        }
        else {
          var note = project.collection('notes').doc(note_id);
          this.saveCurrentURL();
          this.openNoteModal(note);
        }
      }

      if (this.project === project) {
        this.setupNoteModalPaging();
        return ;
      }

      this.project = app.store.project;
      this.update();

      this.columnsStore = this.project.collection('columns');
      this.columnsStore.on('snapshot', async () => {
        app.utils.debouncedUpdate();
      });
      await this.columnsStore.watch();

      this.projectUsers = await this.project.fetchProjectUsers();
      this.update();
      this.setupNoteModalPaging();
    };

    this.openNoteModal = async (note) => {
      if (this.modal) {
        this.modal.setNote(note);
        return ;
      }

      var modal = spat.modal.open('modal-note', {
        note: note,
      });
      this.modal = modal;
      await modal.waitClose();
      this.modal = null;

      app.api.child(note.data.column_ref.path).put({});

      if (this.currentURL === location.href) {
        spat.router.back();
      }

      this.update({
        currentNote: modal.note,
      });
    };

    this.closeNoteModal = () => {
      if (this.modal) {
        this.modal.close();
        this.modal = null;
      }
    };

    this.openCurrentNote = () => {
      if (this.currentNote) {
        this.openNoteModal(this.currentNote);
      }
    };

    this.setupNoteModalPaging = async () => {
      const {modal} = this;
      if (!modal) return ;
      if (modal.opts.showArrowButton) return ;
      await modal.note.fetch();

      const columnTag = this.getColumnTag(modal.note.data.column_ref.id);
      if (!columnTag) return ;

      if (!columnTag.notes || !columnTag.notes.length) {
        await columnTag.fetchNotes();
      }

      let notes = columnTag.getFilteredNotes();

      modal.opts.showArrowButton = true;
      modal.update();

      let indicator;
      let fetchMoreNotesPromise;

      const _fetchMoreNotes = async () => {
        const last_length = columnTag.notes.length;
        if (!indicator) indicator = spat.modal.indicator();
        await columnTag.fetchMoreNotes();

        if (last_length === columnTag.notes.length) return false;

        notes.push(...columnTag.getFilteredNotes());

        notes = app.utils.unique(notes, note => note.id);
        return true;
      };

      const fetchMoreNotes = async () => {
        if (!fetchMoreNotesPromise) {
          fetchMoreNotesPromise = _fetchMoreNotes();
        }
        const result = await fetchMoreNotesPromise;
        fetchMoreNotesPromise = null;
        return result;
      };

      modal.on('pagination', async ({ offset }) => {
        let note;

        while (true) {
          const index = notes.findIndex(note => note.id === modal.note.id);

          if (index === -1) {

            const isFetched = await fetchMoreNotes();

            if (isFetched) continue;
            else break;
          }

          note = notes[index + offset];

          if (note) {
            modal.setNote(note);

            spat.router.replaceState(`/${note.path}?view=board`);
            this.saveCurrentURL();
            break;
          }

          else if (offset === -1) {
            break;
          }

          else if (offset === 1) {

            const isFetched = await fetchMoreNotes();

            if (!isFetched) break;
          }
        }

        if (indicator) {
          indicator.close();
          indicator = null;
        }

        if (!note) {
          const notePosition = (offset === -1) ? '最初' : '最後';
          spat.toast.message(`${notePosition}のノートです。`);
        }
      });
    };

    this.getColumns = () => {
      return [...this.columnsStore.items].sort((a, b) => {
        return a.data.sort_num - b.data.sort_num;
      });
    };

    this.onAddColumn = async (e) => {
      e.preventDefault();
      var value = this.refs.project_name.value.trim();
      if (!value) return;
      await this.project.createColumn(value);
      this.refs.project_name.value = '';
      this.isEditingNewColumn = false;

      app.utils.openToast('add');
      this.update();
    };

    this.onStartEdit = () => {
      this.isEditingNewColumn = true;
      this.update();
      this.refs.project_name.focus();
    };

    this.onCancel = (e) => {
      e.stopPropagation();
      this.isEditingNewColumn = false;
      this.update();
    };

    this.hasValue = () => {
      return !!this.refs.project_name.value.trim();
    };

    this.focusNoteFilter = () => {
      this.refs.filter.focus();
    };

    this.clickAssignedCheckbox = () => {
      this.refs.filter.clickAssignedCheckbox();
    };

    this.getColumnTag = (column_id) => {
      return [].concat(this.tags['item-column'] || []).find(tag => tag.opts.item.id === column_id);
    };
});