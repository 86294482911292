const riot = require('riot');

riot.tag2('popup-status', '<div class="relative w140 box-shadow-primary bg-white rounded-8 p8 z10" ref="popup"> <div class="fs10 lh13 mb6">カラムを変更</div> <div data-is="module-columns" ref="moduleColumns" columns="{columns}" selected-columns="{current.id}"></div> </div>', '', '', function(opts) {
    this.on('mount', async () => {
      var project = this.opts.note.getProject();
      this.columns = await project.fetchColumns();
      this.current = await this.opts.note.fetchColumn();
      this.update();

      this.refs.moduleColumns.on('select', ({ column }) => {
        this.current = column;
        this.update();
        this.changeColumn();
      });

      this.refs.moduleColumns.focusSelected();
    });

    this.changeColumn = async () => {
      if (!this.opts.disableAutoChange && this.opts.note.data.column_ref.id !== this.current.id) {
        await this.opts.note.changeColumn({ column_id: this.current.id });
        await this.opts.note.refresh({relation: true});
        riot.update();
      }
      this.value = this.current;
      this.close();
    };
});