const riot = require('riot');

riot.tag2('modal-email', '<div class="modal bg-white w472 s-w-full p16 rounded-8" ref="modal"> <div class="text-background_dark_primary fs16 lh15 bold mb16">メールアドレスを変更</div> <p class="fs12 mb32">新しく設定するメールアドレスと現在のパスワードを入力してください</p> <form onsubmit="{onSubmit}"> <label class="block lh15 mb12"> <div class="fs10 lh13 mb6">メールアドレス</div> <input class="input large light_secondary w-full text-label_dark_medium" ref="email" required type="email"> </label> <label class="block lh15 mb32"> <div class="fs10 lh13 mb6">現在のパスワード</div> <input class="input large light_secondary w-full text-label_dark_medium" ref="password" type="password" required> </label> <div class="text-center"> <button class="button primary hover-pale w220">更新</button> </div> </form> </div>', 'modal-email,[data-is="modal-email"]{background-color:rgba(0,0,0,0.5)}', 'class="f fh s-px8" spat-animation="push"', function(opts) {
    this.onSubmit = async (e) => {
      e.preventDefault();

      await app.auth.signInWithEmailAndPassword(app.auth.currentUser.email, this.refs.password.value);

      await app.auth.updateEmail(this.refs.email.value);

      await spat.modal.alert('メールアドレスを更新しました');

      this.close();
    };
});