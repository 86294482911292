const riot = require('riot');

riot.tag2('page-search', '<div class="h-full f fclm"> <div data-is="module-header" title="検索" back="{true}"></div> <div class="s-full rounded-12 overflow-scroll" ref="body"> <div class="f fclm container"> <div class="p8"> <div class="flex-fixed" data-is="module-note-filter" ref="filter" users="{users}" status-filters="{statusFilters}" custom-filters="{customFilters}" enable-project="{true}"></div> </div> <div class="py16"> <div each="{note in notes}"> <div class="overflow-hidden rounded-8 cursor-pointer mb8" data-is="item-note" item="{note}" onclick="{onOpenNoteModal}" show-project="{true}"></div> </div> </div> </div> </div> <div class="flex-fixed w-full s-show" if="{!app.utils.hasInputFocus()}" data-is="module-tabbar"></div> </div>', 'page-search,[data-is="page-search"]{display:block}', '', function(opts) {
    this.customFilters = [
      {
        id: 'column',
        label: 'カラム',
        tooltip: 'カラム名で絞り込み',
        action: (e) => this.customFilterColumnAction(e),
        actionArrowDown: (e) => this.customFilterColumnAction(e),
        toggle: ['ignore_column'],
      },
      {
        id: 'ignore_column',
        label: 'カラム除外',
        tooltip: '検索から除外するカラムを指定',
        action: (e) => this.customFilterIgnoreColumnAction(e),
        actionArrowDown: (e) => this.customFilterIgnoreColumnAction(e),
        toggle: ['column'],
      }
    ];

    this.statusFilters = [
      {id: 'favorite', label: 'お気に入り'},
      {id: 'unassigned', label: '未アサイン', toggle: ['assigned']},
    ];

    this.preload = ({req, res}) => {
      return {
        workspace_id: req.params.workspace_id,
      };
    };

    this.on('mount', () => {
      this.refs.filter.on('filterchange', () => {
        this.debouncedSearch();
      });
    });

    this.on('show', async ({req, res}) => {
      if (app.useragent.isDesktop){
        this.refs.filter.focus();
      }
      this._workspaceAllColumns = null;
      await app.store.setup(req.params);

      var workspaces_users = await app.store.workspace.fetchWorkspaceUsers();
      this.users = workspaces_users.map(tu => tu.relation.user);

      this.update();

      if (req.query.q) {
        this.refs.filter.setQueryValue(decodeURIComponent(req.query.q));
      }
    });

    this.getWorkspaceAllColumns = async () => {
      if (this._workspaceAllColumns) return this._workspaceAllColumns;
      const projects_users = app.store.projectsUsersStore.items.filter(item => !item.data.archived);
      const columns_list = await Promise.all(projects_users.map(async pu => {
        if (!pu.data) await pu.fetch();
        return app.store.doc(pu.data.project_ref.path).fetchColumns();
      }));
      this._workspaceAllColumns = columns_list.flat();
      return this._workspaceAllColumns;
    };

    this.getProjectColumns = async () => {
      const project_id = this.refs.filter.getQuery().options.project_id;
      const all_columns = await this.getWorkspaceAllColumns();
      const project_columns = project_id ? all_columns.filter(c => c.getProject().id === project_id) : all_columns;
      return project_columns;
    };

    this.getUniqueProjectColumns = async () => {
      const project_columns = await this.getProjectColumns();
      const unique_columns = app.utils.unique(project_columns, c => c.data.name);
      return unique_columns;
    };

    this.customFilterColumnAction = async (e) => {
      const { value, nativeEvent: event } = e;
      const target = event.currentTarget;

      return this.openPopupColumnFilter({
        title: 'カラム名で絞り込み',
        value,
        target,
      });
    };

    this.customFilterIgnoreColumnAction = (e) => {
      const { value, nativeEvent: event } = e;
      const target = event.currentTarget;

      return this.openPopupColumnFilter({
        title: '除外カラムを指定',
        value,
        target,
      });
    };

    this.openPopupColumnFilter = async ({ title, value, target } = {}) => {

      const names = (value || '').split(',').map(v => v.trim()).filter(v => v);
      const columns = await this.getUniqueProjectColumns();
      const selected = names.map(name => columns.find(c => c.data.name === name)).filter(c => c);

      const result = await spat.popup.open('popup-column-filter', {
        element: target,
        closeTarget: target,
        offsetTop: 8,
        opts: {
          title,
          columns: columns,
          selectedColumns: selected,
        },
      }).waitClose();
      return result.map(c => c.data.name).join(',');
    };

    this.search = async () => {
      var query = this.refs.filter.getQuery();

      const param = {
        keyword: query.text,
        created_user_id: query.options.created_id,
        updated_user_id: query.options.updated_id,
        assigned_user_id: query.options.assigned_id,
        favorite: query.options.favorite,
        is_unassigned: query.options.unassigned,
        project_id: query.options.project_id,
        limit: 64,
      };

      if (query.options.column) {
        const columns = await this.getProjectColumns();
        const column_name_set = new Set(query.options.column.split(',').map(c => c.trim()).filter(c => c));

        param.column_ids = columns.filter(c => column_name_set.has(c.data.name)).map(c => c.id).join(',');
      }

      if (query.options.ignore_column) {
        const columns = await this.getProjectColumns();
        const column_name_set = new Set(query.options.ignore_column.split(',').map(c => c.trim()).filter(c => c));

        param.ignore_column_ids = columns.filter(c => column_name_set.has(c.data.name)).map(c => c.id).join(',');
      }

      this.update({
        notes: [],
      });

      var {note_refs} = await app.api.child('workspaces').child(this.workspace_id).child('projects/search_note').post(param);

      var promises = note_refs.map((path) => {
        return app.store.doc(path).fetch();
      });

      this.notes = await Promise.all(promises);
      this.update();

      var qv = this.refs.filter.getQueryValue().trim();
      if (qv) {
        history.replaceState(null, null, `${location.pathname}?q=${encodeURIComponent(qv)}`);
      }
      else {
        history.replaceState(null, null, `${location.pathname}`);
      }
    };

    this.onOpenNoteModal = (e) => {
      e.preventDefault();
      e.stopPropagation();

      spat.modal.open('modal-note', {
        note: e.item.note,
      });
    };

    this.debouncedSearch = _.debounce(() => {
      this.search();
    }, 256);
});