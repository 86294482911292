const riot = require('riot');

riot.tag2('page-auth', '<div class="f fh fclm s-full s-px16"> <h1 class="lh15 text-center text-background_dark_primary font-lato fs24 mb32">alog</h1> <div class="text-center mb32" if="{invite}"> <div>ワークスペース『{invite.workspace.name}』に参加する</div> </div> <div class="f fh w-full max-width-376 bg-white rounded-8 animation-appear"> <div class="f fh fclm w280 py32"> <button class="button primary f fh h40 w-full transition mb24" onclick="{onSignUpWithProvider}"><img class="s24 object-fit-contain mr16" src="/images/sns/google_color.svg"> <div class="fs13 bold text-primary pt2">Googleで{labels.title}</div> </button> <div class="f fm w-full mb24"> <div class="h1 w-full bg-separator_opaque"></div> <div class="lh13 bold word-break-keep fs12 mx16">メールアドレスで{labels.title}</div> <div class="h1 w-full bg-separator_opaque"></div> </div> <form class="w-full fs13 mb24" onsubmit="{onSubmit}"> <input class="input large w-full focus-pale bg-background_light_secondary mb16" ref="email" placeholder="メールアドレスを入力" required type="email"> <input class="input large w-full focus-pale bg-background_light_secondary mb16" ref="password" type="password" placeholder="パスワードを入力" required> <button class="button primary w-full h40 transition"> <div class="bold text-primary">{labels.button}</div> </button> </form> <div class="fs11 lh15 text-primary text-decoration-underline cursor-pointer mb24" if="{auth_mode === \'signin\'}" onclick="{onPasswordReset}">パスワードを忘れた場合</div><a class="lh13 text-primary bold cursor-pointer" href="{labels.other.link}">{labels.other.label}</a> </div> </div> </div>', 'page-auth,[data-is="page-auth"]{display:block}', '', function(opts) {
    this.preload = ({req, res}) => {
      var auth_mode = req.params.auth_mode;

      return {
        auth_mode,
        invite_id: req.query.invite,
      };
    };

    this.on('show', async () => {
      if (spat.config.env !== 'production') {
        this.refs.email.value = 'development@rabee.jp';
        this.refs.password.value = 'hogehoge';
      }

      if (this.auth_mode === 'signup') {
        this.labels = {
          title: '登録',
          button: '登録',
          other: {
            label: 'ログインはこちら',
            link: '/signin' + location.search,
          },
        };
      }
      else {
        this.labels = {
          title: 'ログイン',
          button: 'ログイン',
          other: {
            label: '新規登録はこちら',
            link: '/signup' + location.search,
          },
        };
      }

      if (this.invite_id) {
        var {invite} = await app.api.child('invites').child(this.invite_id).get();
        this.refs.email.value = invite.email;
        this.update({invite});
      }
    });

    this.onSubmit = async (e) => {
      e.preventDefault();

      var i = spat.modal.indicator();

      try {
        var invite_id = this.invite_id;

        if (this.auth_mode === 'signup') {
          await app.auth.createUserWithEmailAndPassword(this.refs.email.value, this.refs.password.value);
        }
        else {
          await app.auth.signInWithEmailAndPassword(this.refs.email.value, this.refs.password.value);
        }
      }
      finally {
        i.close();
      }
    };

    this.onSignUpWithProvider = async (e) => {
      if (capacitor.isAvailable) {
        await capacitor.signInGoogleAuth();
        await spat.modal.alert('ログインしました!')
      }
      else {
        var provider_id = 'google';

        const Provider = {
          'twitter': firebase.auth.TwitterAuthProvider,
          'facebook': firebase.auth.FacebookAuthProvider,
          'google': firebase.auth.GoogleAuthProvider,
        }[provider_id];
        var provider = new Provider();

        app.auth.signInWithRedirect(provider);
      }
    };

    this.onPasswordReset = () => {
      spat.router.push("/password-reset");
    };
});