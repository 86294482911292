const riot = require('riot');

riot.tag2('module-emoji-mini', '<div class="f fh s30 rounded-4 fs18 hover-trigger hover-bg-background_light_primary cursor-pointer mx2 my3" each="{reaction in reactions}" onmouseenter="{onShowTooltipReactions}" onclick="{onSelect}"> <div if="{reaction.type === \'emoji\'}" title="{reaction.name}">{reaction.value}</div><img class="p4 object-fit-contain" if="{reaction.type === \'image\'}" riot-src="{reaction.value}" alt="{reaction.name}"> </div>', '', '', function(opts) {
    this.on('mount', (reaction) => {
      if (spat.isBrowser) {
        this.reactions = this.getReactions();
      }
    });

    this.getReactions = () => {

      const keys = ["man-gesturing-ok", "woman-gesturing-ok", "+1", "white_check_mark", "rocket"];

      const last_emoji = JSON.parse(localStorage.getItem("emoji-mart.last"));

      if (last_emoji && !keys.includes(last_emoji)) {
        keys.push(last_emoji);
      }

      return app.emoji._map.filter(item => keys.includes(item.id));
    };

    this.onSelect = (e) => {
      let emoji = e.item.reaction;
      this.trigger('selected', emoji);
    };

    this.onShowTooltipReactions = async (e) => {
      if (!e.item.reaction) return ;

      const tooltip = spat.tooltip.open('tooltip-reaction-users', {
        element: e.target,
        offsetLeft: 0,
        offsetTop: -8,
        position: "top",
        opts: {
          reactionKey: e.item.reaction.name,
          limit: 6,
        },
      });
    };
});