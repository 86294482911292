const riot = require('riot');

riot.tag2('module-header', '<div class="relative h48"> <div class="absolute trbl0 f fh" if="{opts.project || opts.title}"> <div class="f fm z1"><a class="fs13 lh15 bold text-label_dark_primary mr4" href="/{opts.project.path}?view=toc">{opts.project ? opts.project.data.name : opts.title}</a> <div class="f fh cursor-pointer p4 tooltip" if="{opts.project &amp;&amp; app.store.workspace_user.data.role !== \'guest\'}" onclick="{openActionSheet}" aria-label="編集"><i class="icon-more_horizontal fs16 text-label_dark_medium"></i></div> </div> </div> <div class="relative f fbw fm h-full px16"> <div> <div class="f p4 bg-pale rounded-8" if="{opts.project}"> <a class="f fh flex-fixed px12 py4 rounded-4 hover-trigger hover-bg-white mr2 mr0-last tooltip s-p6 {opts.view === item.id ? \'bg-label_white_medium text-primary\' : \'text-label_dark_medium\'}" each="{item in app.data.header.views}" href="/{opts.project.path}?view={item.link}" onclick="{onChangeView}" aria-label="{item.tooltip}"><i class="fs16 hover-text-primary s-fs12 icon-{item.icon}"></i></a> </div> <div class="f fm cursor-pointer" if="{!opts.project}" onclick="history.back()"><i class="icon-arrow-left s16"></i> <div class="fs11 lh15 bold">戻る</div> </div> </div> <div class="f fm"><a class="mr8" href="/faq">ヘルプ</a> <div class="mr8 cursor-pointer tooltip" each="{item in app.data.header.menus}" onclick="{onSelect}" aria-label="{item.tooltip}" data-tooltip-offset-left="{item.offset_left}"><i class="fs20 text-label_dark_medium icon-{item.icon}" if="{item.id !== \'settings\'}"></i><a if="{item.id === \'settings\'}" href="/{item.id}"> <img class="block object-fit-cover flex-fixed s20 circle" riot-src="{app.utils.getImageUrl(app.store.currentUser.data.icon_image)}"></a></div> </div> </div> </div>', 'module-header,[data-is="module-header"]{display:block}', '', function(opts) {

    this.isFavorite = false;

    this.on('mount', () => {
      this.current = opts.view;
    });

    this.onSelect = (e) => {
      var item = e.item.item;

      if (item.type === 'link') {
        spat.router.push(`/${item.id}`)
      }
      else if (item.type === 'modal') {
        spat.modal.open(`modal-${item.id}`)
      }
      else if (item.type === 'function') {
        spat.router.push(item.onclick());
      }
    };

    this.onToggleFavorite = () => {
      this.isFavorite = !this.isFavorite;
    };

    this.openActionSheet = (e) => {

      var options = [
        { label: 'プロジェクトを編集', icon: 'edit', action: () => { popup.close(); this.onEdit(); } },
      ];

      if (this.opts.project.data.archived) {
        options.push({ label: 'アーカイブを解除', icon: 'archive', action: () => { popup.close(); this.unarchive(); } })
      }

      else {
        options.push({ label: 'アーカイブする', icon: 'archive', action: () => { popup.close(); this.archive(); } })
      }

      const popup = spat.popup.open('popup-action-sheet', {
        element: e.currentTarget,
        closeTarget: e.currentTarget,
        offsetTop: 4,
        position: 'bottom',
        opts: {
          options: options,
        },
      });
    };

    this.onEdit = async () => {
      var modal = spat.modal.open('modal-project', {project: this.opts.project});
      var result = await modal.waitClose();
      if (!result) return ;
      await this.opts.project.refresh();
      this.update();
    };

    this.archive = async () => {
      var result = await spat.modal.confirm('このプロジェクトをアーカイブしますか?');
      if (!result) return;
      await this.opts.project.archive();
      app.utils.openToast('archiveProject');
    };

    this.unarchive = async () => {
      var result = await spat.modal.confirm('このプロジェクトのアーカイブを解除しますか?');
      if (!result) return;
      await this.opts.project.unarchive();
      app.utils.openToast('releaseProject');
    };

    this.onChangeView = (e) => {
      e.preventDefault();
      spat.router.replace(e.currentTarget.href);
    };
});