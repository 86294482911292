const riot = require('riot');

riot.tag2('popup-templates', '<div class="w250 py8 px4" ref="popup"> <div class="f fm flex-between"> <div class="bold fs13 mb8">{app.store.project.data.name}のテンプレート一覧</div> </div> <div class="block outline-none" ref="target" onkeydown="{selectionList.onKeyDown}" tabindex="0"> <div class="overflow-y-scroll scrollbar-none max-height-168"> <div each="{template, index in opts.templates}" data-is="item-selection-list" item="{template}" onclick="{setValue}" selected-class="bg-pale"> <div class="f fm w-full py4 text-primary cursor-pointer px2"><i class="icon-template mr4"></i> <div class="w-full flex-fixed lh15 bold fs12 line-clamp-1 word-break-all mr5">{template.data.title || ⁗Untitled⁗}</div> </div> </div> </div> </div> </div>', '', 'class="bg-white box-shadow-primary rounded-8"', function(opts) {

    this.mixin('selectionList');
    this.on('mount', async () => {
      requestAnimationFrame(() => {
        this.refs.target.focus();
      });
    });

    this.setValue = () => {
      this.value = this.opts.templates[this.selectionList.selectedIndex];
      this.close();
    };

    this.on('selectionList.keydown.enter', () => {
      this.setValue();
    });
});