const riot = require('riot');

riot.tag2('module-columns', '<div class="overflow-y-scroll max-height-70vh"> <form onsubmit="{submit}" onkeydown="{onKeydown}"> <button class="w-full py2 hover-trigger hover-bg-pale hover-text-label_dark_primary rounded-4 cursor-pointer mb4 mb0-last" each="{column, index in opts.columns}" tabindex="{index + 1}" onclick="{onChange}" data-column-index="{index}"> <div class="f fm fbw pl6"> <div class="f fm"> <div class="s8 circle mr4 flex-fixed" riot-style="background-color: hsl({app.utils.stringToColorAngle( column.data.name )}, 60%, 60%);"></div> <div class="font-lato lh13 fs12 line-clamp-1 word-break-all">{column.data.name}</div> </div> <div class="s16 flex-fixed"><i class="icon-check fs16 text-primary" if="{isSelected(column.id)}"></i></div> </div> </button> </form> </div>', '', '', function(opts) {
    this.selectedIds = new Set();

    this.focusSelected = () => {
      if (!this.opts.columns) return;

      let index = this.opts.columns.slice().reverse().findIndex(column => this.isSelected(column.id));

      if (index === -1) index = this.opts.columns.length - 1;

      const focusTarget = this.root.querySelector(`[data-column-index="${this.opts.columns.length - index - 1}"]`);
      requestAnimationFrame(() => {
        focusTarget.focus();
      });
    };

    this.on('update', () => {
      const selected = [].concat(this.opts.selectedColumns || []);
      this.selectedIds = new Set(selected);
    });

    this.onKeydown = (e) => {
      e.preventUpdate = true;

      if ([13, 32].includes(e.keyCode)) {
        document.activeElement.click();
        e.preventDefault();
      }

      else if (e.keyCode === 38) {
        e.preventDefault();
        document.activeElement.previousElementSibling && document.activeElement.previousElementSibling.focus();
      }

      else if (e.keyCode === 40) {
        e.preventDefault();
        document.activeElement.nextElementSibling && document.activeElement.nextElementSibling.focus();
      }
    };

    this.onChange = (e) => {
      this.selectColumn(e.item.column);
    };

    this.submit = (e) => {
      e.preventDefault();
      this.trigger('submit');
    };

    this.selectColumn = (column) => {
      this.trigger('select', { column });
    };

    this.isSelected = (id) => {
      return this.selectedIds.has(id);
    };
});