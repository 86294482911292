const riot = require('riot');

riot.tag2('module-activities', '<div class="s-full pb8"> <div class="pb16"> <div class="mb16 mb0-last" each="{item in sclazy.items}" data-is="item-activity" item="{item}"></div> </div> <div class="f fh s-full" if="{sclazy.isLoading}"> <div data-is="atom-loading"></div> </div> </div>', 'module-activities,[data-is="module-activities"]{display:block}', '', function(opts) {
    this.on('mount', () => {

      this.lastItem = null;

      this.sclazy = Sclazy({
        target: this.parent.refs.body,
        onload: async () => {
          var limit = 32;
          var items = [];

          this.sclazy.isLoading = true;
          this.update();

          items.push(...await this.fetchActivities({limit, lastItem: this.lastItem}));

          this.sclazy.addItems(items);

          this.lastItem = items[items.length-1];

          var has_more = items.length === limit;
          this.sclazy.next(has_more);

          this.sclazy.isLoading = false;
          this.update();
        },

        onscrollend: () => {
          this.sclazy.load();
        }
      });

      this.update();
    });

    this.fetchActivities = ({limit, lastItem}) => {
      var activities_store = app.store.workspace_user.collection('activities')
        .orderBy('created_at', 'desc')
        .limit(limit);

      if(lastItem) {
        activities_store._ref = activities_store._ref.startAfter(lastItem.doc);
      }

      return activities_store.fetch({relation: true});
    };

    this.reload = () => {
      this.lastItem = null;
      this.sclazy.reset().load();
    };
});