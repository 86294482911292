const riot = require('riot');

riot.tag2('popup-toc', '<div class="w287 h310 bg-white rounded-8 box-shadow-primary overflow-scroll" ref="popup"> <div class="px8 pb16"> <div class="sticky t0 l0 z1 pt12 pb8 bg-white"> <div class="lh13 text-label_dark bold">目次</div> </div> <div class="f" each="{item, index in sections}"> <div class="f fclm"> <div class="relative"> <div class="circle s14 flex-fixed {current_index === index ? \'bg-pale\' : \'\'}"></div> <div class="absolute t3 l3 s8 circle bg-primary"></div> </div> <div class="w2 h35 bg-pale ml6 myn3" if="{index !== sections.length - 1}"></div> </div> <div class="pl8 mtn4 hover-trigger w-full"> <div class="w-full lh15 line-clamp-2 cursor-pointer hover-text-label_dark_primary {current_index === index ? \'text-label_dark_primary\' : \'text-label_dark_medium\'}" onclick="{onScrollToElement}">{trimText(item.textContent)}</div> </div> </div> </div> </div>', '', '', function(opts) {

    this.setCurrentIndex = (index) => {
      this.current_index = index;
      this.update();
    };

    this.trimText = (text) => {
      let result = text.replace(/^#+\s/, '');
      return result;
    };

    this.onScrollToElement = (e) => {
      e.preventDefault();
      let position = app.utils.cumulativeOffset(e.item.item, this.opts.body);
      $(this.opts.body).animate({scrollTop: position}, 256);
      this.trigger('click');
    };

    this.setSections = (elements) => {
      this.sections = elements;
      this.update();
    };
});