const riot = require('riot');

riot.tag2('module-message-reactions', '<div class="f fm flex-wrap gap-4"> <div each="{reaction, key in opts.item.getReactions()}" onmouseenter="{onShowTooltipReactions}" ontouchstart="{onTouchStart}" ontouchend="{onTouchEnd}"> <div class="f fh px4 h22 rounded-4 border cursor-pointer {this.opts.item.isMyReaction(key) ? &quot;border-primary bg-background_light_primary&quot; : &quot;border-label_dark_medium&quot;}" onclick="{onToggleReaction}" role="button"> <div class="fs13 pointer-none mr2" if="{app.emoji.getEmojiObj(key).type === \'emoji\'}">{app.emoji.getEmoji(key)}</div><img class="flex-fixed s17 pointer-none mr2" if="{app.emoji.getEmojiObj(key).type === \'image\'}" riot-src="{app.emoji.getEmoji(key)}"> <div class="fs10 lh15 pointer-none">{reaction.length}</div> </div> </div> <div class="fs16 py4 px8 py2 s-fs20" data-is="atom-emoji" onselected="{onSelectReaction}" disabled-comment-popup="{opts.disabledCommentPopup}"></div> </div>', 'module-message-reactions,[data-is="module-message-reactions"]{display:block}', '', function(opts) {

    this.mixin('enableOptsEventHandler');

    this.addReaction = (key) => {
      return this.opts.item.addReaction(key, this.opts.verify);
    };

    this.onToggleReaction = async (e) => {
      var key = e.item.key;
      var [$target] = e.target.children;
      const submit_event = { removed: false };
      if (this.opts.item.isMyReaction(key)) {
        $target.classList.remove('animation-scale');
        await this.opts.item.removeReaction(key);
        submit_event.removed = true;
      }
      else {
        $target.classList.add('animation-scale');
        await this.addReaction(key)
      }

      var group_by_reactions = this.opts.item.getReactions();
      e.item.reaction = group_by_reactions[e.item.key]

      this.trigger('submit', submit_event);
      this.update();
    };

    this.onOpenModalReactions = () => {
      spat.modal.open('modal-reactions', { reactions: app.emoji })
      .on('selected', async (item) => {
        await this.addReaction(key);
        this.trigger('submit');
      });
    };

    this.onOpenPopupReactions = (e) => {
      spat.popup.open('popup-reactions', {
        element: e.currentTarget,
        closeTarget: e.currentTarget,
        opener: this.root,
        offsetLeft: 78,
        position: 'top',
        opts: {
          reactions: app.emoji,
        },
      })
    };

    this.onSelectReaction = async (reaction) => {
      await this.addReaction(reaction.name);
      this.trigger('submit');
    };

    this.onShowTooltipReactions = async (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (!e.item.reaction) return ;
      const tooltip = spat.tooltip.open('tooltip-reaction-users', {
        element: e.target,
        offsetLeft: 35,
        offsetTop: -2,
        position: "top",
        opts: {
          reactionKey: e.item.key,
          limit: 6,
          showUsers: true,
        },
      });

      var project_users = await this.opts.item.getNote().getProject().fetchProjectUsers();

      const users = project_users.filter(user => e.item.reaction.some(r => r.user_ref.id === user.id));

      tooltip.setUsers(users);
    };

    this.onLongPress = async (e) => {
      let key = e.item.key || '';
      capacitor.hapticsImpactLight();
      let reactions = await this.opts.item.getUsersByReactions();
      spat.modal.open('modal-reaction-users', { reactions, key });
    };

    this.longPressTimer = null;
    this.touchStarted = false;

    this.onTouchStart = (e) => {
      e.preventDefault();
      e.stopPropagation();

      this.touchStarted = true;
      capacitor.hapticsImpactLight();

      this.longPressTimer = setTimeout(() => {
        this.touchStarted = false;
        this.onLongPress(e);
      }, 256);
    };

    this.onTouchEnd = (e) => {

      clearTimeout(this.longPressTimer);

      if (this.touchStarted) {
        this.onToggleReaction(e);
      }

      this.touchStarted = false;
    };
});