const riot = require('riot');

riot.tag2('atom-switch', '<div class="switch s-full rounded-full cursor-pointer transition f fm {checked: checked} {&quot;checked-alert&quot; : opts.isAlert &amp;&amp; checked}" onclick="{onToggleChecked}"> <div class="inner-button flex-fixed ml2"></div> </div>', 'atom-switch,[data-is="atom-switch"]{display:block;width:35px;height:19px;cursor:pointer;-webkit-user-select:none;user-select:none} atom-switch .switch,[data-is="atom-switch"] .switch{background-color:rgba(29,40,76,0.2)} atom-switch .switch .inner-button,[data-is="atom-switch"] .switch .inner-button{box-shadow:0 1px 2px rgba(0,0,0,0.25)} atom-switch .switch.checked,[data-is="atom-switch"] .switch.checked{background-color:#4466EA} atom-switch .switch.checked .inner-button,[data-is="atom-switch"] .switch.checked .inner-button{transform:translateX(16px)} atom-switch .switch.checked-alert,[data-is="atom-switch"] .switch.checked-alert{background-color:#FF7551} atom-switch .switch.checked-alert .inner-button,[data-is="atom-switch"] .switch.checked-alert .inner-button{transform:translateX(16px)} atom-switch .inner-button,[data-is="atom-switch"] .inner-button{width:15px;height:15px;background-color:white;border-radius:50%;transition:256ms}', '', function(opts) {
    this.on('mount', () => {
      this.checked = !!this.opts.checked;
      this.update();
    });

    this.onToggleChecked = () => {
      this.checked = !this.checked;
      this.trigger('toggleChecked', {checked: this.checked});
    };

    this.setValue = (flag) => {
      this.checked = flag;
      this.update();
    };

    this.getValue = () => {
      return this.checked;
    };
});