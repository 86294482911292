const riot = require('riot');

riot.tag2('atom-button-invite', '<button class="button base_light_dashed relative f fh h48 w-full hover-trigger transition cursor-pointer" onclick="{onOpenModal}"> <div class="hover-text-primary fs13 lh15 mr8">{opts.text}を招待</div><i class="icon-help cursor-pointer fs16 text-label_dark_primary tooltip" aria-label="{opts.description}"></i><i class="icon-add absolute r16 t16 hover-text-primary fs16"></i> </button>', '', '', function(opts) {
    this.onOpenModal = () => {
      var modal = spat.modal.open('modal-workspace-invite', {
        workspace: this.opts.workspace,
        role: this.opts.role,
      });
      modal.on('submit', () => {
        this.trigger('submit');
      });
    };
});