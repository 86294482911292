const riot = require('riot');

riot.tag2('atom-link', '<i class="icon-copy text-label_dark_medium hover-trigger hover-text-label_dark_primary cursor-pointer" onclick="{onOpenPopup}"></i>', 'atom-link,[data-is="atom-link"]{display:block}', '', function(opts) {

    this.onOpenPopup = async (e) => {
      e.preventDefault();
      e.stopPropagation();
      const popup = spat.popup.open('popup-link', {
        element: this.root,
        closeTarget: e.currentTarget,
        offsetTop: -20,
        position: 'top',
      }).on('submit', (e) => {
        popup.close();
      });
      popup.focusInput();
      await popup.waitClose();
      if (popup.value) {
        this.trigger("submit", {value: popup.value});
      }
    };
});