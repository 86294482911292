const riot = require('riot');

riot.tag2('popup-link', '<div class="w300 bg-white rounded-8 box-shadow-primary" ref="popup"> <form class="font-lato p16" onsubmit="{submit}"> <label class="f fm"> <div class="bold fs12"></div> <input class="input small w-full {\'focus-pale light\' : ogp}" ref="link" placeholder="https://~" oninput="{oninput}"> </label> <label class="f py16" show="{ogp.title}"> <input class="s16 checkbox mr4" type="checkbox" ref="use_title" checked="{!!ogp.title}" onchange="{_.noop}"> <div class="fs12 bold lh13 word-break-all line-clamp-2">{ogp.title}</div> </label> <label class="f mb16" show="{ogp.image_url}"> <input class="s16 checkbox mr4" type="checkbox" ref="use_image" checked="{!!ogp.image_url}" onchange="{_.noop}"><img class="border border-pale object-fit-cover rounded-8 w90per" riot-src="{ogp.image_url}"> </label> <label class="f" show="{ogp.description}"> <input class="s16 checkbox mr4" type="checkbox" ref="use_description" checked="{!!ogp.description}" onchange="{_.noop}"> <div class="fs11 lh13 word-break-all line-clamp-3">{ogp.description}</div> </label> <div class="py10" if="{isLoading}" data-is="atom-loading"></div> <div class="f fm fr pt16" if="{ogp}"> <button class="primary_fill f fh flex-fixed s32 circle mr12 tooltip {\'disabled\' : isLoading}" if="{app.store.project}" onclick="{onCreateNote}" type="button" aria-label="記事のノートを作成" data-tooltip-position="top"><i class="fs20 icon-edit pb2 f fh"></i></button> <button class="primary_fill f fh flex-fixed s32 circle mr12 tooltip {\'disabled\' : isLoading}" onclick="{onCopyOGP}" type="button" aria-label="記事のの内容をコピー" data-tooltip-position="top"><i class="fs20 icon-template"></i></button> <button class="primary_fill f fh flex-fixed s32 circle tooltip {\'disabled\' : isLoading}" aria-label="記事をエディタに挿入" data-tooltip-position="top"><i class="fs20 icon-check"></i></button> </div> </form> </div>', '', '', function(opts) {
    this.on('mount', () => {

      this.clipboardTextInsert();
    });

    this.focusInput = () => {
      this.refs.link.focus();
    };

    this.fetchOGP = async (e) => {
      this.isLoading = true;
      this.update();
      var url = this.refs.link.value;

      if (!/^http(s)?:\/\/.+/.test(url)) {
        this.isLoading = false;
        this.update();
        return ;
      }

      var {ogp} = await app.api.child('utils/ogp').get({ url });
      this.update({ogp});
      this.isLoading = false;
      this.update();
    };
    this.oninput = _.debounce(this.fetchOGP, 512);

    this.submit = (e) => {
      e.preventDefault();
      if (!this.ogp) return;
      this.value = {
        text: this.getOGPContents(),
        ogp: this.ogp,
      };
      this.trigger('submit', {
        text: this.getOGPContents(),
        ogp: this.ogp,
      });
      this.update();
    };

    this.onCopyOGP = () => {
      var text = this.getOGPContents();
      app.utils.copyToClipBoard(text);
      app.utils.openToast('copy');
    };

    this.getOGPContents = () => {
      if (!this.ogp) return;
      var ogp = this.ogp;
      var texts = [];

      if (this.refs.use_title.checked) {
        texts.push(`[${ogp.title}](${ogp.url})`);
      }
      else {
        texts.push(this.refs.link.value);
      }

      if (this.refs.use_image.checked) {
        texts.push(`[![${ogp.title}](${ogp.image_url})](${ogp.url})`)
      }

      if (this.refs.use_description.checked) {
        texts.push(`${ogp.description}`);
      }
      return texts.join('\n\n');
    };

    this.clipboardTextInsert = async () => {

      if (app.useragent.isSafari) {
        return ;
      }
      try {
        var clipboardText = await navigator.clipboard.readText();

        if (/^https?:\/\//.test(clipboardText.trim())) {
          this.refs.link.value = clipboardText;
          this.fetchOGP();
        }
      }
      catch(e) {
        console.log('クリップボードの読み取りに失敗しました。');
      }
    };

    this.onCreateNote = async ({content = '', title = ''} = {}) => {
      if (!this.ogp || !app.store.project) return;

      const newWindow = open();
      try {
        var {note} = await app.api.child(app.store.project.path).child('notes').post({
          title: this.refs.use_title.checked ? this.ogp.title : "",
          content: "# " + this.getOGPContents(),
        });

        newWindow.location.href = `/${app.store.project.path}/notes/${note.id}`;
      }
      catch(e) {
        newWindow.close();
      }
    };
});