const riot = require('riot');

riot.tag2('popup-suggestion-projects', '<div class="w342 px8" ref="popup"> <div class="f fm flex-between"> <div class="bold fs13">プロジェクトを選択</div> <div class="p4 cursor-pointer" onclick="{close}"><i class="icon-close fs24 text-label_dark_medium"></i></div> </div> <form class="block mb8" onsubmit="{submit}" onkeydown="{selectionList.onKeyDown}"> <div class="relative input light_secondary f fm mb8"><i class="icon-search text-label_dark_disabled fs16 mr8"></i> <input class="input w-full" ref="search" placeholder="プロジェクトを検索する" oninput="{debounceSearch}"><i class="icon-close absolute t0 b0 r0 f fh h-full p6 fs16 cursor-pointer" onclick="{onDeleteValue}" if="{refs.search.value}"></i> </div> <div class="overflow-y-scroll scrollbar-none transition-128" riot-style="max-height: {projects ? 168 : 0}px"> <div each="{project in projects}" data-is="item-selection-list" item="{project}" onclick="{setValue}" selected-class="bg-pale"> <div class="p4 cursor-pointer lh15 fs11 line-clamp-1 word-break-all">{project.data.project_name}</div> </div> </div> </form> </div>', '', 'class="bg-white box-shadow-primary rounded-8"', function(opts) {

    this.mixin('selectionList');
    this.on('mount', async () => {
      this.search();
      setTimeout(() => {
        this.refs.search.focus();
      });
      this.update();
    });

    this.search = () => {
      const value = this.refs.search.value.trim();
      this.projects = app.utils.fuzzySearchItems({
        items: app.store.projectsUsersStore.items.filter(item => !item.data.archived),
        key_or_callback: 'data.project_name',
        value,
      });
      if (!value && !this.opts.disableAll) {
        this.projects.unshift({
          type: 'all',
          data: {
            project_name: '全て',
          }
        });
      }
      this.update();
    };

    this.debounceSearch = _.debounce(this.search, 64);

    this.submit = (e) => {
      e.preventDefault();
    };

    this.setValue = () => {
      this.value = this.getSelectedProject();
      this.close();
    };

    this.getSelectedProject = () => {
      const project = this.projects[this.selectionList.selectedIndex];
      if (!project || project.type === 'all') return project;
      return app.store.doc(project.data.project_ref.path);
    };

    this.onDeleteValue = () => {
      this.refs.search.value = '';
      this.refs.search.focus();
      this.search();
    };

    this.on('selectionList.keydown.enter', () => {
      this.setValue();
    });
});