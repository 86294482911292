const riot = require('riot');

riot.tag2('modal-action-sheet', '<div class="f fb s-full bg-label_dark_disabled" onclick="{startClosing}"> <div class="w-full rounded-top-12 box-shadow-primary bg-white pb16 pb-safe" ref="modal" onclick="event.stopPropagation()"> <div class="f fc overflow-y-scroll transition-128 gpu-enable" ref="body" riot-style="max-height: {isOpen ? refs[&quot;body&quot;].scrollHeight : 0}px" ontransitionend="{onCloseTransition}"> <div class="w-full py16"> <div class="f fm py14 px16 hover-trigger hover-bg-pale cursor-pointer {&quot;text-alert_primary&quot; : option.accent}" each="{option in opts.options}" onclick="{option.action}"><i class="fs22 mr12 icon-{option.icon}" if="{option.icon}"></i> <div class="font-lato fs16 lh15 word-break-keep">{option.label}</div> </div> </div> </div> </div> </div>', '', 'spat-animation=""', function(opts) {
    this.isOpen = false;

    this.on('mount', () => {
      setTimeout(() => {
        this.isOpen = true;
        this.update();
      });
    });

    this.startClosing = () => {
      this.isOpen = false;
      this.update();
    };

    this.onCloseTransition = () => {
      if (!this.isOpen) {
        this.close();
      }
    };
});