var Firerest = require('firerest');

const api = Firerest.create({
  api: spat.config.api.endpoint,
  debug: spat.config.env !== 'production',
});

api.on('prefetch', async (ref) => {
  var user = app.auth.currentUser;
  if (user) {
    var token = await user.getIdToken();
    ref.headers({
      Authorization: `Bearer ${token}`,
    });
  }
});

api.on('fail', function (req, res) {
  if (spat.isBrowser) {
    spat.modal.alert(res.message || `エラーが発生しました。\n${JSON.stringify(res)}`, 'Error');
  }
});

export default api;