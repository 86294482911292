const riot = require('riot');

riot.tag2('modal-password', '<div class="modal bg-white w472 s-w-full p16 rounded-8" ref="modal"> <div class="text-background_dark_primary fs16 lh15 bold mb16">パスワードを変更</div> <p class="fs12 mb32">現在のパスワードと新しいパスワードを入力してください。</p> <form onsubmit="{onSubmit}"> <label class="block lh15 mb12"> <div class="fs10 lh13 mb6">現在のパスワード</div> <input class="input large light_secondary w-full text-label_dark_medium" ref="current_password" type="password" required> </label> <label class="block lh15 mb12"> <div class="fs10 lh13 mb6">新しいパスワード</div> <input class="input large light_secondary w-full text-label_dark_medium" ref="new_password" type="password" required> </label> <label class="block lh15 mb32"> <div class="fs10 lh13 mb6">新しいパスワードを確認</div> <input class="input large light_secondary w-full text-label_dark_medium" ref="new_password_confirm" type="password" required> </label> <div class="text-center"> <button class="button primary hover-pale w220">更新</button> </div> </form> </div>', 'modal-password,[data-is="modal-password"]{background-color:rgba(0,0,0,0.5)}', 'class="f fh s-px8" spat-animation="push"', function(opts) {
    this.onSubmit = async (e) => {
      e.preventDefault();
      var indicator = spat.modal.indicator();

      try {
        await app.auth.signInWithEmailAndPassword(app.auth.currentUser.email, this.refs.current_password.value);

        var new_password = this.refs.new_password.value;

        var new_password_confirm = this.refs.new_password_confirm.value;

        if (new_password !== new_password_confirm) {
          spat.modal.alert('パスワードが一致しませんでした。\nもう一度入力し直してください。');
          return ;
        }
        await app.auth.updatePassword(new_password);
        app.utils.openToast('updatePassword');
        this.close();
      }
      finally {
        indicator.close();
      }
    };
});