import utils from './utils'
import auth from './auth'
import api from './api'
import store from './store'
import data from './data'
import shortcut from './shortcut'
import emoji from './emoji'
import webpush from './webpush'
import storage from './storage'
import EditorUtil from './editor_util';
import localStorageManager from './local_storage_manager';
import holidayJp from 'holiday-jp';

var app = {
  utils,
  auth,
  api,
  store,
  data,
  shortcut,
  emoji,
  webpush,
  storage,
  EditorUtil,
  localStorageManager,
  holidayJp,
};


export default app;
