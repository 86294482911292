const riot = require('riot');

riot.tag2('atom-note-filter', '<div class="f fm cursor-pointer" onclick="{onOpenPopup}"> <div class="fs10 lh15 mr4">絞り込み</div><i class="icon-arrow-down fs8 transition {&quot;rotate-180&quot; : isOpen}"></i> </div>', 'atom-note-filter,[data-is="atom-note-filter"]{display:block}', '', function(opts) {
    this.onOpenPopup = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.update({ isOpen: true });
      spat.popup.open('popup-note-filter', {
        element: this.root,
        closeTarget: e.currentTarget,
        offsetTop: 4,
        position: 'bottom',
        opts: {
          note: opts.note,
        },
      }).on('close', () => {
        this.update({ isOpen: false });
      });
    };
});