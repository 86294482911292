import client from '.spat/modules/client';
import app from './scripts/app';
import './scripts/riot_mixin'

var HyperMD = require('hypermd');
global.HyperMD = HyperMD;
global.CodeMirror = require('codemirror');

require("codemirror/mode/javascript/javascript");
require("codemirror/mode/yaml/yaml");
require("codemirror/mode/clike/clike");

import 'codemirror/addon/hint/show-hint.js';
import 'codemirror/addon/display/placeholder.js';

// client.showSSR();

app.useragent = useragent.parse(navigator.userAgent);
dayjs.locale('ja');

// setup firestore
app.store.init(firebase.firestore());
app.store.settings({
  merge: true,
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});

// setup firebase auth
app.auth.on('ready', async (user) => {
  if (user) {
    // 必ず sign in API を実行しておく(firestore 上にユーザーがない場合ここで作られる)
    var { user: _user } = await app.api.child('me/sign_in').post({});

    await app.store.watch();
    app.utils.openModalNameSettingIfNeeded(_user);
    // web push通知の許可を求める
    app.webpush.requestPermissionIfNeeded();
    
    capacitor.setupPushNotifications();
  }

  // ソフトウェアキーボードの監視
  app.utils.setupInputFocusChange();
  // キーボードショートカットのセットアップ
  app.shortcut.setup();
  client.start();

  // TODO: spat に実装されたら消す
  if (!spat.router.replaceState) {
    spat.router.replaceState = function(path) {
      history.replaceState({
        ...history.state,
        pageIndex: this.pageIndex,
      }, '', path);
    };
  }

  // popup のセットアップ
  const $popup = document.createElement('div');
  spat.appTag.root.appendChild($popup);
  const popupTag = riot.mount($popup, 'module-popup')[0];
  spat.appTag.popupTag = popupTag;
  spat.popup = popupTag;
  
  // tooltip のセットアップ
  const $tooltip = document.createElement('div');
  spat.appTag.root.appendChild($tooltip);
  const tooltipTag = riot.mount($tooltip, 'module-tooltip')[0];
  spat.appTag.tooltipTag = tooltipTag;
  spat.tooltip = tooltipTag;

  // ウェブのときしか呼ばない
  if (!capacitor.isAvailable && location.hostname !== 'localhost') {
    // PWA 対応
    if ('serviceWorker' in navigator) {
      // parcel のビルドでファイル検知しようとしてエラーが出るので + で結合
      navigator.serviceWorker.register('/sw.' + 'js');
    }
    // web 通知対応
    app.webpush.setup();
    app.webpush.setupClient();
  }

  // ローカルストレージにあるノートコメントの履歴をセットする
  app.utils.storage.noteComment.setup();
});

app.auth.on('signin', async (e) => {

  // invite_id があった場合は sign_in に渡す
  var invite_id = '';
  const url = new URL(location.href);
  if (url.searchParams.has('invite')) {
    invite_id = url.searchParams.get('invite');
  }

  // 必ず sign in API を実行しておく(ここで firestore 上にユーザーが作られる)
  var { user } = await app.api.child('me/sign_in').post({invite_id});

  // 監視をスタート
  await app.store.watch();
  app.utils.openModalNameSettingIfNeeded(user);
  // web push通知の許可を求める
  app.webpush.requestPermissionIfNeeded();

  // NATIVE: プッシュ通知のセットアップ
  capacitor.setupPushNotifications();

  // signin/signup ページだった場合はトップページにリダイレクトさせる
  if (['/signin', '/signup'].includes(location.pathname)) {
    spat.router.push('/');
  }
});

app.auth.on('signout', () => {
  app.store.unwatch();
  // ローカルストレージに保存している書きかけコメントの情報を削除する
  app.utils.storage.noteComment.deleteAll();
});

app.auth.init(firebase);


// for capacitor
capacitor.enableSwipeBack();