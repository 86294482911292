const riot = require('riot');

riot.tag2('atom-editor-toolbar', '<div class="f fm fs20"> <div class="text-label_dark_medium hover-trigger hover-text-label_dark_primary cursor-pointer mr12 mr0-last tooltip" each="{item in toolItems}" aria-label="{item.tooltip}" data-tooltip-position="top"><i class="icon-{item.icon}" onclick="{item.action}"></i></div> <div class="mr12" data-is="atom-emoji" onselected="{insertEmoji}" getpopupoptions="{getEmojiPopupOptions}"></div> <div class="tooltip" ref="file" data-is="atom-file" accept="image/*" aria-label="ファイル" data-tooltip-position="top"></div> </div>', '', '', function(opts) {
    this.on('mount', () => {

      this.refs.file.on('change', (target) => {
        this.editor.insertFile(target);
        this.editor.focus();
      });
    });

    this.toolItems = [
      { icon: 'mention', action: (e) => this.insertMentionSymbol(e), tooltip: 'メンション'},
      { icon: 'text_style', action: (e) => this.onOpenPopupToolList(e), tooltip: 'テキストスタイル'},
      { icon: 'tool', action: (e) => this.insertToolSymbol(e), tooltip: 'ツール'},

    ];

    this.styleItems = [
      { icon: 'h1', tooltip: 'h1', symbol: '#', type: 'line_head' },
      { icon: 'h2', tooltip: 'h2', symbol: '##', type: 'line_head' },
      { icon: 'bold', tooltip: 'ボールド', symbol: '**', type: 'enclosing' },
      { icon: 'italic', tooltip: 'イタリック', symbol: '*', type: 'enclosing', isHide: app.useragent?.isMobile },
      { icon: 'strikethrough', tooltip: '取り消し線', symbol: '~~', type: 'enclosing' },
      { icon: 'list', tooltip: 'リスト', symbol: '-', type: 'list' },
      { icon: 'number_list', tooltip: '段落番号', symbol: '1.', type: 'list' },
      { icon: 'checkbox', tooltip: 'チェックボックス', symbol: '- [ ]', type: 'list' },
      { icon: 'block', tooltip: 'コードブロック', symbol: '`', type: 'block' },
      { icon: 'clip', tooltip: 'リンク挿入', symbol: '[](https://~)', type: 'link' },
      { icon: 'table', tooltip: 'テーブル生成', symbol: '', type: 'table' },
    ].filter(item => !item.isHide);

    this.getEmojiPopupOptions = () => {
      return {
        element: this.emojiPopupTarget || this.root,
        offsetLeft: 0,
        offsetTop: -16,
        position: 'top',
      };
    };

    this.setEditor = (editor) => {
      this.editor = editor;
      this.update();
    };

    this.setEmojiPopupTarget = (target) => {
      this.emojiPopupTarget = target;
      this.update();
    };

    this.insertEmoji = async (emoji) => {
      if (emoji.type === 'image') {
        await spat.modal.alert('画像挿入は開発中のため使用できません。しばらくお待ちください。');
        return;
      }
      this.editor.insertTextAtCursor(emoji.value);
      this.editor.focus();
    };

    this.insertMentionSymbol = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.editor.insertTextAtCursor('@');
      this.editor.showHint();
      this.editor.focus();
    };

    this.insertToolSymbol = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.editor.insertTextAtCursor('/');
      this.editor.showHint();
      this.editor.focus();
    };

    this.onOpenPopupToolList = async (e) => {
      e.preventDefault();
      e.stopPropagation();
      const popup = spat.popup.open('popup-tool-list', {
        element: e.currentTarget,
        closeTarget: e.currentTarget,
        offsetTop: -10,
        offsetLeft: 160,
        position: 'top',
        opts: {
          items: this.styleItems,
        },
      }).on('selected', ({ symbol, type }) => {

        if (type === 'line_head' || type === 'list') {
          this.editor.util.replaceLineHead(symbol);
        }

        else if (type === 'enclosing'){
          this.editor.util.replaceEnclosing(symbol);
        }

        else if (type === 'block') {
          this.editor.util.replaceCodeBlock();
        }

        else if (type === 'table') {
          this.onOpenPopupCreateTable();
        }

        else {
          this.editor.util.replaceMarkdownLink(symbol);
        }
        popup.close();
      });
    };

    this.onOpenPopupCreateTable = async () => {
      const popup = spat.popup.open('popup-create-table', {
        element: this.root,
        offsetTop: -10,
        align: { x: 'center' },
        position: 'top',
      }).on('submit', ({ rows, cols }) => {
        this.editor.util.insertMarkdownTable(rows, cols);
        popup.close();
      });
    };
});