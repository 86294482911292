const riot = require('riot');

riot.tag2('module-workspaces', '<div class="h-full bg-black text-white overflow-hidden"> <div class="transition py32 {visible ? &quot;w72&quot; : &quot;w0&quot;}"> <div class="{&quot;bg-background_dark_primary border-left bw4 border-white pr4&quot; : item.data.workspace_id === app.store.workspace_id}" each="{item in app.store.workspacesUsersObserver.items}"><a class="f fh w-full py8" href="/workspaces/{item.data.workspace_id}/inboxes"> <div class="relative s32 tooltip" aria-label="{item.relation.workspace.data.name}" data-tooltip-position="right"><img class="s-full object-fit-cover rounded-8" riot-src="{app.utils.getImageUrl(item.relation.workspace.data.icon_image)}"> <div class="absolute tn6 rn10 f fh s20 circle bg-background_dark_primary" if="{item.hasUnreadMention()}"> <div class="fh f s16 circle bg-alert_primary"> <div class="pb2 text-white fs10 font-lato bold">@</div> </div> </div> <div class="absolute tn3 rn3 f fh s12 circle bg-background_dark_primary" if="{!item.hasUnreadMention() &amp;&amp; item.hasUnread()}"> <div class="fh f s8 circle bg-alert_primary"></div> </div> </div></a></div> <div class="f fh"> <button class="p16 tooltip" onclick="{onOpenWorkspaceCreateModal}" aria-label="ワークスペースを追加" data-tooltip-position="right" data-tooltip-offset-left="-10"><i class="fs24 icon-add"></i></button> </div> </div> </div>', 'module-workspaces,[data-is="module-workspaces"]{display:block}', '', function(opts) {
    const STORAGE_KEY = 'alog_show_project';

    this.dummyNotification = 'noread';

    this.visible = localStorage.getItem(STORAGE_KEY) === "true" || false;

    this.setVisible = (visible) => {
      this.update({
        visible,
      });
      this._syncStorage();
    };

    this.show = () => {
      this.setVisible(true);
    };

    this.hide = () => {
      this.setVisible(false);
    };

    this.toggle = () => {
      this.setVisible(!this.visible);
    };

    this.getVisible = () => {
      return this.visible;
    };

    this.onOpenWorkspaceCreateModal = () => {
      spat.modal.open('modal-workspace-create');
    };

    this._syncStorage = () => {
      localStorage.setItem(STORAGE_KEY, this.visible);
    };
});