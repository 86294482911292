const riot = require('riot');

riot.tag2('popup-notifications', '<div class="px8 pb8 {isFullscreen ? \'w90vw\' : \'w400\'}" ref="popup"> <div class="relative f fh bold fs13 py8 tooltip" ondblclick="{onToggleFullscreen}"><i class="mr4 pt2 icon-{label.icon}"></i> <div class="lh15">{label.title}</div><i class="absolute r8 icon-fullscreen fs16 p4 tooltip" onclick="{onToggleFullscreen}" aria-label="フルスクリーン" data-tooltip-position="top"></i> </div> <div class="block mb8"> <div class="overflow-y-scroll scrollbar-none transition {isFullscreen ? \'h90vh\' : \'h300\'}" ref="body"> <div class="s-full px4" data-is="module-{opts.current}" hide-tab="{true}"></div> </div> </div> </div>', '', 'class="box-shadow-primary rounded-8 bg-blur-8 bg-separator_opacity"', function(opts) {

    this.label = {
      reminds: {
        icon: "remind",
        title: "リマインド",
      },
      notifications: {
        icon: "notification",
        title: "通知",
      },
    }[this.opts.current];

    this.on('mount', async () => {
      setTimeout(() => {
        this.tags[`module-${this.opts.current}`].reload();
      });
      this.update();
    });

    this.onToggleFullscreen = () => {
      this.isFullscreen = !this.isFullscreen;
    };
});