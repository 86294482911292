const riot = require('riot');

riot.tag2('modal-workspace-create', '<div class="f fclm max-width-472 w-full px16 pt19 pb24 bg-white rounded-8" ref="modal"> <div class="f fbw mb18"> <div class="text-background_dark_primary fs16 lh15 bold">ワークスペースを作成</div><i class="icon-close fs24 text-label_dark_medium cursor-pointer" onclick="{close}"></i> </div> <form class="h-full" onsubmit="{onSubmit}"> <input class="input light_secondary w-full fs13 mb8" ref="project" placeholder="ワークスペース名を入力" required oninput="{_.noop}"> <div class="text-center"> <button class="button w220 {canSubmit() ? \'primary hover-pale\' : \'disabled pointer-none\'}" disabled="{!canSubmit()}">作成</button> </div> </form> </div>', '', 'class="f fh bg-overlay_secondary p8" spat-animation="push"', function(opts) {

    this.canSubmit = () => {
      return this.refs.project.value.trim();
    };

    this.onSubmit = async (e) => {
      e.preventDefault();
      var indicator = spat.modal.indicator();
      var workspace_name = this.refs.project.value;
      await app.api.child('workspaces').post({
        "name": workspace_name,
      });
      indicator.close();
      app.utils.openToast('createWorkspace');
      this.close();
    };
});