const riot = require('riot');

riot.tag2('page-notes-messages', '<div class="f fclm h-full"> <div class="s-full f flex-column overflow-hidden p8"> <div class="relative f fh font-lato mb4"> <button class="absolute trbl0 f fm w50 p4" onclick="{spat.router.back}"><i class="icon-arrow-left s16"></i> <div class="fs11 lh15 bold">back</div> </button><a class="fs12 lh16 bold w60per text-center word-break-all line-clamp-1 p4" href="/{note.path}">{note.data.title}</a> </div> <div class="overflow-hidden s-full p8 bg-white box-shadow-primary rounded-8"> <div class="s-full f"> <div class="s-full" data-is="module-messages" ref="messages"></div> </div> </div> </div> <div class="flex-fixed w-full mta s-show" if="{!app.utils.hasInputFocus()}" data-is="module-tabbar"></div> </div>', 'page-notes-messages,[data-is="page-notes-messages"]{display:block}', '', function(opts) {
    this.preload = ({req, res}) => {
      return {
        workspace_id: req.params.workspace_id,
        project_id: req.params.project_id,
        note_id: req.params.note_id,
      };
    };

    this.on('show', async () => {
      var note = app.store.doc(`workspaces/${this.workspace_id}/projects/${this.project_id}/notes/${this.note_id}`);
      this.refs.messages.setNote(note);
      this.note = await note.fetch();
      this.update();
    });
});