const riot = require('riot');

riot.tag2('atom-file', '<i class="icon-photo text-label_dark_medium hover-trigger hover-text-label_dark_primary cursor-pointer" onclick="{onInsertFile}"></i>', 'atom-file,[data-is="atom-file"]{display:block}', '', function(opts) {
    this.on('mount', () => {

      this.fileInput = app.utils.createImageFileInput(this.opts.accept);
      this.fileInput.onchange = (e) => {
        this.trigger("change", e.target);
      };
    });

    this.onInsertFile = () => {

      this.fileInput.value = '';
      this.fileInput.click();
    };
});