const riot = require('riot');

riot.tag2('module-button-note-create', '<div class="relative"> <div class="relative f fh w-full border border-label_dark_disabled rounded-8"> <button class="w-full py10 bold hover-trigger hover-text-primary" ref="create" onclick="{onCreateNote}">ノートを作成</button> <button class="py8" onclick="{onOpenPopup}"> <div class="px12 py3 border-label_dark_disabled border-left hover-trigger tooltip" aria-label="テンプレート" data-tooltip-position="top"><i class="icon-arrow-down fs16 text-background_dark_primary hover-text-primary"></i></div> </button> </div> </div>', 'module-button-note-create,[data-is="module-button-note-create"]{display:block}', '', function(opts) {

    this.onOpenPopup = (e) => {
      const popup = spat.popup.open('popup-note-templates', {
        element: this.root,

        closeTarget: e.currentTarget,
        offsetTop: 4,
        position: 'bottom',
        opts: {
          project: this.opts.project,
        },
      }).on('select', (e) => {
        this.onSelectTemplate(e.item);
        popup.close();
      }).on('create', () => {
        this.onCreateTemplate();
        popup.close();
      });
    };

    this.onSelectTemplate = async (template) => {
      const content = await app.utils.getTemplateContent(template);
      const title = app.utils.getTitle(content);

      const note = await this.opts.project.createNote({title, content, column_id: this.opts.column_id});
      this.trigger("notecreate",{note});
    };

    this.onCreateTemplate = async () => {
      const content = '\n\n#template';

      const note = await this.opts.project.createNote({content, column_id: this.opts.column_id});

      this.trigger("notecreate",{note});
    };

    this.onCreateNote = async () => {
      capacitor.hapticsImpactLight();
      const note = await this.opts.project.createNote({content:'# ', column_id: this.opts.column_id});
      this.trigger("notecreate",{note});
    };

    this.clickCreate = () => {
      this.refs.create.click();
    };
});