const riot = require('riot');

riot.tag2('page-workspaces-single', '<div class="s-full overflow-y-scroll bg-background_dark_primary text-white" ref="scrollContainer"> <div class="flex-fixed w-full mb50"> <div ref="projects" data-is="module-projects"></div> </div> <div class="fixed b0 w-full" if="{!app.utils.hasInputFocus()}" ref="tabbar" data-is="module-tabbar" onclickcurrenttab="{onClickCurrentTab}"></div> </div>', 'page-workspaces-single,[data-is="page-workspaces-single"]{display:block}', '', function(opts) {
    this.preload = ({req, res}) => {
      if (spat.isBrowser && !app.auth.isSignIn()) {
        res.redirect(301, '/signin');
        return ;
      }
    };

    this.on('show', async ({req, res}) => {
      await app.store.setup(req.params);
    });

    this.onClickCurrentTab = () => {
      app.utils.scrollToTop(this.refs.scrollContainer);
    };
});