const riot = require('riot');

riot.tag2('spat-toast', '<div class="f flex-column" ref="items"></div>', 'spat-toast,[data-is="spat-toast"]{display:block;position:fixed;bottom:0;left:55%;transform:translateX(-50%);-webkit-transform:translateX(-50%);-ms-transform:translateX(-50%);z-index:9999;padding-bottom:32px} spat-toast [data-is=spat-toast-item],[data-is="spat-toast"] [data-is=spat-toast-item]{margin-top:8px}', '', function(opts) {
    this.message = (text, {icon, timeout} = {}) => {
      var element = document.createElement('div');
      this.refs.items.appendChild(element);

      var tag = riot.mount(element, 'spat-toast-item', {
        text: text || 'Hello, Spat.js!', icon,
        timeout,
      })[0];

      tag.close = function() {
        tag.unmount();
      };
      tag.update();
    };

});
riot.tag2('spat-toast-item', '<div class="f fm fbw"> <div class="f fm mr16"><i class="fs20 mr10 icon-{opts.icon}" if="{opts.icon}"></i><span class="fs13 word-break-keep">{opts.text}</span></div><i class="icon-close fs20 cursor-pointer" onclick="{close}"></i> </div>', 'spat-toast-item,[data-is="spat-toast-item"]{padding:21px 16px;background-color:rgba(29,40,76,0.7);color:white;border-radius:8px;animation:toast-appear 500ms} spat-toast-item.disappear,[data-is="spat-toast-item"].disappear{animation:toast-disappear 500ms}@keyframes toast-appear{ 0%{transform:translateY(40px);opacity:0} 100%{transform:translateY(0);opacity:1}}@keyframes toast-disappear{ 0%{transform:translateY(0);opacity:1} 100%{transform:translateY(-40px);opacity:0}}', '', function(opts) {
    var timeout = this.opts.timeout || 10000;

    this.on('mount', () => {
      this._timeoutId = setTimeout(() => {
        this.root.classList.add('disappear');
        this.root.addEventListener('animationend', this.close);
      }, timeout);
    });

    this.on('unmount', () => {
      clearTimeout(this._timeoutId);
    });
});