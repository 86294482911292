const riot = require('riot');

riot.tag2('modal-workspace-invite', '<div class="f fclm max-width-472 w-full px16 pt19 pb24 bg-white rounded-8" ref="modal"> <div class="f fbw mb18"> <div class="text-background_dark_primary fs16 lh15 bold">{app.store.workspace.data.name}に{isGuest() ? \'ゲスト\' : \'メンバー\'}を招待</div><i class="icon-close fs24 text-label_dark_medium cursor-pointer" onclick="{close}"></i> </div> <form class="h-full mb24" ref="form" onsubmit="{addEmail}"> <label class="f ft max-height-120 min-height-88 pt15 px12 pb7 rounded-8 border border-separator_opacity overflow-y-scroll"> <div class="f fm flex-wrap overflow-x-scroll"> <div class="f fm py3 pl4 rounded-4 border cursor-pointer mr8 mb8" each="{value, index in values}"> <div class="lh15 fs10 bold mr2">{value}</div><i class="icon-close fs16" onclick="{onClickTagDelete}"></i> </div> <input class="w200 lh15 fs13 text-label_dark_primary outline-none mb8" ref="input" placeholder="{values.length ? &quot;&quot; : &quot;alog@example.com&quot;}" onkeydown="{onKeyDown}" onblur="{onBlur}" type="email"> </div> </label> </form> <div class="f mta {isGuest() ? &quot;fbw&quot; : &quot;fr&quot;}"> <div class="f fm cursor-pointer" if="{isGuest()}" onclick="{onCopyLink}"><i class="icon-copy text-primary fs24 mr8"></i> <div class="fs13 text-primary bold">招待リンクをコピー</div> </div> <div class="f fr"> <button class="button f fh h32 w58 px0 fs13 {values.length ? &quot;primary_fill&quot; : &quot;disabled_fill&quot;}" type="button" onclick="{onSubmit}">送信</button> </div> </div> </div>', 'modal-workspace-invite label:focus-within,[data-is="modal-workspace-invite"] label:focus-within{border:1px solid rgba(29,40,76,0.1) !important}', 'class="f fh bg-overlay_secondary p8" spat-animation="push"', function(opts) {

    this.values = [];

    this.addEmail = (e) => {
      if (e) {
        e.preventDefault();
      }

      var flag = this.refs.input.reportValidity();
      if (!flag) return ;

      var $input = this.refs.input;
      var value = $input.value;
      if (value) {
        this.values.push(value);
      }
      $input.value = '';
      $input.focus();
      this.update();
    };

    this.onClickTagDelete = (e) => {
      e.preventDefault();
      this.values.splice(e.item.index, 1);
      this.update();
    };

    this.onSubmit = async () => {

      var flag = this.refs.input.reportValidity();
      if (!flag) return ;
      if (this.refs.input.value) {
        this.addEmail();
      }

      await app.api.child('invites').post({
        workspace_id: app.store.getWorkspaceUser().data.workspace_id,
        emails: this.values,
        role: this.opts.role,
      });

      this.close();
      app.utils.openToast('inviteMail');
      this.trigger('submit');
    };

    this.onKeyDown = (e) => {
      var code = e.keyCode;
      var isBackspace = code === 8;
      if (isBackspace && !this.refs.input.value) {
        this.values.pop();
        this.update();
      };
    };

    this.onCopyLink = () => {
      alert('未実装です。');
    };

    this.isGuest = () => {
      return this.opts.role === 'guest';
    };

    this.onBlur = () => {

      if (!this.refs.input) return ;

      var flag = this.refs.input.reportValidity();
      if (flag) {
        this.addEmail();
      }
    };
});