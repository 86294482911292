const riot = require('riot');

riot.tag2('modal-note', '<div class="absolute t8 r8 z1 f fh s-hide gap-8"> <button class="f fh s32 rounded-full box-shadow-primary bg-white" onclick="{toggleSize}"><i class="icon-fullscreen fs16 tooltip" if="{!isFull}" aria-label="拡大" data-tooltip-position="top"></i><i class="icon-shrink fs16 tooltip" if="{isFull}" aria-label="縮小" data-tooltip-position="top"></i></button> <button class="f fh s32 rounded-full box-shadow-primary bg-white" if="{isFull}" onclick="{close}"><i class="icon-close fs16 tooltip" aria-label="閉じる" data-tooltip-position="top"></i></button> </div> <div class="f fh overflow-hidden s-full bg-white rounded-8" ref="modal"> <div class="relative overflow-hidden s-full p8 bg-white rounded-8"> <div class="s-full f"> <div class="col8 border-right" data-is="module-note" ref="note" show-arrow-button="{opts.showArrowButton}"></div> <div class="col4" data-is="module-messages" ref="messages"></div> </div> </div> </div>', 'modal-note,[data-is="modal-note"]{background-color:rgba(28,34,54,0.9)}', 'class="f fh p64"', function(opts) {
    this.on('mount', () => {
      app.shortcut.saveView();
      app.shortcut.setView('modalNote', { noteTag: this.refs.note, viewTag: this });
      this.refs.note.on('pagination', (e) => {
        this.trigger('pagination', e);
      });
      this.setNote(this.opts.note);
    });

    this.setNote = (note) => {
      this.note = note;
      this.refs.note.setNote(note);
      this.refs.messages.setNote(note);
      this.update();
    };

    this.on('unmount', () => {
      app.shortcut.restoreView();
    });

    this.focusMessage = () => {
      this.refs.messages.focusEditor();
    };

    this.toggleSize = () => {
      this.isFull = !this.isFull;

      if (this.isFull) {
        this.root.classList.remove('p64');
      }
      else {
        this.root.classList.add('p64');
      }
    };
});