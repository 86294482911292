const riot = require('riot');

riot.tag2('modal-reactions', '<div class="f fb s-full" onclick="{startClosing}"> <div class="w-full rounded-top-6 box-shadow-primary" ref="modal"> <div class="h240 overflow-y-scroll transition-128" ref="body" riot-style="max-height: {isOpen ? refs[&quot;body&quot;].scrollHeight : 0}px" ontransitionend="{onCloseTransition}"> <div class="row p24"> <div class="f fh s38 rounded-4 fs30 hover-trigger hover-bg-background_light_primary cursor-pointer mx2 my4" each="{reaction in opts.reactions._map}" onclick="{select}"> <div title="{reaction.name}">{reaction.value}</div> </div> </div> </div> </div> </div>', '', 'spat-animation=""', function(opts) {
    this.isOpen = false;

    this.on('mount', () => {
      setTimeout(() => {
        this.isOpen = true;
        this.update();
      });
    });

    this.startClosing = () => {
      this.isOpen = false;
      this.update();
    };

    this.onCloseTransition = () => {
      if (!this.isOpen) {
        this.close();
      }
    };

    this.select = (e) => {
      var item = e.item.reaction;
      this.trigger('selected', item);
    };
});