const riot = require('riot');

riot.tag2('popup-group', '<div class="font-lato w240 bg-white rounded-12 box-shadow-primary" ref="popup"> <div class="f fm fclm w-full py24 px12"><img class="block object-fit-cover flex-fixed s100 circle mb11" riot-src="{app.utils.getImageUrl(app.store.workspace.data.icon_image)}"> <div class="bold lh13 line-clamp-1 mb8">@{opts.mention.item.data.screen_name} </div> <div class="row fh"> <div class="f fh fs10 rounded-4 bg-separator_opacity text-label_dark_medium word-break-keep px4 py2 mr4 mb5 tooltip" each="{user in groupUsers}" aria-label="{user.data.display_name}" data-tooltip-position="top"><img class="flex-fixed s16 circle object-fit-cover mr4" riot-src="{app.utils.getImageUrl(user.data.icon_image)}"> <div class="line-clamp-1 word-break-all lh15">{user.data.screen_name}</div> </div> </div><a class="button primary w113 h40 mt24" if="{app.store.workspace_user.data.role !== &quot;guest&quot;}" href="/{app.store.workspace.path}/settings/groups" onclick="{close}">編集</a> </div> </div>', 'popup-group,[data-is="popup-group"]{display:block}', '', function(opts) {
    this.on('mount', async () => {
      this.groupUsers = await this.fetchGroupUsers(this.opts.mention);
      this.update();
    });

    this.fetchGroupUsers = async (mention) => {

      if (!app.store.project) return;
      var group_user_ids = mention.item.data.user_ids;
      var project_user_ids = app.store.project.data.user_ids;

      if (!mention.item.data.is_everyone) {
        project_user_ids = _.intersection(group_user_ids, project_user_ids);
      }

      var promises = project_user_ids.map(uid => {
        return app.store.collection('users').doc(uid).fetch();
      });

      return  await Promise.all(promises);
    };
});