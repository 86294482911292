const riot = require('riot');

riot.tag2('page-notes-single', '<div class="s-full f flex-column overflow-hidden"> <div data-is="module-header" project="{app.store.project}" view="{view}" back="{app.useragent.isMobile}"></div> <button class="absolute b100-puls-safearea r30 z2 f fh s50 bg-white box-shadow-primary circle border border-gray-200 s-show" if="{!shouldShowComments}" onclick="{toggleShowComment}"><i class="icon-comment mbn2 fs22 text-label_dark_medium"></i> <div class="absolute t2 rn5" if="{isUnread()}"> <div class="f fh circle bg-alert_primary font-lato fs10 lh13 text-white bold {getUnreadLabel() ? &quot;s16 pb2&quot; : &quot;s8 m4&quot;}">{getUnreadLabel()}</div> </div> </button> <div class="s-full px16 pb8 overflow-hidden"> <div class="s-full bg-white box-shadow-primary rounded-8"> <div class="s-full f s-flex-column"> <div class="col8 border-right s-col12 {shouldShowComments ? &quot;s-h50per&quot; : &quot;h-full&quot;}" data-is="module-note" if="{!isNoteTagHidden}"></div> <div class="relative s-full flex-fixed transition-128 col4 s-col12 {isCommentsFullScreen ? &quot;s-h90per&quot; : &quot;s-h50per&quot;}" show="{!app.useragent.isMobile || shouldShowComments}"> <div class="s-full" data-is="module-messages" ref="messages"></div> <div class="absolute r0 t0 f fm fbw border-top border-gray w-full h26 bg-separator_opacity bg-blur-8" if="{shouldShowComments}"> <button class="p8 transition f fh s40" onclick="{toggleCommentsFullScreen}"><i class="icon-arrow-up fs18 {&quot;rotate-180&quot; : isCommentsFullScreen}"></i></button><a class="fs12 bold label_dark_primary pl24" href="/{note.path}/messages">コメント一覧</a> <button class="py8 px12" onclick="{toggleShowComment}"> <p class="icon-arrow-dow fs13">閉じる</p> </button> </div> </div> </div> </div> </div> <div class="flex-fixed w-full mta s-show" if="{!app.utils.hasInputFocus()}" data-is="module-tabbar"></div> </div>', 'page-notes-single,[data-is="page-notes-single"]{display:block}', '', function(opts) {
    this.on('show', () => {

      this.isCommentsFullScreen = false;

      this.shouldShowComments = false;
    });

    this.preload = ({req, res}) => {
      return {
        workspace_id: req.params.workspace_id,
        project_id: req.params.project_id,
        note_id: req.params.note_id,
      };
    };

    this.on('show', async ({req, res}) => {

      this.resetNoteTag();
      await app.store.setup(req.params);

      var note = app.store.doc(`workspaces/${this.workspace_id}/projects/${this.project_id}/notes/${this.note_id}`);

      this.note = note;

      this.tags['module-note'].setNote(note);

      this.refs.messages.setNote(note);

      if (app.useragent.isMobile) {
        this.inbox = await note.fetchInbox();
      }

      await note.fetch();
      spat.updateMeta();
      this.update();
    });

    this.resetNoteTag = () => {
      this.update({ isNoteTagHidden: true });
      this.update({ isNoteTagHidden: false });
    };

    this.head = () => {
      if (this.note) {
        return {
          title: `「${this.note.getTitle()}」 | ${spat.config.head.ogp.site_name}`,
        };
      }
    };

    this.getUnreadLabel = () => {
      return this.inbox?.isMention() ? '@' : '';
    };

    this.isUnread = () => {
      if (this.inbox) {
        return !this.inbox.data.looked;
      }
    };

    this.toggleCommentsFullScreen = () => {
      this.isCommentsFullScreen = !this.isCommentsFullScreen;
    };

    this.toggleShowComment = async () => {
      this.shouldShowComments = !this.shouldShowComments;

      if (!this.shouldShowComments) {
        this.inbox = await this.note.fetchInbox();
      }
      this.update();
    };
});