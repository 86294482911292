const riot = require('riot');

riot.tag2('popup-note-templates', '<div class="w280 px8" ref="popup"> <div class="bg-white rounded-8 px24 py12 box-shadow-primary"> <div class="h-full max-height-323 scrollbar-none overflow-y-scroll"> <div class="f fm mb8"> <div class="fs13 bold mr8">テンプレートを選択</div><i class="icon-help cursor-pointer fs16 text-label_dark_primary tooltip" if="{!opts.omitCreate}" aria-label="{app.data.tips.note_create}" data-tooltip-offset-top="4"></i> </div> <button class="button label_dark_disabled f fh w-full py4 overflow-hidden transition" if="{!opts.omitCreate}" onclick="{onCreate}"><i class="icon-add fs16 mr4"></i> <div class="bold fs13">テンプレートを作成</div> </button> <div class="border-top pt8 mt8" if="{templates.length}"> <button class="button pale w-full px8 py11 transition mb8" if="{item.data.title.length}" each="{item in templates}" onclick="{onSelect}"> <div class="fs11 lh15 word-break-all text-justify">{item.data.title}</div> </button> </div> </div> </div> </div>', '', '', function(opts) {
    this.on('mount', async () => {
      this.templates = await opts.project.fetchTemplates();
      this.update();
    });

    this.onCreate = () => {
      this.trigger('create');
    };

    this.onSelect = (e) => {
      this.trigger('select', { item: e.item.item });
    };
});