const riot = require('riot');

riot.tag2('modal-imageviewer', '<div class="f fh s-full px24" ref="modal"> <div class="w-full h80vh max-width-60vw s-max-width-100vw"><img class="block s-full object-fit-contain {transition: transition}" ref="image" show="{initialized}" ondragstart="{stop}" riot-src="{opts.image}"></div> </div> <div class="absolute t32 r32 bg-white p4 circle cursor-pointer translate3d-z1" ref="close"><i class="icon-close fs28 s-fs20" onclick="{close}"> </i></div>', 'modal-imageviewer,[data-is="modal-imageviewer"]{background-color:rgba(28,34,54,0.9)} modal-imageviewer .translate3d-z1,[data-is="modal-imageviewer"] .translate3d-z1{transform:translate3d(0, 0, 1px)}', 'class="relative" spat-animation="fade"', function(opts) {
    this.on('mount', () => {
      let x = 0;
      let y = 0;
      let s = 1;
      let prevX = 0
      let prevY = 0
      let lastScale = s;
      const MAX_SCALE = 5;

      this.hammer = new Hammer(this.refs.modal);

      this.hammer.get('pinch').set({enable: true});
      this.hammer.get('pan').set({enable: true, threshold: 1});

      if (this.refs.image.complete) {
        this.setupSize();
      }
      else {
        this.refs.image.onload = () => {
          this.setupSize();
          this.refs.image.onload = null;
        }
      }

      this.hammer.on('tap doubletap pan panend pinch pinchend', e => {
        let $img = this.refs.image;
        let rect = $img.getBoundingClientRect();
        this.checkClickPosition(rect, e);
        let move = () => {
          x += e.deltaX - prevX;
          y += e.deltaY - prevY;
          prevX = e.deltaX;
          prevY = e.deltaY;
        };
        let fixPosition = () => {

          let overflow_x = Math.max(0, rect.width - innerWidth) / 2;
          let overflow_y = Math.max(0, rect.height - innerHeight) / 2;
          x = Math.max(Math.min(overflow_x, x + e.velocityX * 256), - overflow_x);
          y = Math.max(Math.min(overflow_y, y + e.velocityY * 256), - overflow_y);
          s = Math.min(Math.max(1, s), MAX_SCALE);

          if (s === 1) {
            x = y = 0;
          }
        };

        if (e.type === 'tap') {
          if (e.target === this.refs.close) return ;
          this.update();
          return ;
        }
        this.transition = false;

        if (e.type === 'doubletap') {
          this.transition = true;
          if (lastScale >= 2) {
            s = lastScale = 1;
            fixPosition();
          }
          else {
            s = lastScale = 2;
          }
        }

        else if (e.type === 'pinch') {
          s = lastScale * e.scale;
          move();
        }

        else if (e.type === 'pan') {
          move();
        }

        else if (e.type === 'pinchend') {
          this.transition = true;
          fixPosition();
          lastScale = s;
          prevX = 0;
          prevY = 0;
          this.hammer.stop();
        }

        else if (e.type === 'panend') {
          this.transition = true;
          prevY = prevX = 0;
          fixPosition();
        }
        this.update();
        $img.style.transform = `translate3d(${x}px, ${y}px, 1px) scale(${s})`;
      })
    });

    this.on('unmount', () => {
      this.hammer.destroy();
    });

    this.setupSize = () => {
      let $img = this.refs.image;
      let width_rate = $img.naturalWidth /innerWidth;
      let height_rate = $img.naturalHeight / innerHeight;
      if (width_rate >= height_rate) {
        $img.style.width = '100%';
      }
      else {
        $img.style.height = '100%';
      }
      this.initialized = true;
      this.update();
    };

    this.stop = (e) => {
      e.stopPropagation();
      e.preventDefault();
    };

    this.checkClickPosition = (rect, event) => {
      let {top, right, bottom, left} = rect;
      let {x, y} = event.center;
      if (x < left || x > right || y < top || y > bottom) {
        this.close();
      }
    };
});