
var capacitor = {
  isAvailable: !!global.Capacitor,
  _isFinishedPushNotificationSetup: false,

  enableSwipeBack() {
    if (!this.isAvailable) return ;

    Capacitor.Plugins.IosSwipeBack.enable();
  },

  async signInGoogleAuth() {
    if (!this.isAvailable) return ;
    
    // native
    var GoogleAuth = Capacitor.Plugins.GoogleAuth;

    await GoogleAuth.signOut();
    var googleUser = await GoogleAuth.signIn();
    const credential = firebase.auth.GoogleAuthProvider.credential(googleUser.authentication.idToken);

    await app.auth.signInWithCredential(credential);
  },

  setupPushNotifications() {
    if (!this.isAvailable) return ;

    // セットアップ済みのときは何もしない
    if (this._isFinishedPushNotificationSetup) return ;

    var PushNotifications = Capacitor.Plugins.PushNotifications;

    PushNotifications.requestPermissions().then(result => {
      // console.log(result.receive);
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });
  
    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', async (token) => {
      var device_id = await Capacitor.Plugins.Device.getId();
      var data = {
        "device_id": device_id.uuid,
        "platform": Capacitor.getPlatform(),
        "token": token.value,
      };
        
      var res = await app.api.child('me/push/entry').post(data);
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', async (error) => {
      // alert('Error on registration: ' + JSON.stringify(error));
    });
    
    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived', (notification) => {
      // alert('Push received: ' + JSON.stringify(notification));
    });
  
    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed', ({notification}) => {
      console.log(JSON.stringify(notification, null, '  '));
      spat.router.push(notification.data.url);
    });

    this._isFinishedPushNotificationSetup = true;
  },

  clearBadge() {
    if (!this.isAvailable) return ;

    var PushNotifications = Capacitor.Plugins.PushNotifications;

    PushNotifications.removeAllDeliveredNotifications();
  },

  // ブラウザで開く
  // presentationStyle: popover or fullscreen
  openInBrowser(url, {presentationStyle='popover'}={}) {
    if (!this.isAvailable) return ;

    Capacitor.Plugins.Browser.open({url, presentationStyle});
  },

    // 触覚フィードバック
  //  小
  async hapticsImpactLight() {
    if (!this.isAvailable) return;
    await Capacitor.Plugins.Haptics.impact({ style: 'LIGHT' });
  },

  // 中
  async hapticsImpactMedium() {
    if (!this.isAvailable) return;
    await Capacitor.Plugins.Haptics.impact({ style: 'MEDIUM' });
  },
  
  // 大
  async hapticsImpactHeavy() {
    if (!this.isAvailable) return;
    await Capacitor.Plugins.Haptics.impact({ style: 'HEAVY' });
  },
};

export default capacitor;