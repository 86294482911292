const riot = require('riot');

riot.tag2('atom-emoji', '<i class="icon-face text-label_dark_medium hover-trigger hover-text-background_dark_primary cursor-pointer" onclick="{onEmojiClick}" onmouseenter="{onMouseenter}"></i>', 'atom-emoji,[data-is="atom-emoji"]{display:block}', '', function(opts) {
    this.onEmojiClick = (e) => {
      return app.useragent.isMobile ? this.onOpenModalEmojiPicker() : this.onOpenPopupEmojiPicker();
    };

    this.onOpenPopupEmojiPicker = (e) => {
      const POPUP_HEIGHT = 435;
      let height_diff = innerHeight - this.root.top;

      let is_offscreen = height_diff < POPUP_HEIGHT;
      let offsetLeft = is_offscreen ? -48 : -4;

      let default_options = {
        element: this.root,
        closeTarget: this.root,
        align: { y: 'top'},
        offsetLeft,
        offsetTop: 28,
        position: 'left',
      };

      let options = this.opts.getpopupoptions ? Object.assign(default_options, this.opts.getpopupoptions()) : default_options;

      spat.popup.open('popup-emoji-picker', options).on('selected', async (emoji) => {
        if (this.opts.onselected) {
          await this.opts.onselected(emoji);
        }
      });
    };

    this.onOpenModalEmojiPicker = () => {
      spat.modal.open('modal-emoji-picker').on('selected', async (emoji) => {
        if (this.opts.onselected) {
          await this.opts.onselected(emoji);
        }
      });
    };

    this.onMouseenter = (e) => {
      if (!this.opts.disabledCommentPopup) {
        this.onOpenPopupEmojiMini(e);
      }
    };

    this.onOpenPopupEmojiMini = async (e) => {

      if (!app.useragent.isDesktop || this.popup) return;

      this.popup = spat.popup.open('popup-emoji-mini', {
        element: e.currentTarget,
        offsetTop: -2,
        position: 'top',
      });

      this.popup.on('selected', (emoji) => {
        this.opts.onselected(emoji);
        this.popup?.close();
      });

      this.popup.on('close', () => {
        this.popup = null;
      });

      this.popup.on('hideSpaceClick', () => {
        this.onEmojiClick(e);
      });
    };
});