const riot = require('riot');

riot.tag2('item-note', '<a class="relative block pb7 p12 transition hover-trigger hover-bg-white {opts.isActive ? \'bg-white\' : \'bg-background_light_secondary\'}" href="{opts.link ? opts.link : getLink()}"> <div class="absolute t0 b0 l0" if="{opts.enableActiveBorder &amp;&amp; opts.isActive}"> <div class="w2 h-full bg-primary"></div> </div> <div class="f fm mb8"><i class="icon-fill_pin text-label_dark_medium fs16 mr4" if="{opts.item.isPinned()}"></i> <div class="lh15 word-break-all mr2">{opts.item.data.title || ⁗(新しいノート)⁗}</div> </div> <div class="f fm fbw"> <div class="f fm"> <div class="f fm py8 pr12 text-label_dark_medium mr8" onclick="{onGoMessages}"><i class="icon-comment pt1 fs16 mr4 tooltip" aria-label="メッセージへ" data-tooltip-position="right"></i> <div class="fs12 lh15">{opts.item.data.message_count}件</div><i class="icon-arrow-right pt1 fs12"></i> </div> <div class="f fm" onclick="{onOpenScheduleModal}"><i class="icon-time pt2 fs16 text-label_dark_medium mr4 tooltip" aria-label="期限を設定" data-tooltip-position="right"></i> <div class="fs12 lh15 {opts.item.getTermColor()}" if="{isShowTerm()}" data-is="atom-time" date="{opts.item.data.end_at}" position="right"></div> </div> </div> <div data-is="atom-assign" note="{opts.item}"></div> </div> <div class="f fm fbw mt8" if="{!opts.hideStatus}"> <div class="f fm"> <div class="mr8" if="{opts.showProject}" data-is="atom-project" item="{opts.item.getProject()}"></div> <div class="cursor-pointer mr16" data-is="atom-status" note="{opts.item}"></div> </div> </div> <div class="absolute t4 r4" if="{isUnread()}"> <div class="f fh circle bg-alert_primary font-lato fs10 lh13 text-white bold {getUnreadLabel() ? &quot;s16 pb2&quot; : &quot;s8 m4&quot;}">{getUnreadLabel()}</div> </div></a>', 'item-note,[data-is="item-note"]{display:block}', '', function(opts) {
    this.on('mount', async () => {
    });

    this.isActive = () => {
      if (!this.opts.currentNote) return false;

      return this.opts.currentNote.id === this.opts.item.id;
    };

    this.isUnread = () => {
      var inbox = this.opts.item.getInbox();

      if (inbox) {
        return !inbox.data.looked;
      }
    };

    this.getUnreadLabel = () => {
      var inbox = this.opts.item.getInbox();
      return inbox.isMention() ? '@' : '';
    };

    this.getLink = () => {
      if (this.opts.view) {
        return `/${this.opts.item.path}?view=${this.opts.view}`;
      }
      else {
        return `/${this.opts.item.path}`;
      }
    };

    this.onGoMessages = (e) => {
      e.preventDefault();
      e.stopPropagation();
      spat.router.push(`/${this.opts.item.path}/messages`);
    };

    this.onOpenScheduleModal = async (e) => {
      e.preventDefault();
      e.stopPropagation();

      var modal = spat.modal.open('modal-calendar', {note: this.opts.item});
      await modal.waitClose();
      await this.opts.item.refresh();
      this.update();
    };

    this.isShowTerm = () => {
      return this.opts.item.data.end_at;
    };
});