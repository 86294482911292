// キーボードショートカット系の管理クラス
const shortcut = {
  actions: {
    // 最後に選択したプロジェクトがあればそこへ。なければinboxへ
    gotoHome: {
      type: 'goto',
      label: 'ホームへ移動',
      run: () => {
        const { workspace_id, project_id, projectsUsersStore } = app.store;
        if (!workspace_id) {
          spat.router.push('/');
          return;
        }
        let projects_users;
        if (projectsUsersStore && project_id) {
          projects_users = projectsUsersStore.items.find(item => item.data.project_id === project_id);
        }
        if (!projects_users) {
          spat.router.push(`/workspaces/${app.store.workspace.id}/inboxes`);
          return;
        }
        spat.router.push(projects_users.getProjectURL());
      },
    },
    gotoInboxes: {
      type: 'goto',
      label: 'インボックスへ移動',
      run: () => {
        if (app.store.workspace_id) {
          spat.router.push(`/workspaces/${app.store.workspace.id}/inboxes`);
        }
        else {
          spat.router.push('/');
        }
      },
    },
    gotoNotifications: {
      type: 'goto',
      label: '通知へ移動',
      run: () => {
        if (app.store.workspace_id) {
          spat.router.push(`/workspaces/${app.store.workspace.id}/notifications`);
        }
      },
    },
    gotoReminds: {
      type: 'goto',
      label: 'リマインドへ移動',
      run: () => {
        if (app.store.workspace_id) {
          spat.router.push(`/workspaces/${app.store.workspace.id}/notifications/reminds`);
        }
      },
    },
    gotoActivity: {
      type: 'goto',
      label: 'アクティビティへ移動',
      run: () => {
        if (app.store.workspace_id) {
          spat.router.push(`/workspaces/${app.store.workspace_id}/notifications/activities`);
        }
      },
    },
    gotoList: {
      type: 'goto',
      label: 'Listへ移動',
      run: () => {
        const { workspace_id, project_id } = app.store;
        if (workspace_id && project_id) {
          spat.router.push(`/workspaces/${workspace_id}/projects/${project_id}?view=list`);
        }
        else {
          spat.router.push('/');
        }
      },
    },
    gotoBoard: {
      type: 'goto',
      label: 'Boardへ移動',
      run: () => {
        const { workspace_id, project_id } = app.store;
        if (workspace_id && project_id) {
          spat.router.push(`/workspaces/${workspace_id}/projects/${project_id}?view=board`);
        }
        else {
          spat.router.push('/');
        }
      },
    },
    gotoTable: {
      type: 'goto',
      label: 'Tableへ移動',
      run: () => {
        const { workspace_id, project_id } = app.store;
        if (workspace_id && project_id) {
          spat.router.push(`/workspaces/${workspace_id}/projects/${project_id}?view=table`);
        }
        else {
          spat.router.push('/');
        }
      },
    },
    gotoGantt: {
      type: 'goto',
      label: 'Ganttへ移動',
      run: () => {
        const { workspace_id, project_id } = app.store;
        if (workspace_id && project_id) {
          spat.router.push(`/workspaces/${workspace_id}/projects/${project_id}?view=gantt`);
        }
        else {
          spat.router.push('/');
        }
      },
    },
    gotoSearch: {
      type: 'goto',
      label: '検索ページへ移動',
      run: () => {
        const { workspace_id } = app.store;
        if (workspace_id) {
          spat.router.push(`/workspaces/${workspace_id}/search`);
        }
        else {
          spat.router.push('/');
        }
      },
    },
    openSwitcher: {
      type: 'tool',
      label: 'スイッチャーを開く',
      run: () => {
        const modal = spat.modal.open('modal-switcher');
        modal.focusInput();
      },
    },
    openShortcutHelp: {
      type: 'help',
      label: 'ショートカットキーのヘルプを開く',
      run: () => {
        spat.modal.open('modal-shortcut-help');
      },
    },
    blur: {
      type: 'other',
      label: '要素のフォーカスを外す',
      run: () => {
        document.activeElement.blur();
      },
    },

    // editor
    editorSubmit: {
      label: '送信する',
      run: (editor) => {
        editor.trigger('submit');
      },
    },
    editorSave: {
      label: '保存する',
      run: (editor) => {
        editor.trigger('save');
      },
    },

    // note
    notePrev: {
      label: '前のノートを表示',
      run: ({ noteTag }) => {
        noteTag.trigger('pagination', { offset: -1 });
      },
    },
    noteNext: {
      label: '次のノートを表示',
      run: ({ noteTag }) => {
        noteTag.trigger('pagination', { offset: 1 });
      },
    },
    noteEdit: {
      label: 'ノートを編集',
      run: ({ noteTag }) => {
        noteTag.refs.editor.focus();
      },
    },

    clickNoteCreate: {
      label: '新規ノート作成',
      run: ({ viewTag }) => {
        if (!confirm('新規ノートを作成しますか？')) return;
        viewTag.tags['module-button-note-create'].clickCreate();
      },
    },

    referenceNoteCreate: {
      label: '関連ノート作成',
      run: ({ noteTag }) => {
        noteTag.tags['module-note-header'].onOpenModalReferredNote();
      },
    },

    clickAtomStatus: {
      label: 'カラムを変更',
      run: ({ noteTag }) => {
        noteTag.refs.header.tags['atom-status'].click();
      }
    },
    
    clickAtomAssign: {
      label: 'アサインを変更',
      run: ({ noteTag }) => {
        noteTag.refs.header.tags['atom-assign'].click();
      }
    },

    openScheduleModal: {
      label: '期限を設定',
      run: ({ noteTag }) => {
        noteTag.refs.header.openScheduleModal();
      }
    },

    focusNoteFilter: {
      label: 'フィルターにフォーカス',
      run: ({ viewTag }) => {
        viewTag.focusNoteFilter();
      }
    },

    focusMessage: {
      label: 'メッセージにフォーカス',
      run: ({ viewTag }) => {
        viewTag.focusMessage();
      }
    },

    quickFilter: {
      label: 'アサインフィルターをトグル',
      run: ({ viewTag }) => {
        viewTag.clickAssignedCheckbox();
      }
    },

    openNote: {
      label: 'ノートを編集',
      run: ({ viewTag }) => {
        if (viewTag.openCurrentNote) {
          viewTag.openCurrentNote();
        }
      },
    },

    // remind
    toggleMeMention: {
      label: '個人メンションのみのチェックをトグル',
      run: ({ viewTag }) => {
        if (viewTag.clickMeMentionCheckbox) {
          viewTag.clickMeMentionCheckbox();
        }
      },
    },

    toggleDoneCurrentRemind: {
      label: 'ホバーしているリマインドの完了状態をトグル',
      run: ({ viewTag }) => {
        if (viewTag.toggleDoneCurrentRemind) {
          viewTag.toggleDoneCurrentRemind();
        }
      }
    },
  },
  commands: {
    'cmd+k': 'openSwitcher',
    'ctrl+k': 'openSwitcher',
    'shift+/': 'openShortcutHelp',
  },
  doubleKeyCommands: {
    g: {
      h: 'gotoHome',
      i: 'gotoInboxes',
      n: 'gotoNotifications',
      r: 'gotoReminds',
      a: 'gotoActivity',
      l: 'gotoList',
      b: 'gotoBoard',
      t: 'gotoTable',
      g: 'gotoGantt',
      f: 'gotoSearch',
    },
  },
  currentCommands: null,
  // 画面によって別のコマンドを実行する
  views: {
    // エディタ特有のショートカットキーは必要最低限に抑えて、 /command で実装する方針にしたい
    editor: {
      label: 'エディターフォーカス時',
      commands: {
        'cmd+k': 'openSwitcher',
        'cmd+enter': 'editorSubmit',
        'cmd+s': 'editorSave',
      },
      doubleKeyCommands: {
        // デフォルトのescコマンドを上書きしてしまったのでだめ
        // esc: { esc: 'blur' },
      },
    },
    
    inbox: {
      label: 'インボックス表示時',
      useHotkeys: true,
      commands: {
        // 'alt+up': 'notePrev',
        // 'alt+down': 'noteNext',
        'alt+e': 'noteEdit',
        'alt+n': 'referenceNoteCreate',
        'f': 'focusNoteFilter',
        'q': 'quickFilter',
        'm': 'focusMessage',
        's': 'clickAtomStatus',
        'a': 'clickAtomAssign',
        'd': 'openScheduleModal',
      },
      doubleKeyCommands: {

      },
    },

    list: {
      label: 'リスト表示時',
      useHotkeys: true,
      commands: {
        // 'alt+up': 'notePrev',
        // 'alt+down': 'noteNext',
        'n': 'clickNoteCreate',
        'f': 'focusNoteFilter',
        'q': 'quickFilter',
        'm': 'focusMessage',
        'alt+e': 'noteEdit',
        'alt+n': 'referenceNoteCreate',
        's': 'clickAtomStatus',
        'a': 'clickAtomAssign',
        'd': 'openScheduleModal',
      },
      doubleKeyCommands: {

      },
    },

    modalNote: {
      label: 'ノートモーダル表示時',
      useHotkeys: true,
      commands: {
        'alt+e': 'noteEdit',
        'alt+n': 'referenceNoteCreate',
        'm': 'focusMessage',
        's': 'clickAtomStatus',
        'a': 'clickAtomAssign',
        'd': 'openScheduleModal',
        'alt+up': 'notePrev',
        'alt+down': 'noteNext',
      },
      doubleKeyCommands: {

      },
    },

    board: {
      label: 'ボード表示時',
      useHotkeys: true,
      commands: {
        'f': 'focusNoteFilter',
        'q': 'quickFilter',
        'e': 'openNote',
        // 's': 'clickAtomStatus',
        // 'a': 'clickAtomAssign',
        // 'd': 'openScheduleModal',
      },
      doubleKeyCommands: {

      },
    },

    table: {
      label: 'テーブル表示時',
      useHotkeys: true,
      commands: {
        'f': 'focusNoteFilter',
        'q': 'quickFilter',
        // 'e': 'noteEdit',
        // 's': 'clickAtomStatus',
        // 'a': 'clickAtomAssign',
        // 'd': 'openScheduleModal',
      },
      doubleKeyCommands: {

      },
    },

    remind: {
      label: 'リマインド表示時',
      useHotkeys: true,
      commands: {
        'm': 'toggleMeMention',
        'x': 'toggleDoneCurrentRemind',
      }
    }

  },

  setup() {
    if (spat.isNode) return ;
    this.setupView(this);
    for (let key in this.views) {
      const viewData = this.views[key];
      if (viewData.useHotkeys) {
        this.setupView(viewData, key);
      }
    }
  },

  setupView(viewData, scope = 'all') {
    if (spat.isNode) return ;
    const keys = [];
    viewData = viewData || this;
    keys.push(...Object.keys(viewData.commands));
    for (let key in viewData.doubleKeyCommands) {
      // g+n と g と n で反応するようにする
      const nextKeys = Object.keys(viewData.doubleKeyCommands[key]);
      keys.push(
        key,
        ...nextKeys,
        ...nextKeys.map(nextKey => `${key}+${nextKey}`)
      );
    }
    // ショートカットキー登録
    hotkeys(_.uniq(keys).join(','), {
      scope,
    }, (event, handler) => {
      // ロック中は何もしない
      if (this._lock) {
        return;
      }
      // ２つ目のキーを押したときの処理
      if (this.currentCommands) {
        let key = handler.key;
        const keys = handler.key.split('+');
        // g+n の同時押しパターン
        if (keys.length >= 2) {
          key = keys[1];
        }
        if (this.runByKey(this.currentCommands, key)) {
          event.preventDefault();
        }
        this.currentCommands = null;
        return;
      }
      // 単一のコマンドのパターン
      if (this.runByKey(viewData.commands, handler.key)) {
        event.preventDefault();
        return;
      }
      // 複数キーコマンドの1つ目のキーを押したとき
      if (this.setCurrentCommands(handler.key, viewData)) {
        event.preventDefault();
      }
    });
  },

  // 2つ目のキーのオブジェクトをセットする。セットできた場合は true
  setCurrentCommands(firstKey, viewData) {
    return !!(this.currentCommands = viewData.doubleKeyCommands[firstKey]);
  },

  // ショートカットキーのマップとキーからアクションを実行する。実行した場合は true
  runByKey(commands, key) {
    const args = this.currentArgs || [];
    return this.run(commands[key], ...args);
  },

  // アクション名からアクションを実行する。実行できた場合は true
  run(actionName, ...args) {
    const action = this.actions[actionName];
    if (action) {
      this.lock();
      action.run(...args);
      return true;
    }
  },

  lock(ms = 16) {
    if (this._lockTimeoutId) {
      clearTimeout(this._lockTimeoutId);
    }
    this._lockTimeoutId = setTimeout(() => {
      this._lock = false;
    }, ms);
    this._lock = true;
  },

  getHelpList(shortcutObj) {

    // 一旦一次元配列にする
    const helpItems = [];

    for (let key in shortcutObj.commands) {
      const action = this.actions[shortcutObj.commands[key]];
      if (!action) continue;
      // 同じアクションのコマンドはまとめる
      const duplicateActionItem = helpItems.find(item => item.action === action);
      if (duplicateActionItem) {
        duplicateActionItem.commands.push([key]);
        continue;
      }
      helpItems.push({
        commands: [[key]],
        action,
      });
    }

    for (let firstKey in shortcutObj.doubleKeyCommands) {
      const commands = shortcutObj.doubleKeyCommands[firstKey];
      for (let nextKey in commands) {
        const action = this.actions[commands[nextKey]];
        if (!action) continue;
        // 同じアクションのコマンドはまとめる
        const duplicateActionItem = helpItems.find(item => item.action === action);
        if (duplicateActionItem) {
          duplicateActionItem.commands.push([firstKey, nextKey]);
          continue;
        }
        helpItems.push({
          commands: [[firstKey, nextKey]],
          action,
        });
      }
    }

    return helpItems;
  },

  getTypeLabel(type) {
    return {
      help: 'ヘルプ',
      goto: 'ページ遷移',
      tool: 'ツール',
    }[type] || type;
  },

  // help 表示するようのデータを生成して返す
  getHelpData() {
    const helpItems = this.getHelpList(this);
    // type ごとに階層分けする
    const helpData = {};
    helpItems.forEach(helpItem => {
      const data = helpData[helpItem.action.type] = helpData[helpItem.action.type] || { label: this.getTypeLabel(helpItem.action.type), items: [] };
      data.items.push(helpItem);
    });

    for (let viewName in this.views) {
      const view = this.views[viewName];
      const helpItems = this.getHelpList(view);
      const type = 'view_' + viewName;
      helpData[type] = {
        label: view.label,
        items: helpItems,
      }
    }

    // どうしてもできなかったので例外的に手動で定義する
    helpData.view_editor.items.push({
      commands: [['esc', 'esc']],
      action: this.actions.blur,
    });
    return helpData;
  },

  setView(type, ...args) {
    if (spat.isNode) return;
    if (!type) {
      return this.setDefaultView();
    }
    this.currentViewType = type;
    this.currentArgs = args;
    hotkeys.setScope(type);
  },

  setDefaultView() {
    if (spat.isNode) return ;
    this.currentViewType = null;
    this.currentArgs = null;
    hotkeys.setScope('all');
  },

  _viewStack: [],

  // 現在のviewをスタックする
  saveView() {
    const viewArgs = [this.currentViewType];
    if (this.currentArgs) {
      viewArgs.push(...this.currentArgs);
    }
    this._viewStack.push(viewArgs);
  },

  // スタックを一つ戻す
  restoreView() {
    const viewArgs = this._viewStack.pop();
    if (!viewArgs) {
      return this.setDefaultView();
    }
    this.setView(...viewArgs);
  },

  getViewData() {
    if (this.currentViewType) {
      return this.views[this.currentViewType];
    }
    else {
      return this;
    }
  },

  getCtrlKeyLabel() {
    return app.useragent.isMac ? '⌘' : 'Ctrl';
  }
};

export default shortcut;

