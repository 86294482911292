const riot = require('riot');

riot.tag2('module-faq', '<div class="f fclm pt32 mb80"> <h1 class="f fc lh13 fs24 bold mb48 s-fs22 s-mb32">{app.data.faq.title}</h1> <div class="f fc w-full pb48 s-pb24"> <div class="relative inline-block f fm w-full search_box"> <input class="input pale w-full p21 pl30 fs16" ref="search" placeholder="キーワードで検索" oninput="{() =&gt; debounceSearch(refs.search.value)}"><i class="absolute b13 r15 icon-close fs16 cursor-pointer" if="{refs.search.value}" onclick="{deleteText}"></i> </div> </div> <div if="{refs.search &amp;&amp; refs.search.value &amp;&amp; refs.search.value.length &gt; 1}"> <p class="pb32 fs28 fw7">検索結果</p> <div class="text-center label_dark-primary" if="{noResultsMessage}"> <p class="pb16 fs18 fw7">{noResultsMessage}</p> <p class="fs16">別のキーワードで検索してみてください。</p> </div> </div> <section class="w-full mb48 mb0-last" ref="{questionBox}" each="{category in displayFaq}"> <h2 class="fs20 bold mb32">{category.title}</h2> <div class="button s-full f fclm rounded-8 p0 transition mb24 mb0-last" data-is="item-faq_" each="{question in category.questions}" question="{question}"></div> </section> </div>', 'module-faq,[data-is="module-faq"]{display:block} module-faq input:focus,[data-is="module-faq"] input:focus{border:solid 1px #4466EA !important;outline:1} module-faq .search_box::before,[data-is="module-faq"] .search_box::before{content:"";width:16px;height:16px;background:url(\'/images/icons/search.svg\') no-repeat center center / auto 100%;display:inline-block;position:absolute;bottom:13px;left:10px;z-index:5}', '', function(opts) {

    this.displayFaq = app.data.faq.categories;

    this.keyword = '';

    this.preload = async ({req, res}) => {
      var keyword = req.query.keyword || '';
      this.refs.search.value = keyword;
      this.searchFaq(keyword);
    };

    this.searchFaq = (value) => {

      value = value?.toLowerCase().trim();

      if (!value || value.length <= 1) {
        this.displayFaq = app.data.faq.categories;
        this.update();
        return ;
      }

      let search_target_keys = ['question', 'answer'];

      this.displayFaq = app.data.faq.categories.map(c => {
        let result_questions = (Array.isArray(c.questions) ? c.questions : []).filter(q => {
          return search_target_keys.some(k => {
            if (!q[k]) return ;
            let plain_text = app.utils.removeMd(q[k]).replace(/\r?\n/g, '').toLowerCase();
            return plain_text.includes(value);
          });
        });

        if (!result_questions.length) return ;
        return {
          title: c.title,
          questions: result_questions,
        }
      }).filter(Boolean);
      this.update();
    };

    this.syncURL = (keyword) => {

      history.replaceState('', '', `/faq?keyword=${keyword}`);
    };

    this.debounceSearch = _.debounce((keyword) => {
      this.searchFaq(keyword);
      this.syncURL(keyword);
      this.setupEmptyMessage();
    }, 256);

    this.setupEmptyMessage = () => {

      const url = new URL(location.href);
      let value = url.searchParams.get('keyword');
      if (!this.displayFaq.length) {
        this.noResultsMessage = `"${value}"に一致する情報は見つかりませんでした。`;
      }
      else {
        this.noResultsMessage = '';
      }
      this.update();
    };

    this.deleteText = () => {
      this.refs.search.value = '';
      this.searchFaq(this.refs.search.value);
      this.refs.search.focus();
    };

    this.head = () => {
      return {
        title : `よくある質問 | ${spat.config.head.ogp.site_name}`,
        canonical: `${spat.config.domain}/faq`,
      }
    };

});
riot.tag2('item-faq_', '<div class="f fm fbw p16 fs16 text-label_dark_primary {\'pb0\' : isOpen}" onclick="{onToggleOpen}"> <div class="f fm"> <div class="fw7 mr8">Q.</div> <div class="text-left fw4">{opts.question.question}</div> </div><i class="icon-arrow-down fs16 transition {\'rotate-180\' : isOpen}"></i> </div> <div class="f s-full max-height-0 overflow-hidden px16 text-left text-label_dark_primary fw4 lh15 transition fs16 cursor-default {\'mt8\' : isOpen}" ref="evaluation"> <div class="s-full f ft pt16 pb16 border-top" ref="evaluation_container"> <p class="fw7 mr8">A.</p> <p class="w-full rounded-8 white-space-pre-wrap fw4" data-is="atom-raw" content="{opts.question.answer}"></p> </div> </div>', 'item-faq_,[data-is="item-faq_"]{display:block}', '', function(opts) {

    this.isOpen = false;

    this.on('mount', () => {

      if (spat.isBrowser) {
        this.setupItemHeight();
      }
    });

    this.setupItemHeight = () => {
      this.refs.evaluation.style.maxHeight = this.isOpen ? getComputedStyle(this.refs.evaluation_container).height : 0;
    }

    this.onToggleOpen = (e) => {
      e.stopPropagation();
      this.isOpen = !this.isOpen;
      this.setupItemHeight();
    };
});