const riot = require('riot');

riot.tag2('module-popup', '', 'module-popup,[data-is="module-popup"]{transform:translate3d(0, 0, 0);visibility:hidden;position:fixed;top:0;right:0;bottom:0;left:0;display:block;z-index:9999} module-popup .popup-content,[data-is="module-popup"] .popup-content{position:fixed;visibility:visible;--popup-x:0;--popup-y:0;transform:translate3d(var(--popup-x), var(--popup-y), 0)}', '', function(opts) {
    this.MIN_EDGE_MARGIN = 12;

    this.on('mount', () => {
      if (spat.isBrowser) {

        document.addEventListener('click', (e) => {
          const $targetPopup = this.closestPopupRoot(e.target);
          let ignoreList = [];

          if ($targetPopup) {

            ignoreList.push(...this.getOpeners($targetPopup), $targetPopup);
          }
          [...this.root.children].forEach((child) => {
            if (!child._tag) return ;
            if (!child._tag.__openOptions.dismissible) return ;

            if (ignoreList.includes(child)) return ;

            setTimeout(() => {
              child._tag && child._tag.close();
            }, 32);
          });
        }, true);

        window.addEventListener('keydown', (e) => {

          if (e.keyCode === 27) {
            e.preventDefault();
            var popupTag = this.getCurrentPopupTag();

            if (popupTag && popupTag.__openOptions.dismissible) {
              popupTag.close();
            }
          }
        });

        window.addEventListener('popstate', (e) => {
          [...this.root.children].forEach((child) => {
            if (!child._tag) return ;
            if (!child._tag.__openOptions.dismissible) return ;
            child._tag.close();
          });
        }, false);

        this.resizeObserver = new ResizeObserver(this.popupResized);
      }
    });

    this.popupResized = (entries, observer) => {
      for (let entry of entries) {
        this.updatePosition(entry.target);
      }
    };

    this.updatePosition = ($popup) => {
      if (!$popup._tag) return ;
      const popupTag = $popup._tag;
      const options = popupTag.__openOptions;
      const align = Object.assign({}, options.align);

      let left = 0;
      let top = 0;

      const popupOrigin = {x: '0', y: '0'};

      if (options.element) {
        const rect = options.element.getBoundingClientRect();
        options.position = options.position || 'bottom';

        if (options.position === 'top') {
          top = rect.top;
          popupOrigin.y = '-100%';
          if (!align.x) align.x = 'center';
        }

        else if (options.position === 'bottom') {
          top = rect.bottom;
          if (!align.x) align.x = 'center';
        }

        else if (options.position === 'left') {
          left = rect.left;
          popupOrigin.x = '-100%';
          if (!align.y) align.y = 'middle';
        }

        else if (options.position === 'right') {
          left = rect.right;
          if (!align.y) align.y = 'middle';
        }

        if (align.x === 'center') {
          left = rect.left + rect.width / 2;
          popupOrigin.x = '-50%';
        }
        else if (align.x === 'left') {
          left = rect.left;
        }
        else if (align.x === 'right') {
          left = rect.right;
          popupOrigin.x = '-100%';
        }

        if (align.y === 'middle') {
          top = rect.top + rect.height / 2;
          popupOrigin.y = '-50%';
        }
        else if (align.y === 'top') {
          top = rect.top;
        }
        else if (align.y === 'bottom') {
          top = rect.bottom;
          popupOrigin.y = '-100%';
        }
      }

      top += options.offsetTop || 0;
      left += options.offsetLeft || 0;
      $popup.style.setProperty('--popup-x', popupOrigin.x);
      $popup.style.setProperty('--popup-y', popupOrigin.y);
      Object.assign($popup.style, {
        left: `${left}px`,
        top: `${top}px`,
      });

      const popupRect = $popup.getBoundingClientRect();
      let positionChanged = false;

      if (popupRect.top < this.MIN_EDGE_MARGIN) {
        top -= popupRect.top - this.MIN_EDGE_MARGIN;
        positionChanged = true;
      }
      else if (popupRect.bottom > innerHeight - this.MIN_EDGE_MARGIN) {
        top -= popupRect.bottom - innerHeight + this.MIN_EDGE_MARGIN;
        positionChanged = true;
      }

      if (popupRect.left < this.MIN_EDGE_MARGIN) {
        left -= popupRect.left - this.MIN_EDGE_MARGIN;
        positionChanged = true;
      }
      else if (popupRect.right > innerWidth - this.MIN_EDGE_MARGIN) {
        left -= popupRect.right - innerWidth + this.MIN_EDGE_MARGIN;
        positionChanged = true;
      }

      if (positionChanged) {
        Object.assign($popup.style, {
          left: `${left}px`,
          top: `${top}px`,
        });
      }
    };

    this.open = (tagName, options = {}) => {
      var _options = Object.assign({
        dismissible: true,
      }, options);

      var element = document.createElement('div');
      this.root.appendChild(element);

      var popupTag = riot.mount(element, tagName, _options.opts)[0];
      element.classList.add('popup-content');
      popupTag.__openOptions = _options;
      popupTag.promise = new Promise((resolve) => {
        popupTag.close = async () => {

          if (popupTag.isClose) return ;

          popupTag.isClose = true;

          this.resizeObserver.unobserve(popupTag.root);
          var value = popupTag.value;
          await this.close(popupTag);
          resolve(value);

          return value;
        };
        popupTag.waitClose = () => {
          return popupTag.promise;
        };
        popupTag.update();
      });

      this.updatePosition(element);

      this.resizeObserver.observe(element);
      this.update();

      if (_options.closeTarget) {
        const close = (e) => {
          e.preventDefault();
          e.stopPropagation();
          popupTag.close();
        };
        _options.closeTarget.addEventListener('click', close, true);
        popupTag.on('unmount', () => {
          _options.closeTarget.removeEventListener('click', close, true);
        });
      }
      return popupTag;
    };

    this.close = (popupTag) => {
      popupTag.trigger('close');
      return new Promise(resolve => {
        this._unmount(popupTag);
        resolve();
      });
    };

    this._unmount = (popupTag) => {
      popupTag.unmount();
    };

    this.getCurrentPopupTag = () => {
      var children = this.root.children;
      var child = children[children.length-1];

      return child && child._tag ? child._tag : null;
    };

    this.closeAll = () => {
      [...this.root.children].forEach((child) => {
        if (!child._tag || !child._tag.__openOptions || !child._tag.__openOptions.dismissible) return ;
        child._tag.close();
      });
    };

    this.closestPopupRoot = (dom) => {
      if (!dom) return ;
      if (dom._tag) {
        dom = dom._tag.root;
      }
      if (!dom) return ;
      const popupRoot = dom.closest('.popup-content');
      if (this.isPopup(popupRoot)) {
        return popupRoot;
      }
    };

    this.isPopup = (dom) => {
      if (!dom) return false;
      return dom.classList.contains('popup-content');
    };

    this.getOpeners = ($popup) => {
      const openers = [];
      let temp = $popup;

      while (temp && temp._tag && (temp = this.closestPopupRoot(temp._tag.__openOptions.opener))) {
        openers.push(temp);
      }
      return openers;
    };
});