const riot = require('riot');

riot.tag2('atom-project', '<div class="relative cursor-pointer tooltip" aria-label="{projectStore.data.name}へ移動" data-tooltip-position="top" onclick="{onPushProjectRoute}" role="button"> <div class="f fh h20 px6 rounded-4 font-lato" riot-style="background-color: {projectStore.getColor()}"> <div class="bold fs12 lh13 text-white word-break-all line-clamp-1">{projectStore.data.name}</div> </div> </div>', 'atom-project,[data-is="atom-project"]{display:block}', '', function(opts) {
    this.loaded = false;
    this.on('mount', () => {
      this.setup();
    });
    this.on('update', async () => {
      this.setup();
    });

    this.setup = async () => {
      if (!this.opts.item) return ;

      if (!this.loaded || !this.projectStore || this.opts.item.id !== this.projectStore.id) {
        this.loaded = true;
        this.projectStore = await this.opts.item.fetch();
        this.update();
      }
    };

    this.onPushProjectRoute = (e) => {
      e.preventDefault();
      var url = this.opts.item.getURL();
      spat.router.push(`${url}`);
    };
});