import { getStorage, ref, uploadBytes as _uploadBytes, getDownloadURL as _getDownloadURL } from "firebase/storage";

let _storage = getStorage();

let storage = {
  getRef(file) {
    // ファイル名を取得
    let filename = file.name.match(/([^/]*)\./)[1];
    // 拡張子を取得
    let extend = file.name.match(/[^.]+$/)[0];
    // 重複しない文字列を生成
    let strong = 1000;
    let uniqId = Date.now().toString() + Math.floor(strong*Math.random()).toString(16);
    return ref(_storage, `sensitive_images/${filename + uniqId}.${extend}`);
  },
  async uploadFile (file) {
    let ref = this.getRef(file);
    await _uploadBytes(ref, file);
    let download_url = await _getDownloadURL(ref);
    return download_url;
  },
};

export default storage;