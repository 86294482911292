import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken, deleteToken } from "firebase/messaging";
// web push 通知
const webpush = {
  // webpush のセットアップ
  setup() {
    // if (!this.isSupported()) return;
    const firebaseApp = initializeApp(spat.config.firebase);
    this.messaging = getMessaging(firebaseApp);
    this.getToken().then(token => {
      return this.postPushToken(token);
    });
  },
  getToken() {
    return getToken(this.messaging, { vapidKey: spat.config.webpush.publicVapidKey });
  },
  // タブを開いてるときの通知のセットアップ
  setupClient() {
    // ワーカーからメッセージを受け取る
    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.origin !== location.origin) return;
      if (event.data.url && location.pathname !== event.data.url) {
        spat.router.push(event.data.url);
      }
    }, false);

    onMessage(this.messaging, async (payload) => {
      // 横から出てくる push 通知を出す
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
        icon: payload.notification.icon,
        data: {
          FCM_MSG: payload,
        },
      };
      // sw.js に対して通知を出すように依頼
      navigator.serviceWorker.controller.postMessage({
        eventType: 'foreground-notification',
        title: notificationTitle,
        notificationOptions,
      });
      if (payload.data.url && location.pathname !== payload.data.url) {
        // 上から表示するスナックバーみたいなやつの想定
        // app.modulePushNotification.message({
        //   title: notificationTitle,
        //   body: payload.notification.body,
        //   url: payload.data.url,
        // });
      }
    });
  },
  // push token を送る
  postPushToken(token) {
    // if (!this.isSupported()) return;
    if (app.auth.currentUser) {
      return app.api.child('/me/push/entry').post({
        "platform": "web",
        "device_id": this.getUuid(),
        "token": token,
      });
    }
  },
  // トークン削除
  deleteToken() {
    // if (!this.isSupported()) return;
    return deleteToken(this.messaging).then(() => {
      return app.api.child('/me/push/leave').post({
        "platform": "web",
        "device_id": this.getUuid(),
      });
    }).catch(function(err) {
      console.log(err);
      return err;
    });
  },
  // uuid を取得する
  getUuid() {
    var uuid = localStorage.getItem('alog-webpush-uuid');
    if (!uuid) {
      uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
      localStorage.setItem('alog-webpush-uuid', uuid);
    }
    return uuid;
  },

  // service worker に対応してるかどうか
  isSupported() {
    return navigator.cookieEnabled &&
      'serviceWorker' in navigator &&
      'PushManager' in window &&
      'Notification' in window &&
      'fetch' in window &&
      ServiceWorkerRegistration.prototype.hasOwnProperty('showNotification') &&
      PushSubscription.prototype.hasOwnProperty('getKey');
  },

  // web通知が使用可能な状態であるか
  isEnable() {
    return this.isSupported() &&
      Notification.permission === 'default' &&
      !capacitor.isAvailable &&
      location.hostname !== 'localhost' &&
      spat.config.env === 'production';
  },

  // 通知許可を求めるモーダルを開く
  requestPermissionIfNeeded() {
    if (!this.isEnable()) return;
    spat.modal.open('modal-notification-consent', {
      dismissible: false,
    });
  },

  // 通知許可を求める
  requestNotificationPermission() {
    if (!this.isEnable()) return;
    Notification.requestPermission();
  },
};

export default webpush;

