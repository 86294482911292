const riot = require('riot');

riot.tag2('item-column', '<div class="relative pl16 pr12 py8 {&quot;cursor-pointer&quot;: !isLocked()}" if="{!isEditing}" onclick="{onToggleEdit}"> <div class="f fm fbw h32"> <div class="flex-fixed s12 circle mr8" riot-style="background-color: hsl({app.utils.stringToColorAngle(opts.item.data.name)}, 60%, 60%);"></div> <div class="w-full f fm font-lato py6"><span class="bold line-clamp-1 word-break-all lh13 mr2">{opts.item.data.name}</span><span class="flex-fixed text-label_dark_medium fs10" if="{opts.item.data.note_refs.length}">({opts.item.data.note_refs.length})</span></div> <div class="p8 cursor-pointer" onclick="{onDelete}"> <div class="tooltip" if="{isLocked()}" aria-label="削除不可" data-tooltip-position="top"><i class="icon-lock f fh fs15 text-label_dark_medium"></i></div> <div class="tooltip" if="{!isLocked()}" aria-label="削除" data-tooltip-position="top"><i class="icon-stash_box f fh fs15 text-label_dark_medium"></i></div> </div> </div> </div> <form class="f p8" if="{isEditing}" onsubmit="{onSubmit}"> <input class="input pale w-full" ref="column_name" riot-value="{opts.item.data.name}" onblur="{onSave}"> </form> <div class="w-full px12 mb8" data-is="module-button-note-create" project="{opts.item.parent.parent}" column_id="{opts.item.id}"></div> <div class="relative h-full overflow-scroll" ref="body"> <div class="absolute trbl0 s-full px12 pb12" ref="notes" data-column-id="{opts.item.id}"> <div class="rounded-8 border border-transparent mxn1 mb6" each="{note in getFilteredNotes()}" onclick="{onClickNote}"> <div class="hover-trigger hover-board-shadow box-shadow-primary rounded-8 overflow-hidden transition" data-is="item-note" item="{note}" hide-status="{true}" is-active="{note.id === parent.opts.currentNote.id}" view="board"></div> </div> <div class="h1"></div> </div> </div>', '', '', function(opts) {

    this.isEditing = false;
    this.page = 1;
    this.per = 32;

    this.on('mount', async () => {

      this.refs.body.onscroll = (e) => {
        var elm = e.currentTarget;
        var max_scroll_value = elm.scrollHeight - elm.offsetHeight;

        if (elm.scrollTop + 2 >= max_scroll_value) {
          this.fetchMoreNotes();
        }
      };

      this.tags['module-button-note-create'].on('notecreate', async (e) => {

        var note = this.opts.item.parent.parent.collection('notes').doc(e.note.id);

        spat.router.push(`/${note.path}?view=board`);
      });

      if (!app.useragent.isMobile) {
        Sortable.create(this.refs.notes, {
          group: 'column',
          animation: 150,
          multiDrag: true,
          avoidImplicitDeselect: true,
          selectedClass: 'border-primary',

          onEnd: async (e) => {

            e.items.forEach(elm => Sortable.utils.deselect(elm));

            var note_tags = e.items.length ? e.items : [e.item];
            var note_ids = note_tags.map(item => item._tag.note.id);
            var indicator;

            try {

              if (e.items.length) {
                indicator = spat.modal.indicator();
              }

              await this.opts.item.getProject().changeNotesColumn({
                note_ids: note_ids,
                index: e.newIndex,
                next_column_id: e.to.dataset.columnId,
              });
            }
            finally {
              indicator && indicator.close();
            }
          }
        });
      }
    });

    this.on('update', async () => {
      if (this.opts.item && this._last_updated_at !== this.opts.item.data.updated_at) {
        this._last_updated_at = this.opts.item.data.updated_at;
        this.fetchNotes();
      }
    });

    this.fetchNotes = async () => {

      this.notes = [];
      this.update();

      var start = 0;
      var end = (this.page) * this.per;
      var promises = this.opts.item.data.note_refs.slice(start, end).map(ref => app.store.doc(ref.path).fetch({relation:true}));
      var notes = await Promise.all(promises);
      this.update({notes});
    };

    this.fetchMoreNotes = async () => {
      var start = this.page * this.per;
      var end = (this.page+1) * this.per;
      var promises = this.opts.item.data.note_refs.slice(start, end).map(ref => app.store.doc(ref.path).fetch({relation:true}));
      var notes = await Promise.all(promises);

      this.notes.push(...notes);
      this.update();
      this.page++;
    };

    this.onToggleEdit = (e) => {
      e.stopPropagation();
      this.isEditing = !this.isEditing;
      this.update();
      if (this.refs.column_name) {
        this.refs.column_name.focus();
      }
    };

    this.onSave = async (e) => {
      e.preventDefault();
      if (!this.isEditing) return ;

      if (this.opts.item.data.name !== this.refs.column_name.value.trim()) {
        var name = this.refs.column_name.value.trim();

        if (name) {
          await this.opts.item.update({name: this.refs.column_name.value});
        }
        await this.opts.item.refresh();
      }
      this.isEditing = false;
      this.update();
    };

    this.onSubmit = (e) => {
      e.preventDefault();
      this.refs.column_name.blur();
    };

    this.isLocked = () => {
      return this.opts.item.data.rule !== 'noop';
    };

    this.onDelete = async (e) => {
      e.stopPropagation();
      if (this.opts.item.data.rule !== 'noop') {
        spat.modal.alert('このカラムは削除できません');
        return ;
      }

      if (this.opts.item.data.note_refs.length) {
        spat.modal.alert('ノートのあるカラムは削除できません');
        return ;
      }
      var result = await spat.modal.confirm('本当に削除しますか？');

      if (!result) return ;

      await this.opts.item.delete();

      app.utils.openToast('delete');

      this.update();
    };

    this.onClickNote = (e) => {

      if (!e.shiftKey && !e.metaKey && !e.ctrlKey) {

        Sortable.utils.deselect(e.currentTarget);
        return;
      }
      e.preventDefault();
      e.stopPropagation();
    };

    this.getFilteredNotes = () => {
      return app.utils.filterNotes(this.notes, this.opts.noteQuery);
    };
});