
export default {
  '/:auth_mode(signup|signin)': {
    tag: 'page-auth',
  },
  '/password-reset': {
    tag: 'page-password-reset',
  },
  // 招待
  '/invites': {
    tag: 'page-invites',
  },
  // コメント一覧
  '/workspaces/:workspace_id/projects/:project_id/notes/:note_id/messages/:message_id?': {
    // PCの時はノート単体をmount PCの時はコメント単体をmount
    tag: ({req, res}) => {
      if (req.useragent.isMobile) {
        // SP
        return 'page-notes-messages';
      }
      // PC
      else {
        // PC のときに query.view の有無で分岐
        return req.query.view ? 'page-index' : 'page-notes-single';
      }
    },
    cacheKey({ req, res }) {
      if (req.useragent.isMobile) {
        // SP
        return `workspaces/${req.params.workspace_id}/messages`;
      }
      else {
        // PC のときに query.view の有無で分岐
        return req.query.view ? `workspaces/${req.params.workspace_id}/index` : `workspaces/${req.params.workspace_id}/notes-single`;
      }
    },
  },
  // ノート単体
  '/workspaces/:workspace_id/projects/:project_id/notes/:note_id': {
    tag: ({req, res}) => {
      if (req.useragent.isMobile) {
        // SP
        return 'page-notes-single';
      }
      else {
        // PC のときに query.view の有無で分岐
        return req.query.view ? 'page-index' : 'page-notes-single';
      }
    },
    cacheKey({ req, res }) {
      const NOTES_SINGLE_PATH = `workspaces/${req.params.workspace_id}/notes-single`;
      if (req.useragent.isMobile) {
        // SP
        return NOTES_SINGLE_PATH;
      }
      else {
        // PC のときに query.view の有無で分岐
        return req.query.view ? `workspaces/${req.params.workspace_id}/index` : NOTES_SINGLE_PATH;
      }
    },
  },
  // プロジェクト単体
  '/workspaces/:workspace_id/projects/:project_id': {
    tag: ({req, res}) => {
      return 'page-index';
    },
    cacheKey({req, res}) {
      return `workspaces/${req.params.workspace_id}/index`;
    },
  },
  // ワークスペース単体
  '/workspaces/:workspace_id': {
    tag: ({req, res}) => {
      return req.useragent.isMobile ? 'page-workspaces-single' : 'page-index';
    },
    cacheKey({req, res}) {
      return req.useragent.isMobile ? req.Url.pathname : `workspaces/${req.params.workspace_id}/index`;
    },
  },
  '/workspaces/:workspace_id/settings/:tab?': {
    tag: 'page-workspace-settings',
  },
  '/settings/:tab?': {
    tag: 'page-settings',
  },

  // 通知系
  '/workspaces/:workspace_id/notifications/:tab?': {
    tag: ({req, res}) => {
      req.view = 'notifications';
      return 'page-index';
    },
    cacheKey({req, res}) {
      return `workspaces/${req.params.workspace_id}/index`;
    },
  },

  // 検索
  '/workspaces/:workspace_id/search': {
    tag: 'page-search',
  },

  '/faq': {
    tag: "page-faq",
  },

  // インボックス
  '/workspaces/:workspace_id/inboxes/:inbox_id?': {
    tag: ({req, res}) => {
      req.view = 'inbox';
      return 'page-index';
    },
    cacheKey({req, res}) {
      return `workspaces/${req.params.workspace_id}/index`;
    },
  },
  
  '/': {
    tag: 'page-index',
  },
};

