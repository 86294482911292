const riot = require('riot');

riot.tag2('atom-status', '<div class="relative tooltip" if="{!opts.enableNext}" ref="button" onclick="{onOpenPopup}" aria-label="カラムを変更" data-tooltip-position="right"> <div class="f fm px6 py3 rounded-20 border border-label_dark_disabled"> <div class="s8 circle mr4" riot-style="background-color: hsl({app.utils.stringToColorAngle(column.data.name)}, 60%, 60%);"></div> <div class="font-lato fs12 lh12 line-clamp-1 word-break-all">{column.data.name}</div> </div> </div> <div class="relative tooltip" if="{opts.enableNext}" aria-label="カラムを変更" data-tooltip-position="top"> <div class="f fm rounded-20 border border-label_dark_disabled"> <div class="f fm pl8 pr12 py4" ref="button" onclick="{onOpenPopup}"> <div class="s8 circle mr4" riot-style="background-color: hsl({app.utils.stringToColorAngle(column.data.name)}, 60%, 60%);"></div> <div class="font-lato fs12 lh12 line-clamp-1 word-break-all">{column.data.name}</div> </div> <div class="h12 w2 bg-separator_opacity"></div> <div class="pl4 pr8 py4" onclick="{changeColumnToNext}"><i class="icon-arrow-right fs13"></i></div> </div> </div>', 'atom-status,[data-is="atom-status"]{display:block}', '', function(opts) {
    this.on('mount', () => {
      this.setup();
    });
    this.on('update', async () => {
      this.setup();
    });

    this.setup = async () => {

      if (this.opts.note && this.opts.note.data && this._last_updated_at !== this.opts.note.data.updated_at) {
        this._last_updated_at = this.opts.note.data.updated_at;
        this.column = await this.opts.note.fetchColumn();
        this.update();
      }
    };

    this.onOpenPopup = (e) => {
      e.preventDefault();
      e.stopPropagation();
      spat.popup.open('popup-status', {
        element: this.root,
        closeTarget: e.currentTarget,
        offsetTop: 4,
        position: 'bottom',
        align: { x: 'left' },
        opts: {
          note: opts.note,
        },
      });
    };

    this.click = () => {
      this.refs.button.click();
    };

    this.changeColumnToNext = () => {
      this.opts.note.changeColumnToNext();
    };
});