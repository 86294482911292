const riot = require('riot');

riot.tag2('module-view-gantt', '<div class="f fclm s-full"> <div class="px16 mb10" data-is="module-note-filter" ref="filter" users="{projectUsers}"></div> <div class="s-full px16 pb16 overflow-hidden"> <div class="s-full bg-white overflow-scroll rounded-8"> <svg ref="gantt" show="{notes.length}"></svg> </div> </div> </div>', 'module-view-gantt,[data-is="module-view-gantt"]{display:block} module-view-gantt svg,[data-is="module-view-gantt"] svg{max-width:none !important} module-view-gantt .gantt .bar-label,[data-is="module-view-gantt"] .gantt .bar-label{font-weight:bold} module-view-gantt .gantt .today-highlight,[data-is="module-view-gantt"] .gantt .today-highlight{fill:hsl(54,90%,79%)} module-view-gantt .gantt .bar-wrapper:hover .bar-progress,[data-is="module-view-gantt"] .gantt .bar-wrapper:hover .bar-progress{fill:hsl(210,90%,60%)} module-view-gantt .gantt .bar-wrapper .bar-progress,[data-is="module-view-gantt"] .gantt .bar-wrapper .bar-progress{fill:hsl(130,60%,45%)} module-view-gantt .gantt .bar-wrapper.expired .bar-progress,[data-is="module-view-gantt"] .gantt .bar-wrapper.expired .bar-progress{fill:#ff6060} module-view-gantt .popup-wrapper,[data-is="module-view-gantt"] .popup-wrapper{display:none}', '', function(opts) {
    this.on('mount', () => {
      this.refs.filter.on('filterchange', () => {
        this.setupTasks();
      });
    });

    this.setup = async () => {
      var project = app.store.project;

      this.columns = await project.fetchColumns();

      if (!this.gantt) {
        this.gantt = new Gantt(this.refs.gantt, [{}], {

          view_mode: 'Day',

          custom_popup_html: (task) => {

            this.editTask(task);

            return '';
          },

          on_date_change: async (task, start, end) => {

            await task.note.updatePeriod({start_at: start.getTime(), end_at: end.getTime()});
            await task.note.refresh();

            var new_task = this.noteToTask(task.note);
            Object.assign(task, new_task);
            this.gantt.refresh(this.gantt.tasks);
          },
        });
      }

      this.setupTasks();

      this.projectUsers = await project.fetchProjectUsers();
      this.update();
    };

    this.setupTasks = async () => {
      if (!this.columns) return ;

      var query = this.refs.filter.getQuery();

      var store = app.store.project.collection('notes')
        .where('disabled', '==', false)
        .where('archived', '==', false)
        .orderBy('last_commented_at', 'desc')
        .limit(32);

      if (query.options.assigned_id) {
        var user_ref = app.store.db.collection('users').doc(query.options.assigned_id);
        store.where('assigned_user_refs', 'array-contains', user_ref);
      }

      this.notes = await store.fetch();
      this.notes = app.utils.filterNotes(this.notes, query);

      this.notes = this.notes.sort((a, b) => {
        var a_end_at = a.data.end_at || 0;
        var b_end_at = b.data.end_at || 0;

        if (!a_end_at) {
          return 1;
        }
        else if (!b_end_at) {
          return -1;
        }

        return b_end_at > a_end_at ? -1 : 1;
      });

      this.update();

      var tasks = this.notes.map(note => {
        return this.noteToTask(note);
      });

      if (tasks.length) {
        this.gantt.refresh(tasks);
      }
    };

    this.noteToTask = (note) => {

      var column_index = this.columns.findIndex(column => column.id === note.data.column_ref.id);
      var progress = ((column_index+1)/this.columns.length) * 100;
      var classes = [];

      if (note.data.end_at) {
        if (dayjs(note.data.end_at).diff() < 0) {
          classes.push('expired');
        }
      }

      return {
        id: note.id,
        name: note.data.title,
        start: dayjs(note.data.start_at || undefined).format('YYYY-MM-DD'),
        end: dayjs(note.data.end_at || undefined).format('YYYY-MM-DD'),
        progress: progress,
        note: note,
        custom_class: classes.join(' '),
      };
    };

    this.editTask = async (task) => {
      var modal = spat.modal.open('modal-note', {
        note: task.note,
      });
      await modal.waitClose();

      var new_task = this.noteToTask(task.note);
      Object.assign(task, new_task);
      this.gantt.refresh(this.gantt.tasks);
    };
});