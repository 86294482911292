const riot = require('riot');

riot.tag2('popup-create-table', '<div class="p14 bg-white rounded-8 box-shadow-primary" ref="popup"> <form class="f fm fclm font-lato" onsubmit="{submit}"> <label class="f fm mb12"> <div class="bold mr8">行:</div> <input class="input light_secondary w70" min="1" max="32" placeholder="1" ref="rows" required type="number"> </label> <label class="f fm mb16"> <div class="bold mr8">列:</div> <input class="input light_secondary w70" min="1" max="32" placeholder="1" ref="cols" required type="number"> </label> <button class="f fh primary_fill button h32 w-full"> <div>作成</div> </button> </form> </div>', '', '', function(opts) {
    this.on('mount', () => {
      requestAnimationFrame(() => {
        this.refs.rows.focus();
      });
    });

    this.submit = (e) => {
      e.preventDefault();
      let rows = this.refs.rows.value;
      let cols = this.refs.cols.value;
      this.trigger('submit', { rows, cols });
    };
});