const riot = require('riot');

riot.tag2('page-password-reset', '<div class="f fh fclm s-full s-px16"> <h1 class="font-lato lh15 text-center text-background_dark_primary fs24 mb32">alog</h1> <div class="f fh w-full max-width-380 bg-white rounded-8 animation-appear"> <div class="f fh fclm w-full px48 py32"> <div class="f fm w-full mb24"> <div class="h1 w-full bg-separator_opacity"></div> <div class="lh13 bold word-break-keep fs12 mx16">パスワードの再設定</div> <div class="h1 w-full bg-separator_opacity"></div> </div> <div class="w-full" if="{mode === &quot;sending&quot;}"> <div class="text-label_dark_primary lh15 text-center mb24">登録されたメールアドレス宛に<br>パスワード再設定のURLを送信します。</div> <form class="w-full fs13 mb24" onsubmit="{onSubmit}"> <input class="input large light_secondary w-full focus-pale bg-background_light_secondary mb16" ref="email" placeholder="メールアドレスを入力" required type="email"> <button class="button primary w-full h40 transition">送信</button> </form> <div class="f fc"><a class="lh13 text-primary bold" href="/signin">ログインはこちら</a></div> </div> <form class="w-full" if="{mode === &quot;completed&quot;}" onsubmit="{onSubmit}"> <div class="fs18 lh13 bold text-center mb24">送信完了</div> <div class="lh15 text-center mb24"><span class="bold">{email}</span><span>に<br>パスワード再設定のURLを送信しました。<br>メールが届かない場合や紛失した場合は、<br>以下より再送信してください。</span></div> <button class="button primary w-full h40 transition">メールを再送信</button> </form> </div> </div> </div>', 'page-password-reset,[data-is="page-password-reset"]{display:block}', '', function(opts) {
    this.on('show', () => {
      this.mode = 'sending';
    });

    this.onSubmit = async (e) => {
      e.preventDefault();
      if (this.mode === 'sending') {
        this.email = this.refs.email.value;
      }

      await app.auth.sendPasswordResetEmail(this.email, {
        url: `${location.origin}/signin?from=password_reset`,
      });

      if (this.mode === 'completed') {
        app.utils.openToast('resendInviteMail', {label: this.email});
      }

      this.update({
        mode: 'completed',
        email: this.email,
      });
    };
});