import $ from 'jquery';
global['$'] = $

import sanitizeHtml from 'sanitize-html';
global['sanitizeHtml'] = sanitizeHtml

import marked from 'marked';
global['marked'] = marked

import Split from 'split.js';
global['Split'] = Split

import QuerySearch from 'querysearch';
global['QuerySearch'] = QuerySearch

import dayjs from '~/plugins/dayjs.js';
global['dayjs'] = dayjs

import firebase from '~/plugins/firebase.js';
global['firebase'] = firebase

import capacitor from '~/plugins/capacitor.js';
global['capacitor'] = capacitor

import app from '~/scripts/app.js';
global['app'] = app

import '~/styles/main.less';
