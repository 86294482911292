const localStorageManager = {
  // ローカルストレージからデータを取得する関数
  getFromStorage: (key, defaultValue = {}) => {
    const saved_data = localStorage.getItem(key);
    if (saved_data) {
      try {
        return JSON.parse(saved_data);
      }
      catch (e) {
        // storage アクセス失敗
        return defaultValue;
      }
    }
    return defaultValue;
  },

  // ローカルストレージにデータを保存する関数
  saveToStorage: (key, data) => {
    try {
      const json_data = JSON.stringify(data);
      localStorage.setItem(key, json_data);
      // 保存成功
      return true;
    }
    catch (e) {
      // storage アクセス失敗 or stringify 失敗
      console.error(e);
      // 保存失敗
      return false;
    }
  }
};

  export default localStorageManager;